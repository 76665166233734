import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { isFunction } from 'underscore';
import { IInsightOptions, IInsightRecord } from './Insight.Contract';
import { sort } from './util';

export class AssignedOptionsDataSource extends DataSource<IInsightRecord> {
	private items$: BehaviorSubject<IInsightRecord[]>;
	private sub: Subscription;
	public isLoading: boolean;
	public isAllDataLoaded: boolean;
	private readonly disableScroll: boolean;

	public get pauseInfiniteScroll(): boolean {
		return this.isLoading || this.isAllDataLoaded || this.disableScroll;
	}

	public get items(): IInsightRecord[] {
		return this.items$.getValue();
	}

	constructor(data: IInsightRecord[],
		private insightOptions: IInsightOptions,
		private loadNext: () => Promise<any>
	) {
		super();
		this.items$ = new BehaviorSubject<IInsightRecord[]>(data);
		this.disableScroll = !isFunction(this.insightOptions.onAssignedInfiniteScroll);
	}

	public connect(collectionViewer: CollectionViewer): Observable<IInsightRecord[]> {
		collectionViewer.viewChange.subscribe(({ end }) => {
			if(end + 2 <= this.items$.value.length || this.pauseInfiniteScroll) {
				return;
			}

			this.loadPage();
		});

		return this.items$.asObservable();
	}

	public disconnect(): void {
		this.items$.complete();
		this.sub?.unsubscribe();
	}

	public updateAssignedItems(items: IInsightRecord[]): void {

		this.items$.next(items.filter(item => item._loaded));

		if(items.length){
			this.loadPage();
		}
	}

	public removeItem(item: IInsightRecord): void {
		this.items$.next(this.items.filter(i => i !== item));
	}

	public appendItem(item: IInsightRecord): void {
		this.items$.next(this.items.concat(item));
	}

	private loadPage(): void {
		if(this.disableScroll) {
			return;
		}

		this.isLoading = true;
		this.loadNext()
			.then(({ assignedItems, loadedItems, complete }) => {

				this.isAllDataLoaded = complete;

				//Maintain infinite scroll cursor position
				this.items$.next([
					...this.items$.getValue(),
					...sort(loadedItems, this.insightOptions.fieldDefs.orderBy, this.insightOptions.fieldDefs.ascending),
					...(this.isAllDataLoaded
						? assignedItems.filter(r => !r._loaded) //if infinite scroll is complete, append any unloaded data just so it is rendered
						: [])
				]);

				this.isLoading = false;
			})
			.catch(e => {
				console.error('AssignedOptionsDataSource. Error Loading Next Page: ', e);
			});
	}

}
