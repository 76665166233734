import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapObject } from 'underscore';

import { escape } from 'rev-shared/util';

/**
 * Translates a string that contains HTML formatting
 * Example usage
 * To add html formatting:
 * (en.json) "Video_Playback_DownloadVideoFailed": "Downloading video from Webex <b>failed</b>. Please <i>contact</i> your administrator.",
 * (html template) <span [innerHTML]="'Video_Playback_DownloadVideoFailed' | translateHtml></span>
 *
 * To insert parameters, use same rules as the translate pipe:
 * (en.json) "Video_Playback_SeeVideoPageForDetails": "Please click <a href="{0}">here</a> to see the video page for details",
 * (template) <span [innerHTML]="'Video_Playback_SeeVideoPageForDetails' | translateHtml : { '0' : videoUrl }"></span>
 */
@Pipe({ name: 'translateHtml' })
export class TranslateHtmlPipe implements PipeTransform {
	constructor(
		private Translate: TranslateService,
		private sanitizer: DomSanitizer
	) {}

	public transform(key: string, params: any): SafeHtml {
		const htmlParams = mapObject(params, val => escape(val));
		const html = this.Translate.instant(key, htmlParams);

		return this.sanitizer.bypassSecurityTrustHtml(html);

	}
}
