import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SpinnerState, SpinnerSize } from './Spinner';

import styles from './VbUiSpinner.module.less';

/**
 * Basic spinner animation using SVG.
 * see <vb-loading-spinner> component for more advanced options
 */
@Component({
	selector: 'vb-ui-spinner',
	host: {
		'[class]': 'styles.root'
	},
	template: `
		<div [ngClass]="spinnerClass">
			<svg viewBox="0 0 100 100">
				<circle [ngClass]="styles.pip"
					cx="50"
					cy="50"
					r="42"
				/>
			</svg>
		</div>
	`
})
export class VbUiSpinnerComponent implements OnChanges {
	@Input() public size: SpinnerSize;
	@Input() public state: SpinnerState;

	public readonly styles = styles;
	public spinnerClass: string = this.getSpinnerClass();

	public ngOnChanges(): void {
		this.spinnerClass = this.getSpinnerClass();
	}

	private getSpinnerClass(): string {
		return [
			styles.spinner,
			styles[this.size] || '',
			styles[this.state] || styles.active
		].join(' ');
	}
}
