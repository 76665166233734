<ng-template #linkContent>
	<span *ngIf="iconClass" [ngClass]="iconClass"></span>
	<span [innerHTML]="linkText | highlight : highlightText : highlightClass"></span>
</ng-template>

<a class="type-bold" *ngIf="uiSref" [ngClass]="cssClass" [uiSref]="uiSref" [uiParams]="uiSrefParams" [uiOptions]="uiOptions">
	<ng-container *ngTemplateOutlet="linkContent"></ng-container>
</a>

<a class="type-bold" *ngIf="href" [target]="target || '_self'" [ngClass]="cssClass" [href]="href">
	<ng-container *ngTemplateOutlet="linkContent"></ng-container>
</a>

<span *ngIf="plainText" class="margin-5" [innerHTML]="plainText | highlight : highlightText : highlightClass">
</span>
