import { WritableSignal } from '@angular/core';

//TODO: I Prefix means interface. Rename this enum without I prefix.
//same for other places in the file
export enum IGenerateMetadataOption {
	TITLE = 'title',
	DESCRIPTION = 'description',
	TAGS = 'tags',
	CHAPTERS = 'chapters'
}

export enum IGenerateMetadataStatus {
	ENABLED = 'enabled',
	PROCESSING = 'processing',
	HIDDEN = 'hidden'
}

export enum IMetadataGenerationJobStatus {
	INPROGRESS = 'InProgress',
	SUCCESS = 'Success',
	FAILED = 'Failed'
}

export interface IChapterMetadata {
	extension: string;
	imageId: string;
	isUploadedImage: boolean;
	time: string;
	title: string;
}

export type IGenerateMetadataOptionSignalMap = {
	[key in IGenerateMetadataOption]: WritableSignal<IGenerateMetadataStatus>
};

export interface VideoMetadataGenerationJobSubmittedEvent {
	generationStatus: IMetadataGenerationJobStatus;
	metadataGenerationFields: IGenerateMetadataOption[];
}

export interface VideoMetadataGenerationJobFinishedEvent {
	generationStatus: IMetadataGenerationJobStatus;
	metadataGenerationFields: IGenerateMetadataOption[];
	title: string;
	description: string;
	tags: string[];
	videoChapters: IChapterMetadata[];
}

export interface VideoMetadataGenerationJobFailedEvent {
	videoId: string;
	userId: string;
	videoOwnerId: string;
	title: string;
	languageId: string;
	type: string;
	metadataGenerationFields: IGenerateMetadataOption[];
}

