import { Component, Input, OnChanges, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import styles from './ProfilePicture.module.less';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { getInitials } from 'rev-shared/util/nameUtil';

const Medium = 'medium';
const Large = 'large';
const DefaultIcon = 'user';

@Component({
	selector: 'vb-profile-picture',
	host: {
		'[class]': 'styles.root'
	},
	template: `
		<span
			[ngClass]="[
				styles.wrap,
				hilight ? 'theme-accent-border' : '',
				!url ? styles.wrapBackground : ''
			]"
			[style]="wrapStyle">
			<img
				*ngIf="url; else noImage"
				[ngClass]="styles.profilePicture"
				[alt]="altTxt"
				[src]="url"
				loading="lazy"/>

			<ng-template #noImage>
				<span *ngIf="placeholderInitials; else placeholderIcon"
					[ngClass]="[styles.placeholderInitials]"
					[attr.aria-label]="altTxt">
					{{ placeholderInitials }}
				</span>
			</ng-template>

			<ng-template #placeholderIcon>
				<span
					class="glyphicons theme-accent-txt"
					[ngClass]="[styles.profileIcon, placeholderIconClass]"
					[hidden]="url"
					[attr.aria-label]="altTxt">
				</span>
			</ng-template>
		</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VbProfilePictureComponent implements OnChanges {
	@Input() public altTxt: string;
	@Input() public hilight: boolean;
	@Input() public wrapStyle: string;
	@Input() public profileUrl: string;
	@Input() public size: string;
	@Input() public placeholderIconClass: string;
	@Input() public placeholderInitialsFullName: string;

	public placeholderInitials: string;
	public url: string;
	public readonly styles = styles;

	constructor(
		private ThemeService: ThemeService
	) {}

	private get userProfileImageDimensions(): string {
		return this.ThemeService.brandingSettings.headerSettings.userProfileImageDimensions;
	}

	public ngOnChanges(): void {
		this.url = this.profileUrl ?
			`${this.profileUrl}?size=${this.userProfileImageDimensions}` :
			undefined;

		this.placeholderIconClass = this.placeholderIconClass || DefaultIcon;
		this.placeholderInitials = getInitials(this.placeholderInitialsFullName);
	}

	@HostBinding('class.vbProfilePictureLarge')
	public get isLargeSize(): boolean {
		return this.size === Large;
	}

	@HostBinding('class.vbProfilePictureMedium')
	public get isMediumSize(): boolean {
		return this.size === Medium;
	}
}
