import { NgModule } from '@angular/core';
import { NgxTrimDirective } from './ngx-trim.directive';



@NgModule({
  declarations: [
    NgxTrimDirective,
  ],
  imports: [],
  exports: [
    NgxTrimDirective,
  ],
})
export class NgxTrimDirectiveModule {
}
