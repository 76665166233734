import { Rect } from './Contract';
import { gridLayout, insertLetterbox, insertPadding, packedRightColumnLayout, rowGridLayout, singleLeftColRightLayout, singleTopRowBottom } from './LayoutUtil';

export enum LayoutType {
	Empty = 'Empty',
	FullFrame = 'FullFrame',
	Grid1_1 = 'Grid1_1',
	Grid2_1 = 'Grid2_1',
	Grid1_2 = 'Grid1_2',
	Grid2_2 = 'Grid2_2',
	Grid3_2 = 'Grid3_2',
	Grid3_3 = 'Grid3_3',
	RightCol_1 = 'RightCol_1',
	RightCol_2 = 'RightCol_2',
	RightCol_4 = 'RightCol_4',
	BottomRow_3 = 'BottomRow_3',
	BottomRow_4 = 'BottomRow_4',
	Rows_2_1 = 'Rows_2_1',
	Rows_3_2 = 'Rows_3_2',
}
export const LayoutTypes = [
	LayoutType.Empty,
	LayoutType.FullFrame,
	LayoutType.Grid1_1,
	LayoutType.Grid2_1,
	LayoutType.Grid1_2,
	LayoutType.RightCol_1,
	LayoutType.RightCol_2,
	LayoutType.Rows_2_1,
	LayoutType.Grid2_2,
	LayoutType.BottomRow_3,
	LayoutType.RightCol_4,
	LayoutType.BottomRow_4,
	LayoutType.Rows_3_2,
	LayoutType.Grid3_2,
	LayoutType.Grid3_3
];

export const LayoutHeight = 1080;
export const LayoutWidth = 1920;

const fullFrame: Rect = {
	x: 0, y: 0,
	w: LayoutWidth,
	h: LayoutHeight
};
const margin = 20;
const container = insertPadding(fullFrame, margin, margin, margin, margin);

export const LayoutDefs = {
	[LayoutType.Empty]: [],
	[LayoutType.FullFrame]: [fullFrame],

	[LayoutType.Grid1_1]: [insertLetterbox(container)],
	[LayoutType.Grid2_1]: gridLayout(container, 2, 1, margin),
	[LayoutType.Grid1_2]: gridLayout(container, 1, 2, margin),
	[LayoutType.Grid2_2]: gridLayout(container, 2, 2, margin),
	[LayoutType.Grid3_2]: gridLayout(container, 3, 2, margin),
	[LayoutType.Grid3_3]: gridLayout(container, 3, 3, margin),

	[LayoutType.RightCol_1]: singleLeftColRightLayout(container, 0.7, 1, margin),
	[LayoutType.RightCol_2]: packedRightColumnLayout(container, 2, margin),
	[LayoutType.RightCol_4]: singleLeftColRightLayout(container, 0.75, 4, margin),
	[LayoutType.BottomRow_3]: singleTopRowBottom(container, 0.75, 3, margin),
	[LayoutType.BottomRow_4]: singleTopRowBottom(container, 0.75, 4, margin),
	[LayoutType.Rows_2_1]: rowGridLayout(container, 2, 1, margin),
	[LayoutType.Rows_3_2]: rowGridLayout(container, 3, 2, margin)
};
