import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
	NONE,
	UNCATEGORIZED
} from 'rev-shared/media/MediaConstants';

import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

import {
	DateRangeFilter,
	Filter,
	MultiValueFilter,
	RangeFilter,
	TextFilter
} from './SearchFilterTypes';

import { formatMediumDate } from 'rev-shared/date/DateFormatters';


class CategoryFilter extends MultiValueFilter {
	public addToQuery(queryBuilder: SearchQueryBuilder) {
		if (this.value?.length) {
			const value = this.getQueryValue();
			if(value.find(cat => cat === UNCATEGORIZED)) {
				value.length == 1 ?
					queryBuilder.noValues(this.searchField, true) :
					queryBuilder.valuesOrNone(this.searchField, this.getQueryValue());
				return;
			}
			queryBuilder.values(this.searchField, this.getQueryValue());
		}
	}
}

class TeamFilter extends MultiValueFilter {
	public addToQuery(queryBuilder: SearchQueryBuilder) {
		if (this.value?.length) {
			if (this.value[0].name === NONE) {
				return queryBuilder.noValues(this.searchField, true);
			}
			queryBuilder.values(this.searchField, this.getQueryValue());
		}
	}
}

export function getStaticFilters(): { [key: string]: Filter } {
	return {
		title: new TextFilter({ searchField: 'Title' }),

		description: new TextFilter({ searchField: 'IndexedDescription' }),

		ownerUserId: new MultiValueFilter({
			searchField: 'OwnerUserId',
			queryProperty: 'id',
			formatter: user => user.name
		}),

		uploaderUserId: new MultiValueFilter({
			searchField: 'UploaderUserId',
			queryProperty: 'id',
			formatter: user => user.name
		}),

		userTags: new MultiValueFilter({
			searchField: 'UserTags',
			queryProperty: 'id',
			formatter: user => user.name
		}),

		whenUploaded: new DateRangeFilter({
			searchField: 'WhenUploaded',
			value: {
				from: null,
				to: null
			},
			formatterFactory: (t: TranslateService) => value => ([
				value.from ? t.instant('Media_Search_Filters_DateRangeFrom', { '0': formatMediumDate(value.from) }) : '',
				value.to ? t.instant('Media_Search_Filters_DateRangeTo', { '0': formatMediumDate(value.to) }) : ''
			].join(' '))
		}),

		is360: new Filter({
			searchField: 'Is360',
			formatterFactory: (t: TranslateService) => value =>
				t.instant(value ? 'FilterOptionIs360Video' : 'FilterOptionNot360Video')
		}),

		unlisted: new Filter({
			searchField: 'Unlisted',
			formatterFactory: (t: TranslateService) => value =>
				t.instant(value ? 'FilterOptionUnlistedVideo' : 'FilterOptionNotUnlistedVideo')
		}),

		isLive: new Filter({
			searchField: 'IsLive',
			formatterFactory: (t: TranslateService) => value =>
				t.instant(value ? 'Live' : 'VOD')
		}),

		isActive: new Filter({
			searchField: 'IsActive',
			formatterFactory: (t: TranslateService) => value =>
				t.instant(value ? 'Active' : 'Inactive')
		}),

		approvalStatus: new Filter({ searchField: 'Approval.status' }),

		stepId: new MultiValueFilter({ searchField: 'Approval.stepId' }),

		categoryIds: new CategoryFilter({
			searchField: 'CategoryIds',
			queryProperty: 'categoryId',
			formatter: category => category.name }),

		teamIds: new TeamFilter({
			searchField: 'TeamIds',
			queryProperty: 'teamId',

			formatter(team: any): any {
				return team.name;
			}
		}),

		expiryDateExists: new RangeFilter({ searchField: 'expiryDate' }),
		mySubscriptions: new Filter({ searchField: 'mySubscriptions' }),

		tags: new MultiValueFilter({ searchField: 'Tags' })
	};
}
