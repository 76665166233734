<div class="zoom-import" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"> <!--Prevents the upload dropdown from being closed while interacting with this dialog-->
	<div [hidden]="!isLoading">
		<vb-toolbar edge-padding class="section-header">
			<div flex="fill" class="padding-left-32">
				<h1>{{ 'Loading' | translate }}</h1>
			</div>
		</vb-toolbar>

		<div class="modal-body" disable-ng-animate [hidden]="!isLoading">
			<vb-loading-spinner [size]="'medium'" [block]="true"></vb-loading-spinner>
		</div>
	</div>

	<div [hidden]="isLoading">
		<form #form="ngForm" name="zoomImportForm" class="form-horizontal" (submit)="import()" class="padding-full-5">

			<vb-toolbar edge-padding class="section-header">
				<div flex="fill">
					<h1>{{ 'Uploads_Import_SelectVideos' | translate }}</h1>
				</div>
				<div [hidden]="!zoomRecordings?.length">
					<small>{{ zoomRecordings?.length }} {{ 'Media_Videos' | translate }}</small>
				</div>
			</vb-toolbar>

			<div class="modal-body">
				<div [hidden]="!errorGeneric" class="system-msg error-msg">
					<div [hidden]="!errorEmailNotFound">
						<span class="glyphicons remove_2"></span>{{ 'Uploads_Import_Zoom_EmailNotFound' | translate }}
					</div>
					<div [hidden]="errorEmailNotFound">
						<span class="glyphicons remove_2"></span>{{ 'Uploads_Import_Zoom_NoRecordings' | translate }}
					</div>
				</div>

				<vb-grid-list [ngClass]="styles.importZoomGridlist" class="white-items" [emptyMsg]="'Uploads_Import_Zoom_NoRecordings' | translate" [hidden]="errorGeneric">

					<header header>
						<vb-ui-checkbox class="selectionColumn" (ngModelChange)="syncSelectionHeaderModel()" [(ngModel)]="isSelectAll" name="selectionHeader">
						</vb-ui-checkbox>
						<div flex="fill">{{ 'Name' | translate }}</div>
						<div align="center">{{ 'Imported' | translate }}</div>
						<div>{{ 'Created' | translate }}</div>
						<div align="right">{{ 'Duration' | translate }}</div>
						<div align="right">{{ 'Size' | translate }}</div>
						<div flex="fill" align="right">{{ 'Transcript' | translate }}</div>
					</header>

					<vb-grid-row-collection vbGridRowCollection>
						<vb-grid-list-row *ngFor="let recording of zoomRecordings; trackBy trackByIndex; let index = index">
							<vb-ui-checkbox (ngModelChange)="onRowSelectionChange(recording)" [(ngModel)]="recording.selected" [disabled]="recording.isImported" name="selectedRecording{{index}}">
							</vb-ui-checkbox>
							<vb-grid-list-column flex="fill">{{recording.recordingName}}</vb-grid-list-column>
							<vb-grid-list-column align="center"><span class="glyphicons" [ngClass]="{'circle_ok': recording.isImported}"></span></vb-grid-list-column>
							<vb-grid-list-column>{{recording.creationDate|date:'medium'}}</vb-grid-list-column>
							<vb-grid-list-column align="right">{{recording.durationInMs|vbTimespan}}</vb-grid-list-column>
							<vb-grid-list-column align="right">{{recording.size|fileSize:2:'KB'}}</vb-grid-list-column>
							<vb-grid-list-column flex="fill" align="right">
								<span *ngIf="!recording.hasVtt; else transcriptSelection">{{ 'None'| translate }}</span>
							</vb-grid-list-column>
							<ng-template #transcriptSelection>
								<vb-ui-select>
									<select #selectElement name="transcriptLanguage{{index}}" [(ngModel)]="recording.vttLanguageCode" [disabled]="!recording.selected" [required]="recording.selected">
										<option [ngValue]="null">-- {{ 'SelectLanguage' | translate }} --</option>
										<option *ngFor="let language of transcriptionLanguageCodes" [ngValue]="language.code">{{language.name}}</option>
									</select>
								</vb-ui-select>
							</ng-template>
						</vb-grid-list-row>
					</vb-grid-row-collection>
				</vb-grid-list>
			</div>

			<div class="modal-footer">
				<button type="button" class="btn" vbUiBtnSecondary (click)="close()">{{ 'Cancel' | translate }}</button>
				<button type="submit" class="btn" vbBtnPrimary [disabled]="!selectedZoomImports.length || form.invalid">
					{{ 'Import' | translate }}
					<span [hidden]="!selectedZoomImports.length"> {{ selectedZoomImports.length }} {{ 'Media_Videos' | translate }}</span>
				</button>
			</div>
		</form>
	</div>
</div>
