import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientXsrfModule } from '@angular/common/http';

import { UIRouter, UIRouterModule } from '@uirouter/angular';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { PushService } from 'rev-shared/push/PushService';
import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';
import { SignalRHubsConnectionToken } from 'rev-shared/push/SignalRHubsConnectionToken';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';

import { ACCOUNT_DETAIL_SERVICE_TOKEN, getAccountDetailService } from './AccountDetail.Provider';
import { SECURITY_STARTUP_INJECTION_TOKEN, getSecurityStartup } from './SecurityStartup.Provider';
import { SecurityContextService } from './SecurityContext.Service';
import { SessionService } from './Session.Service';
import { UserContextService } from './UserContext.Service';
import { UserLocalIPService } from './UserLocalIP.Service';

import { VbAuthorizationKeyAngularDirective } from './VbAuthorizationKeyAngular.Directive';
import { VbAuthorizeStateDirective } from './VbAuthorizeStateAngular.Directive';

import { SecurityUiRouterStateInterceptor } from './SecurityUiRouterStateInterceptor';
import { SessionRun } from './Session.Run';
import { StateInterceptorRun } from './StateInterceptor.Run';
import { securityBootstrap } from './SecurityBootstrapAngular';
import { CsrfTokenHeader, CsrfTokenCookie } from './Tokens';
import { HttpXSRFInterceptorProvider } from './HttpXsrfInterceptor.Provider';

export const AccountIdToken = 'accountId';

const directives = [
	VbAuthorizationKeyAngularDirective,
	VbAuthorizeStateDirective
];

@NgModule({
	declarations: directives,
	exports: directives,
	imports: [
		DateAngularModule,
		HttpClientJsonpModule,
		HttpClientXsrfModule.withOptions({
			cookieName: CsrfTokenCookie,
			headerName: CsrfTokenHeader
		}),
		PushModule,
		UtilAngularModule,
		UIRouterModule.forChild({
			config: configureUiRouterSecurity
		})
	],
	providers: [
		HttpXSRFInterceptorProvider, // applies our Csrf/Xsrf token header to GET requests
		{
			provide: ACCOUNT_DETAIL_SERVICE_TOKEN,
			useFactory: getAccountDetailService,
			deps: [
				PushService
			]
		},
		{
			provide: SECURITY_STARTUP_INJECTION_TOKEN,
			useFactory: getSecurityStartup,
			deps: [
				SecurityContextService
			]
		},
		{
			provide: APP_BOOTSTRAP_LISTENER,
			deps: [
				PushService,
				SecurityContextService,
				SessionService,
				SignalRHubsConnectionToken,
				UserContextService,
				UserLocalIPService
			],
			useFactory: initSecurityModule,
			multi: true
		}
	]
})
export class SecurityAngularModule {}

function configureUiRouterSecurity(uiRouter: UIRouter): void {
	const { transitionService } = uiRouter;

	StateInterceptorRun(transitionService);

	SecurityUiRouterStateInterceptor(transitionService);
}

function initSecurityModule(
	pushService: PushService,
	securityContext: SecurityContextService,
	sessionService: SessionService,
	signalRHubsConnection: SignalRHubsConnection,
	userContext: UserContextService,
	userLocalIp: UserLocalIPService
) {
	return () => {
		securityBootstrap(pushService, securityContext, signalRHubsConnection, userContext);

		SessionRun(sessionService, signalRHubsConnection, userContext);

		userLocalIp.init();
	};
}
