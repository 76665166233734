export enum WebcastStatus {
	Completed = 'Completed',
	Scheduled = 'Scheduled',
	Starting = 'Starting',
	InProgress = 'InProgress',
	Broadcasting = 'Broadcasting',
	Deleted = 'Deleted',
	Recording = 'Recording',
	RecordingStarting = 'RecordingStarting',
	RecordingStopping = 'RecordingStopping',
	VideoSourceStarting = 'VideoSourceStarting',
	WaitingForStream = 'WaitingForStream',
	Restarting = 'Restarting'
}

export enum WebcastQuestionOption {
	IDENTIFIED = 'IDENTIFIED',
	SELFSELECT = 'SELFSELECT',
	ANONYMOUS = 'ANONYMOUS'
}

export enum UserPollsResponseTypes {
	ANONYMOUS = 'Anonymous',
	LOGALL = 'LogAll'
}
