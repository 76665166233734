interface IPlaylistWhen {
	when: Date;
	principal: string;
}

export interface IPlaylist {
	playlistId: string;
	name?: string;
	thumbnailUris?: any[];
	totalVideos: number;
	whenVideoAddedOrDeleted?: IPlaylistWhen;
	createdBy?: IPlaylistWhen;
	featured?: boolean;
}

export enum PlaylistType {
	Static = 'Static',
	Dynamic = 'Dynamic'
}

export interface IPlaylistData {
	id: string;
	name: string;
	isFeatured?: boolean;
	playlistType: PlaylistType;
	videos?: any[];
	canEdit?: boolean;
	searchFilter?: IDynamicPlaylistSeachFilter;
}

export interface IDynamicPlaylistSeachFilter {
	sortDescending: boolean;
	sortField: string;
	searchTerm: string;
	title: string;
	description: string;
	whenUploaded: { from: Date, to: Date };
	uploaderUserId: string[];
	userTags: string[];
	isActive?: boolean;
	isLive?: boolean;
	is360?: boolean;
	tags: string[];
	categoryIds: string[];
	teamIds: string[];
	unlisted?: boolean;
}
