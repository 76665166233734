import { Attribute, AfterViewInit, Component, ElementRef } from '@angular/core';
import './toolbar-component.less';

/**
 * Standardized flexbox based layout for toolbars for wrapping flex-grid components.
 * ___________________
 * Current CSS options
 *
 * .section-header -> h1
 * .sub-section-header -> h2
 *
 * ________
 * Examples
 * <vb-toolbar class="section-header">
 * 		<div flex="fill">
 *  		<h1>{{ 'Reports_VideoHeader' | translate }}</h1>
 *  	</div>
 *  	<div>
 *  		<a class="btn btn-admin btn-white" ng-href="/reports/videos/account/summary/{{accountId}}/csv" download>
 * 	     		{{ 'Reports_Csv' | translate }}
 * 	    	</a>
 *  	</div>
 *  </vb-toolbar>
 *  <vb-toolbar class="sub-section-header">
 *  	<div flex="fill">
 *  		<h2>{{ 'Reports_Top20Videos' | translate }}</h2>
 * 		</div>
 *  </vb-toolbar>
 *
 *  // MOBILE NAV
 *  this sticks to the top of the browser up to 992px
 *  <vb-toolbar class="mobile-nav">
 *	 <div flex>
 *	    <button class="btn" (click)="dme.selected = false">
 *	        <span class="glyphicons chevron-left"></span>
 *	    </button>
 *	 </div>
 *	 <div flex="fill">
 *	    {{ dme.name }}
 *	 </div>
 *	</vb-toolbar>
 *
 * // MODALS
 * <vb-toolbar edge-padding class="section-header">
 *	 <div flex="fill">
 *	    <h1>{{ 'Uploads_Import_SelectVideos' | translate }}</h1>
 *	 </div>
 *	 <div [hidden]="!(webExRecordings.length)">
 *	    <small>{{ webExRecordings.length }} {{ 'Media_Videos' | translate }}</small>
 *	 </div>
 * </vb-toolbar>
 */
//Note:- will change this to directive later.
@Component({
	selector: 'vb-toolbar',
	template: `<ng-content></ng-content>`
})
export class ToolbarComponent implements AfterViewInit {

	private $element: HTMLElement;
	constructor(
		@Attribute('layout') private layout: string,
		@Attribute('flexAlign') private flexAlign: string,
		@Attribute('layoutPadding') private layoutPadding: string,
		@Attribute('edgePadding') private edgePadding: string,
		element: ElementRef
	){
		this.$element = element.nativeElement;
	}

	public ngAfterViewInit(): void {
		if(!this.layout) {
			this.$element.setAttribute('layout', 'row');
		}

		if(!this.flexAlign) {
			this.$element.setAttribute('flex-align', 'center');
		}

		if(!this.layoutPadding) {
			this.$element.setAttribute('layout-padding', '0 100');
		}
		if(!this.edgePadding) {
			this.$element.setAttribute('layout-padding-no-edges', 'true');
		}
	}
}
