<section>
	<vb-ui-form-group labelClass="labelClass" [parentNgModel]="control">

		<label [id]="labelId" #uiLabel>
			{{label}}
		</label>

		<div uiControl role="group">
			<div class="btn-file" [attr.aria-labelledby]="labelId" vbBtnPrimary fileUpload (onAdd)="setImageFile($event.file)" [attr.disabled]="isFileDisabled ? true : null">
				<span class="glyphicons file"></span>
				{{ 'ChooseFile' | translate }}
				<input type="file" [attr.aria-label]="'ChooseFile' | translate" [disabled]="isFileDisabled">
			</div>

			<div *ngIf="value?.url" [ngClass]="styles.imagePreview">
				<img [src]="value.url" [alt]="label">
				<span *ngIf="!imageComponentMetadata.readOnly">
					<button type="button" *ngIf="imageComponentMetadata.showDeleteButton" (click)="clearSelectedImage()" vbUiBtnSecondary>
						{{ 'Delete' | translate }}
					</button>
					<button type="button" *ngIf="imageComponentMetadata.showResetButton && value && !isDefaultImageSelected" (click)="setDefaultImage()" vbUiBtnSecondary>
						{{ 'ResetDefault' | translate }}
					</button>
				</span>
			</div>
		</div>

		<div role="alert" uiValidation>
			<div *ngIf="control?.invalid" vbUiErrorMessage>

				<div *ngIf="control.errors.required">
					{{ 'ThisFieldIsRequired' | translate }}
				</div>

				<div *ngIf="control.errors.fileType">
					{{ 'ImageSelector_FileExtensionError' | translate }}: jpg, gif, png
				</div>

				<div *ngIf="control.errors.size">
					{{ 'ImageSelector_SizeError' | translate : { '0': imageComponentMetadata.maxSize | fileSize } }}
				</div>
			</div>
		</div>
	</vb-ui-form-group>
</section>
