import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Monkey patching http request method to discard undefined and null value. From Angular 14, the undefined and null getting convertd to 'undefined' and 'null'
 */
export function monkeyPatchHttpRequest() {
	const originalFunction = HttpClient.prototype.request;

	HttpClient.prototype.request = function(...args: any[]): Observable<any> {
		const options = args[2];
		if (options && options.params && !(options.params instanceof HttpParams)) {
			Object.keys(options.params).forEach(key => {
				const value = options.params[key];

				if (value == null) {
					delete options.params[key];
				}
			});
		}
		return originalFunction.apply(this, args);
	};
}
