import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'sign-in-btn',
	templateUrl: './SignInBtn.Component.html'
})

export class SignInBtnComponent {
	@Input() public btnLabel: string;
	@Input() public showIcon: boolean = true;

	@Output() public onClick: EventEmitter<any> = new EventEmitter();
}
