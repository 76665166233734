import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { TranslateModule } from '@ngx-translate/core';

import { VbUiBtnFabDirective } from './VbUiBtnFab.Directive';
import { VbUiBtnSecondaryDirective } from './VbUiBtnSecondaryAngular.Directive';
import { VbUiRadioBtnDirective } from './VbUiRadioBtnAngular.Directive';
import { VbUiRadioBtnGroupDirective } from './VbUiRadioBtnGroupAngular.Directive';
import { VbUiBtnDangerDirective } from './VbUiBtnDanger.Directive';
import { VbUiMultiSelectBtnComponent } from './VbUiMultiSelectBtn.Component';
import { VbToggleButtonComponent } from './VbToggleButton.Component';
import { VbBtnPrimaryDirective } from './VbBtnPrimary.Directive';
import { VbBtnSecondaryDirective } from './VbBtnSecondary.Directive';

const declarations = [
	VbBtnPrimaryDirective,
	VbBtnSecondaryDirective,
	VbToggleButtonComponent,
	VbUiBtnDangerDirective,
	VbUiBtnFabDirective,
	VbUiBtnSecondaryDirective,
	VbUiMultiSelectBtnComponent,
	VbUiRadioBtnDirective,
	VbUiRadioBtnGroupDirective
];

@NgModule({
	declarations: [
		declarations
	],
	exports: [
		declarations
	],
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		TranslateModule
	]
})
export class ButtonsAngularModule { }
