export function truncateFirstName(fullName: string): string {
	if(!fullName) {
		return '';
	}

	const [firstName, ...lastNames] = fullName.split(' ');
	return [`${firstName[0]}.`, ...lastNames].join(' ');
}

export function truncateName(name: string, maxLength: number): string {
	if(!name) {
		return '';
	}

	return name.substring(0, maxLength) + '…';
}

export function getInitials(fullName: string): string {
	if(!fullName) {
		return '';
	}

	const names = fullName.split(' ');

	return names
		.filter((_, i) => i === 0 || i === names.length - 1)
		.map(name => name[0])
		.join('')
		.toUpperCase();
}
