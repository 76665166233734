import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';

import { VbUiSelectComponent } from './VbUiSelect.Component';
import { CssRulesAngularModule } from '../css/Css.AngularModule';

@NgModule({
	exports: [
		VbUiSelectComponent
	],
	declarations: [
		VbUiSelectComponent
	],
	imports: [
		CommonModule,
		CssRulesAngularModule,
		DirectivesAngularModule
	]
})
export class SelectAngularModule {}
