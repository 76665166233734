import { isString } from 'rev-shared/util';
import { PlaybackTokenType } from './PlaybackTokenType';
import { isIe } from 'rev-shared/util/UserAgentUtil';
import { getQueryParamsFromUrl, removeQueryParamsFromUrl } from 'rev-shared/util/UrlUtil';
import { ResourceType } from './ResourceType';
import { getUrlPath } from 'rev-shared/util/Util.Service';
import { IVbPlaybackTokenConfig } from 'vbrick-player-src/IVbPlaybackTokenConfig';

const AVAILABLE_TOKENS = [PlaybackTokenType.CloudFrontToken, PlaybackTokenType.VBToken];
const WITH_CRED_KEY = 'withCred';
const tokenRenewalIntervalInMins: number = 60;

export const PLAYBACK_TOKEN_END_POINT: string = '/auth/playback-url-token';

export function isPlaybackTokenAvailable(src: string): boolean {
	return !!getPlaybackToken(src);
}

export function getPlaybackToken(src: string): string {
	if (!isString(src)) {
		return;
	}
	return AVAILABLE_TOKENS.find(token => src.includes(token));
}

export function getPlaybackTokenConfig(resourceId: string, resourceType: ResourceType, src: string): IVbPlaybackTokenConfig {
	const tokenType = getPlaybackToken(src);

	if(!tokenType) {
		return;
	}

	const cnodeFound = src.match(/cnode=([^&]*)/);
	const cnode = cnodeFound ? cnodeFound[1] : '';

	return {
		path: getUrlPath(src),
		renewalIntervalInMins: tokenRenewalIntervalInMins,
		resourceId,
		resourceType,
		tokenName: tokenType,
		cnode,
		tokenEndpoint: PLAYBACK_TOKEN_END_POINT,
		withCredentials: useWithCredentials(src)
	};
}

export function useWithCredentials(src: string): boolean {
	return checkWithCredParam(src) && (isIe() || !src.includes(PlaybackTokenType.CloudFrontToken));
}

export function checkWithCredParam(src: string): boolean {
	const params = getQueryParamsFromUrl(src);

	if (params.has(WITH_CRED_KEY)) {
		const paramValue = params.get(WITH_CRED_KEY);
		return paramValue?.toLowerCase() === 'true';
	}
	return true;
}

export function removeWithCredParam (url: string): string {
	return removeQueryParamsFromUrl(url, [WITH_CRED_KEY]);
}
