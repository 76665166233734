import { Directive, EventEmitter, Output, SimpleChanges, OnDestroy, OnChanges, Input } from '@angular/core';
/**
 * Directive to handle header cell keyboard event. this can be extended to any data cell keyboard event as well.
 * For Header cell, capture eGridHeader element present in IHeaderParams. ( see HeaderSelectAllCheckboxRendererComponent for quick reference )
 * user can pass key names like enter/space etc.. in keyCodes Input and events will be emitted for those events only.
 * If no keyCodes is passed then events will be emitted for all keys.
 */
@Directive({
	selector: '[vbUiGridCellKeyboardHandler]'
})
export class VbUiGridCellKeyboardHandlerDirective implements OnChanges, OnDestroy {
	@Input() public headerElement: HTMLElement;
	@Input() public keyCodes: string[];

	@Output() public keyPressed = new EventEmitter<KeyboardEvent>();

	private onKeyPress = $event => this.emitKeyPressEvent($event);

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.headerElement) {
			this.attachEventHandler();
		}
	}

	public ngOnDestroy(): void {
		this.headerElement?.removeEventListener('keydown', this.onKeyPress);
	}

	private attachEventHandler(): void {
		this.headerElement?.addEventListener('keydown', this.onKeyPress);
	}

	public emitKeyPressEvent(event: KeyboardEvent) {
		if (!this.keyCodes?.length) {
			this.keyPressed.emit(event);
			return;
		}
		const key = event.key?.toLowerCase();
		const code = event.code?.toLowerCase();

		if (this.keyCodes?.length && this.keyCodes.find(eKey => eKey === key || eKey === code)) {
			this.keyPressed.emit(event);
		}

	}
}
