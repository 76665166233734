import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { IDynamicPlaylistSeachFilter, IPlaylist, IPlaylistData, PlaylistType } from './IPlaylist';
import { VideoService, getThumbnailCfg } from './Video.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { IMediaFeatures } from './IMediaFeatures';
import { SearchService } from 'rev-portal/search/Search.Service';
import { getFiltersQuery } from 'rev-portal/media/search/SearchFilterState.Service';

export const FEATURED_VIDEOS_PLAYLIST_ID = 'featured';

interface ISavePlaylist {
	playlistId: string;
	name: string;
	videoIds?: string[];
	searchFilter?: IDynamicPlaylistSeachFilter;
}

@Injectable({
	providedIn: 'root'
})
export class PlaylistService {
	constructor(
		private DateParsers: DateParsersService,
		private PushService: PushService,
		private SearchService: SearchService,
		private UserContext: UserContextService,
		private VideoService: VideoService,
		private http: HttpClient
	) {}

	public addFeaturedVideo(video: any): Promise<void> {
		return this.PushService.dispatchCommand('media:AddFeaturedVideo', video);
	}

	public addVideoToPlaylists(videoId: string, userId: string, playlistIds: string[]): Promise<void> {
		return this.PushService.dispatchCommand('media:AddVideoToPlaylists', {
			videoId,
			userId,
			playlistIds
		});
	}

	public createPlaylist(playlist: {name: string, userId: string, playlistType: PlaylistType, searchFilter?: IDynamicPlaylistSeachFilter }): Promise<string> {
		return this.PushService.dispatchCommand('media:CreatePlaylist', playlist, 'PlaylistCreated')
			.then(result => result.message.playlistId);
	}

	public deletePlaylist(playlistId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:DeletePlaylist', { playlistId });
	}

	public getFeaturedContent(accountId: string, fetchActiveVideosOnly?: boolean, mediaFeatures?: IMediaFeatures): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/accounts/${accountId}/featured-content`,
			{
				params: this.getIsActiveParam(fetchActiveVideosOnly)
			})).then(result => this.processFeaturedVideoContent(result, mediaFeatures));
	}

	public getFeaturedVideos(accountId: string, fetchActiveVideosOnly?: boolean, mediaFeatures?: IMediaFeatures): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/accounts/${accountId}/featured-videos`,
			{
				params: this.getIsActiveParam(fetchActiveVideosOnly)
			})).then(result => this.processFeaturedVideoContent(result, mediaFeatures));
	}

	private processFeaturedVideoContent(result: any, mediaFeatures?: IMediaFeatures): Promise<any> {
		result.whenVideoAddedOrDeleted = result.whenVideoAddedOrDeleted || {};
		result.whenVideoAddedOrDeleted.when = this.DateParsers.parseUTCDate(result.whenVideoAddedOrDeleted.when);
		result.createdBy.when = this.DateParsers.parseUTCDate(result.createdBy.when);

		result.videos.forEach((video, playlistIndex) => Object.assign(video, {
			duration: this.DateParsers.parseTimespan(video.duration),
			thumbnailUri: video.thumbnailUri || '',
			thumbnailCfg: getThumbnailCfg(video.thumbnailCfg),
			uploaderFirstName: video.uploaderFirstName || '',
			subtitles: (video.transcriptionFiles || []).map(this.VideoService.toPlayerDirectiveSubtitle),
			playlistIndex,
			ownerUserId: video.owner.userId || '',
			ownerUsername: video.owner.username || '',
			ownerFirstName: video.owner.firstName || '',
			ownerLastName: video.owner.lastName || '',
			ownerProfileImageUri: video.owner.profileImageUri,
			ownerFullName: this.shapeOwnerFullName(video.owner?.firstName, video.owner?.lastName),
			ratingsCount: video.ratingCount,
			videoOverlays: mediaFeatures ? this.VideoService.getViewerIdOverlays(mediaFeatures, video.viewerIdEnabled) : undefined
		}));

		return Object.assign(result, {
			count: result.videos.length,
			featured: true,
			id: FEATURED_VIDEOS_PLAYLIST_ID,
			totalVideos: result.videos.length
		});
	}

	public getPlaylist(playlistId: string, fetchActiveVideosOnly?: boolean): Promise<IPlaylistData> {
		const accountId = this.UserContext.getAccount().id;
		if (playlistId === FEATURED_VIDEOS_PLAYLIST_ID) {
			return this.getFeaturedVideos(accountId, fetchActiveVideosOnly);
		}

		return lastValueFrom(this.http.get<any>(`/media/playlists/${playlistId}`,
			{
				params: this.getIsActiveParam(fetchActiveVideosOnly)
			}))
			.then(result => {
				result.videos?.forEach((v, i) => Object.assign(v, {
					whenUploaded: this.DateParsers.parseUTCDate(v.whenUploaded),
					duration: this.DateParsers.parseTimespan(v.duration),
					uploaderFirstName: v.uploaderFirstName || '',
					playlistIndex: i + 1,
					ownerUserId: v.owner.userId || '',
					ownerUsername: v.owner.username || '',
					ownerFirstName: v.owner.firstName || '',
					ownerLastName: v.owner.lastName || '',
					ownerProfileImageUri: v.owner.profileImageUri,
					ownerFullName: this.shapeOwnerFullName(v.owner?.firstName, v.owner?.lastName),
					ratingsCount: v.ratingCount
				}));

				if(result.playlistType === PlaylistType.Dynamic) {
					return this.getDynamicPlaylistVideos(accountId, result.searchFilter)
						.then(videos => {
							result.videos = videos;
							return result;
						});
				}

				return result;
			});
	}

	public getUserPlaylists(userId: string): Promise<IPlaylist[]> {
		return lastValueFrom(this.http.get<any>(`/media/users/${userId}/playlists`))
			.then(result => {
				result.playlists.forEach(p => {
					p.whenVideoAddedOrDeleted = p.whenVideoAddedOrDeleted || {};
					p.whenVideoAddedOrDeleted.when = this.DateParsers.parseUTCDate(p.whenVideoAddedOrDeleted.when);
					p.createdBy.when = this.DateParsers.parseUTCDate(p.createdBy.when);
				});

				return result.playlists;
			});
	}

	public modifyFeaturedVideos(videoIds: any[]): Promise<void> {
		return this.PushService.dispatchCommand('media:SaveFeaturedVideos', { videoIds });
	}

	public modifyPlaylist(playlist: ISavePlaylist): Promise<void> {
		return this.PushService.dispatchCommand('media:SavePlaylistDetails', playlist);
	}

	public removeFeaturedVideo(video: any): Promise<void> {
		return this.PushService.dispatchCommand('media:RemoveFeaturedVideo', video);
	}

	public removeVideoFromPlaylists(videoId: string, userId: string, playlistIds: string[]): Promise<void> {
		return this.PushService.dispatchCommand('media:RemoveVideoFromPlaylists', {
			videoId,
			userId,
			playlistIds
		});
	}

	private getIsActiveParam(isActive: boolean): HttpParams {
		return isActive ? new HttpParams().set('isActive', 'true') : undefined;
	}

	private shapeOwnerFullName(firstName: string, lastName: string): string {
		return `${firstName || ''} ${lastName || ''}`.trim();
	}

	public getDynamicPlaylistVideos(accountId: string, searchFilter: IDynamicPlaylistSeachFilter): Promise<any[]> {
		return this.SearchService.getVideos({
			accountId,
			query: searchFilter.searchTerm,
			parsedQuery: getFiltersQuery(searchFilter),
			sortAscending: !searchFilter.sortDescending,
			sortField: searchFilter.sortField,
			subtitles: true,
			count: 100
		}).then(results => {
			const videos = results.videos;
			videos.forEach(v => v.ownerFullName = v.ownerName);
			return videos;
		});

	}

}
