import { Optional, Provider, SkipSelf } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

export const DTMF_CODES_REGEX = '[A-D0-9#* ]{0,}';
export const SINGLE_DTMF_CODE_REGEX = '[A-D0-9#*]{0,}';

export function toggleError(control: AbstractControl, key: string, setError: boolean): void {
	const invalid = control.invalid && control.errors?.[key];
	if(invalid === setError) {
		return;
	}

	const errors = control.errors || {};
	if(setError) {
		errors[key] = true;
	}
	else {
		delete errors[key];
	}

	control.setErrors((Object.keys(errors).length === 0) ? null : errors);
}

export function parentControlContainerProvider(): Provider {
	return {
		provide: ControlContainer,
		useFactory: (container: ControlContainer) => container,
		deps: [[new Optional(), new SkipSelf(), ControlContainer]],
	};
}

export function combineErrors(control: AbstractControl, error: any): void {
	if (!control) {
		return;
	}

	const errors: { [key: string]: boolean } = {};

	const current = control.errors;
	const combinedErrors = { ...(current || {}), ...(error || {}) };
	Object.keys(combinedErrors).forEach(key => {
		if (combinedErrors[key]) {
			errors[key] = true;
		}
	});

	control.setErrors(Object.keys(errors).length ? errors : null);
}
