<ng-container *ngFor="let buttonDef of buttons">
	<button *ngIf="!buttonDef.onlyIconDisplay; else onlyIcon" vbUiBtnSecondary class="margin-right-5" type="button" (click)="onClick(buttonDef)">
		<span [ngClass]="buttonDef.iconClass"></span>
		{{ buttonDef.label }}
	</button>

	<ng-template #onlyIcon>
		<button type="button" class="btn btn-transparent padding-0" (click)="onClick(buttonDef)">
			<span [ngClass]="buttonDef.iconClass"></span>
	</button>
	</ng-template>
</ng-container>
