import { IWebcastTranslationKeys } from 'rev-shared/webcast/IWebcastTranslationKeys';

export const WEBCAST_SHORTCUT_STATE = 'portal.eventShortcut';
export const WEBCAST_FULLSCREEN_STATE = 'webcast-fullscreen';

export const WEBCAST_ROOT_STATE = 'portal.scheduledEvents';
export const WEBCAST_LANDING_STATE = `${WEBCAST_ROOT_STATE}.webcast`;
export const WEBCAST_REPORTS_STATE = `${WEBCAST_LANDING_STATE}.reports`;
export const WEBCAST_VIEW_STATE = `${WEBCAST_LANDING_STATE}.view`;
export const WEBCAST_REGISTERED_GUESTS_STATE = `${WEBCAST_LANDING_STATE}.guests`;
export const WEBCAST_PREVIEW_STATE = `${WEBCAST_LANDING_STATE}.preview`;
export const INFO_STATE = `${WEBCAST_VIEW_STATE}.info`;
export const CALENDAR_STATE_NAME = `${WEBCAST_ROOT_STATE}.all`;

export const WEBCAST_FULLSCREEN_PRESENTATION_STATE = `${WEBCAST_FULLSCREEN_STATE}.presentation`;
export const WEBCAST_FULLSCREEN_MANAGE_QUESTION_STATE = `${WEBCAST_FULLSCREEN_STATE}.manage-questions`;

export const WEBCAST_ROOT_STATE_URL = `/events`;

export const TRANSLATION_KEYS: IWebcastTranslationKeys = {
	infoSidebarHeader: 'Event_Webcast_InformationPanelHeader',
	eventNotStarted: 'Event_EventHasNotStarted',
	eventEnded: 'Event_EventHasEndedOrUpdating'
};

export function getEventUrl(webcastId: string, query?: string): string {
	return `${WEBCAST_ROOT_STATE_URL}/${webcastId}${query ? '?' + query : ''}`;
}
