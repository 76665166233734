import { Component, Input } from '@angular/core';

import { SpinnerState } from 'rev-shared/ui/spinner/Spinner';

@Component({
	selector: 'camera-spinner',
	templateUrl: './CameraSpinner.Component.html'
})
export class CameraSpinnerComponent {
	@Input() public msg: string;
	@Input() public state: SpinnerState;
}
