import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TranslateModule } from '@ngx-translate/core';

import { AccessEntityOptionRowComponent, AccessEntityAssignedRowComponent, CategoryAdminAssignedRowComponent, TeamAdminAssignedRowComponent, ApproverAssignedRowComponent, EventModeratorAssignedRowComponent } from './templates/InsightRow.Component';
import { VbProfilePictureModule } from '../profile/VbProfilePicture.Module';
import { VbDropdownMenuModule } from '../dropdown-menu/VbDropdownMenu.Module';
import { InsightModule } from './ngx/Insight.Module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VbInsightAngularComponent } from './VbInsightAngular.Component';

const components = [
	AccessEntityAssignedRowComponent,
	AccessEntityOptionRowComponent,
	ApproverAssignedRowComponent,
	CategoryAdminAssignedRowComponent,
	EventModeratorAssignedRowComponent,
	TeamAdminAssignedRowComponent,
	VbInsightAngularComponent
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonsModule,
		VbDropdownMenuModule,
		BsDropdownModule,
		TranslateModule,
		InsightModule,
		VbProfilePictureModule
	],
	declarations: [...components],
	exports: [...components]
})
export class InsightAngularModule {
}
