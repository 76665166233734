export enum VideoHeartbeatEventType {
	BUFFERING_START = 'BufferingStarted',
	BUFFERING_STOP = 'BufferingStopped',
	COMPLETE = 'Complete',
	ERROR = 'Error',
	HEARTBEAT = 'Heartbeat',
	KEEPALIVE = 'KeepAlive',
	PAUSE = 'Pause',
	PLAY = 'Play',
	PLAYBACK_STARTED = 'PlaybackStarted',
	PLAYBACK_UPDATED = 'PlaybackUpdated',
	SEEK = 'Seek',
	SPINNER = 'Spinner',
	STOP = 'Stop',
}
