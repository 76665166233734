import { Component, Input, HostBinding, ViewEncapsulation, SimpleChanges } from '@angular/core';

import type { BodyScrollEvent, ColDef } from '@ag-grid-community/core';

import { filter } from 'rxjs/operators';

import { SecondMs } from 'rev-shared/date/Time.Constant';

import { COMPONENT_HOST, COMPONENT_PROVIDERS, COMPONENT_TEMPLATE, VbUiDataGridComponent } from '../VbUiDataGrid.Component';

import { IGetInfiniteScrollRows } from './IGetInfiniteScrollRows';
import { VbUiInfiniteScrollGridDataSource } from './VbUiInfiniteScrollGridDataSource';

import infiniteGridStyles from './infiniteScroll.module.less';

const DEFAULT_PAGE_SIZE: number = 25;

export const INFINITE_GRID_HOST = {
	...COMPONENT_HOST,
	'[class]': 'infiniteGridHostClass'
};

@Component({
	selector: 'vb-ui-infinite-scroll-grid',
	template: `
		${COMPONENT_TEMPLATE}
		<div [ngClass]="infiniteGridStyles.noRecords"
			*ngIf="(vbUiDataSource?.noRecord$ | async)">
			<h2>{{ 'UI_NoResultsFound' | translate }}</h2>
		</div>
		<div [ngClass]="[infiniteGridStyles.scrollExpiredMsg, this.themed ? infiniteGridStyles.themedLink : '']"
			*ngIf="scrollExpired">
			<a (click)="resetDataModel()">
				{{ 'UI_ClickToRefreshTable' | translate }}
			</a>
		</div>
	`,
	host: INFINITE_GRID_HOST,
	providers: COMPONENT_PROVIDERS,
	encapsulation: ViewEncapsulation.None
})
export class VbUiInfiniteScrollGridComponent extends VbUiDataGridComponent {
	@Input() public getRows: IGetInfiniteScrollRows;
	@Input() public clearScrollId: (ctx: any, scrollId: string) => void;
	@Input() public dataFetchContext: any;
	@Input() public pageSize: number;
	@Input() public pollingIntervalSecs: number;

	public dataAsOf: Date;
	public readonly infiniteGridStyles = infiniteGridStyles;
	public infiniteGridHostClass: string;

	public isPollingActive: boolean = true;
	protected pollingTimeoutRef: number;

	@HostBinding('class.infiniteGridEnableStatusBar')
	public get scrollExpired(): boolean{
		return this.vbUiDataSource?.scrollExpired;
	}

	public ngOnInit(): void {
		this.cacheBlockSize = this.pageSize || DEFAULT_PAGE_SIZE;
		this.maxConcurrentDatasourceRequests = 1;
		this.rowModelType = 'infinite';
		this.sortingOrder = ['asc', 'desc'];

		this.subscriptions.push(
			this.bodyScroll
				.pipe(
					filter((event: BodyScrollEvent) => event.direction === 'vertical')
				)
				.subscribe(
					event => this.onVerticalScroll(event),
					err => console.error('bodyScroll error: ', err)),
			this.modelUpdated.subscribe(
				() => this.onModelUpdatedInternal(),
				err => console.error('modelUpdated error: ', err))
		);

		this.infiniteGridHostClass = `${this.hostClass} ${this.infiniteGridStyles.root}`;

		super.ngOnInit();
	}

	public ngAfterViewInit() {
		return super.ngAfterViewInit()
			.then(() => {
				if (this.quickFilterText) {
					this.updateQuickFilter();
				}
				this.datasource = new VbUiInfiniteScrollGridDataSource(this.getRows, this.isSortable, this.zone, this.api, this.dataFetchContext);
				this.api.updateGridOptions({ datasource: this.datasource });
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.quickFilterText) {
			this.updateQuickFilter();

			// prevent console warning about usage of the feature thrown by super
			changes.quickFilterText = undefined;
		}

		if (changes.dataFetchContext && !changes.dataFetchContext.firstChange) {
			this.updateDataFetchContext();
		}

		//making sure each property of changes obj should have a value.
		if (Object.values(changes).some(value => value == null)) {
			return;
		}

		super.ngOnChanges(changes);
	}

	public ngOnDestroy(): void {
		if(this.vbUiDataSource?.scrollId) {
			this.clearScrollId?.(this.context, this.vbUiDataSource.scrollId);
		}
		this.cancelPollingTimeout();

		super.ngOnDestroy();
	}

	public pausePolling(): void {
		this.isPollingActive = false;

		this.cancelPollingTimeout();
	}

	public resumePolling(): void {
		this.isPollingActive = true;

		this.resetDataModel();
	}

	public setQuickFilterText(value: string): void {
		super.setQuickFilterText(value, true);

		this.updateQuickFilter();
	}

	protected get isSortable(): boolean {
		const colDefs: ColDef[] = this.columnDefs ||
			this.columnApi.getColumns()?.map(col => col.getColDef());

		return colDefs?.some(col => col.sortable);
	}

	protected cancelPollingTimeout(): void {
		if (this.pollingTimeoutRef) {
			window.clearTimeout(this.pollingTimeoutRef);
			this.pollingTimeoutRef = null;
		}
	}

	protected onModelUpdatedInternal(): void {
		if ((this.vbUiDataSource?.currentPage || 1) < 2) {
			this.schedulePollingTimeout();

			this.dataAsOf = new Date();
		}
	}

	public resetDataModel(): void {
		this.cancelPollingTimeout();
		(this.api.getModel() as any).reset();
	}

	protected schedulePollingTimeout(): void {
		if (
			!this.pollingIntervalSecs ||
			!this.isPollingActive
		) {
			return;
		}

		this.cancelPollingTimeout();

		this.pollingTimeoutRef = window.setTimeout(() => {
			this.vbUiDataSource?.resetScrollId();
			this.resetDataModel();
		}, this.pollingIntervalSecs * SecondMs);
	}

	protected onVerticalScroll(event: BodyScrollEvent): void {
		if (event.top > 0 && this.isPollingActive) {
			this.pausePolling();
		}
	}

	private updateQuickFilter(): void {
		if (!this.api) {
			return;
		}

		const colIds: string[] = this.columnApi.getColumns().map(col => {
			const colDef = col.getColDef();
			return col.isVisible() && colDef.filter !== false ? colDef.colId || colDef.field : undefined;
		}).filter(Boolean);

		const filterModelValue = colIds.reduce((out, currentValue) => {
			out[currentValue] = { filter: this.quickFilterText };

			return out;
		}, {});

		this.api.setFilterModel(filterModelValue);
	}

	private updateDataFetchContext(): void {
		this.cancelPollingTimeout();

		this.vbUiDataSource?.setDataFetchContext(this.dataFetchContext);
		this.api?.updateGridOptions({ datasource: this.datasource });
	}

	public get vbUiDataSource(): VbUiInfiniteScrollGridDataSource {
		return this.datasource as VbUiInfiniteScrollGridDataSource;
	}
}
