import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';

export enum TopType {
	MARGIN = 'Margin',
	PADDING = 'Padding'
}

export interface IMediaStateConfig {
	accessParentCategory?: boolean;
	baseCategoryId?: string;
	adjustMediaResultTop?: TopType;
	redirectToAllTeams?: boolean;
	showBulkEdit?: boolean;
	showEditTeam?: boolean;
	showSignInBtn?: boolean;
	showSubscribeSparkBtn?: boolean;
	showUploadToTeam?: boolean;
	showDownloadInventoryReports?: boolean;
	videoPlaybackConfig?: IVideoPlaybackConfig;
}
