import { NgZone } from '@angular/core';
import { indexBy, partition } from 'underscore';

import { IProducerLayout, IProducerLayoutVideo } from 'rev-shared/webcast/webcastView/producer/Contract';
import { orderBy } from 'rev-shared/util/SortUtil';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { IInboundStream, IPresenter, IPresenterAVStream, IPresenterStreams, StreamType } from './Contract';
import { WebRtcListenerConnectionService } from './WebRtcListenerConnection.Service';
import { BehaviorSubject, Subject } from 'rxjs';

export type StreamsInLayout = {
	speaker: boolean;
	content: boolean;
	audio: boolean;

	speakerBg: boolean; //Only set if speaker/content video is in the bg slot
	contentBg: boolean;
};

export class ListenerPresenters {

	public readonly presentersSubject = new BehaviorSubject<IPresenter[]>(null);
	public readonly presenters$ = this.presentersSubject.asObservable();
	public readonly listenerMsgSubject$ = new Subject<string>();
	public readonly listenerMsg$ = this.listenerMsgSubject$.asObservable();
	private readonly onUnload = () => this.WebRtcListenerConnectionService.leavePeerEvent();

	public presentersById: { [key: string]: IPresenter } = {};

	private activeSpeakerVolume: { [key: string]: number } = {};

	public get presenters(): IPresenter[] {
		return this.presentersSubject.value;
	}

	constructor(
		initialPresenters: any[],
		private readonly userId: string,
		private readonly WebRtcListenerConnectionService: WebRtcListenerConnectionService,
		private ngZone: NgZone
	) {
		this.setPresenters(
			orderBy(initialPresenters
				.map(readPresenter),
			p => p.name));
		this.listenEvents();
	}

	public initialize(): void {
		this.presenters.forEach(presenter => {
			if(presenter.id === this.userId) {
				return;
			}

			presenter.streams.forEach(stream => {
				retryUntilSuccess(() => this.startStream(presenter, stream), 3, undefined, err => err.status === 'InternalServerError')
					.catch(e => console.error('Error starting stream: ', e));
			});
		});

		window.addEventListener('unload', this.onUnload);
	}

	public stop(): void {
		this.presenters.forEach(p => this.stopPresenter(p));
		window.removeEventListener('unload', this.onUnload);
	}

	private listenEvents() {
		this.WebRtcListenerConnectionService.onSocket('activeSpeaker', volumes => {
			this.activeSpeakerVolume = volumes.reduce((acc, { peerId, volume }) => {
				if(peerId && volume >= 0) {
					acc[peerId] = Math.max(volume, acc[peerId] || 0);//peer can have volume for multiple producers, take largest one
				}
				return acc;
			}, {});
			this.presentersSubject.next(this.presentersSubject.value);
		});

		this.WebRtcListenerConnectionService.onSocket('newPresenter', ({ presenter }) => this.onNewPresenter(presenter));
		this.WebRtcListenerConnectionService.onSocket('presenterLeft', ({ id }) => this.onPresenterLeft(id));
		this.WebRtcListenerConnectionService.onSocket('newStreamProducer', ({ id, producer }) => this.onNewStreamProducer(id, producer));
		this.WebRtcListenerConnectionService.onSocket('streamProducerClosed', ({ id, producerId }) => this.onStreamProducerClosed(id, producerId));

		const setMute = muted => ({ peerId }) => {
			const presenter = this.presenters.find(p => p.id === peerId);
			this.replacePresenter(peerId, { ...presenter, muted });
		};

		this.WebRtcListenerConnectionService.onSocket('presenterMuted', setMute(true));
		this.WebRtcListenerConnectionService.onSocket('presenterUnmuted', setMute(false));

		this.WebRtcListenerConnectionService.onSocket('startTalk', ({ peerId, toPeerId }) => this.setTalking(true, peerId, toPeerId));
		this.WebRtcListenerConnectionService.onSocket('stopTalk', ({ peerId, toPeerId }) => this.setTalking(false, peerId, toPeerId));
		this.WebRtcListenerConnectionService.onSocket('RecordingStopped', () => this.listenerMsgSubject$.next('RecordingStopped'));
	}

	private onNewPresenter(presenter: any): void {
		console.log('ListenerPresenters newPresenter', presenter);
		if(!presenter?.id) {
			return;
		}

		if(this.presenters.some(p => p.id === presenter.id)) {
			console.error('Duplicate presenter', presenter);
			return;
		}

		this.setPresenters(
			orderBy([...this.presenters, readPresenter(presenter)],
				p => p.name));
	}

	private onPresenterLeft(presenterId: string): void {
		console.log('ListenerPresenters presenterLeft', presenterId);

		const [[presenter], remainingPresenters] = partition(this.presenters, p => p.id === presenterId);
		if(!presenter) {
			return;
		}
		this.stopPresenter(presenter);
		this.setPresenters(remainingPresenters);
	}

	private onNewStreamProducer(presenterId: string, producer: any): void {
		console.log('ListenerPresenters newStreamProducer', presenterId, producer);
		const presenter = this.presenters.find(p => p.id === presenterId);
		if(!presenter) {
			console.log('newStreamProducer, no presenter found');
			return;
		}
		const stream = { producer };
		this.replacePresenter(presenterId, {
			...presenter,
			streams: [...presenter.streams, stream]
		});

		if(presenterId === this.userId) {
			//Don't start the consumer for your own local streams
			return;
		}

		retryUntilSuccess(() => this.startStream(presenter, stream), 3, undefined, err => err.status === 'InternalServerError')
			.catch(e => console.error('Error starting stream: ', e));
	}

	private onStreamProducerClosed(presenterId: string, producerId: string): void {
		console.log('ListenerPresenters streamProducerClosed', presenterId, producerId);
		const presenter = this.presenters.find(p => p.id === presenterId);
		if(!presenter) {
			return;
		}
		const [[stream], remainingStreams] = partition(presenter.streams, s => s.producer.id === producerId);
		if(stream) {
			stream.consumer?.close();
		}
		this.setPresenters(
			this.presenters.map((p, i) => p !== presenter ? p : {
				...p,
				...getPlaybackStreams(p, remainingStreams),
				streams: remainingStreams,
			}));
	}

	private startStream(presenter: IPresenter, stream: IInboundStream): Promise<any> {
		return this.WebRtcListenerConnectionService.createConsumer(presenter.id, stream.producer.id)
			.then(consumer => {
				console.log('consumer:', consumer);
				presenter = this.getPresenter(presenter.id);
				const streams = this.updateStream(presenter.streams, stream, { consumer });

				presenter = {
					...this.getPresenter(presenter.id),
					...getPlaybackStreams(presenter, streams),
					streams
				};
				this.replacePresenter(presenter.id, presenter);

				consumer.on('trackended', () => {
					presenter = this.getPresenter(presenter.id);
					const streams = this.updateStream(presenter.streams, stream, { consumer: null });
					this.replacePresenter(presenter.id, {
						...presenter,
						...getPlaybackStreams(presenter, streams),
						streams
					});
				});

				return this.WebRtcListenerConnectionService.resumeConsumer(consumer.id);
			});
	}

	private stopPresenter(presenter: IPresenter): void {
		presenter.streams.forEach(stream => {
			stream?.consumer?.close();
		});
	}

	public tryCreateProducerStream(peerId: string, producerId: string, oldStream: MediaStream): MediaStream {
		const inboundStream = this.presenters
			.find(p => p.id === peerId)
			?.streams.find(stream => stream.producer.id === producerId);

		const track = inboundStream?.consumer?.track;
		const oldTrack = oldStream?.getTracks()[0];

		return !track ? null :
			track === oldTrack ? oldStream :
			new MediaStream([track]);
	}

	public replacePresenter(id: string, presenter: IPresenter): void {
		this.setPresenters(this.presenters.map(p => p.id !== id ? p : presenter));
	}

	public getPresenter(id: string): IPresenter {
		return this.presentersById[id];
	}

	public getLayoutPresenters(layout: IProducerLayout): IPresenter[] {
		const presenters = this.getLayoutStreams(layout)
			.reduce((presenters, { presenter }) => {
				presenters[presenter.id] = presenter;
				return presenters;
			}, {});

		return Object.values(presenters);
	}

	public getLayoutStreams(layout: IProducerLayout, noBg?: boolean): Array<{ presenter: IPresenter, stream: IProducerLayoutVideo}> {
		return ([
			...layout.audioOnlyStreams,
			...layout.videos,
			!noBg && layout.backgroundVideo
		])
			.map(v => this.getLayoutSlotPresenter(v))
			.filter(Boolean);
	}

	public getLayoutSlotPresenter(v: IProducerLayoutVideo): { presenter: IPresenter, stream: IProducerLayoutVideo} {
		const presenter = v && this.getPresenter(v.userId);
		if(!presenter) {
			return;
		}

		const hasVideoStream = v.type !== StreamType.Audio && presenter.streams.find(s => s.producer.id === v.videoProducerId);
		if(hasVideoStream) {
			return { presenter, stream: v };
		}

		const hasAudioStream = presenter.streams.find(s => v.audioProducerIds.includes(s.producer.id));
		if(!hasAudioStream) {
			return;
		}

		return {
			presenter,
			stream: v.type === StreamType.Audio ? v : {
				...v,
				type: StreamType.Audio
			}
		};
	}

	public checkPresenterLayout(presenterId: any, layout: IProducerLayout): StreamsInLayout {
		if(!layout) {
			return {} as any;
		}

		return this.getLayoutStreams(layout)
			.reduce((acc, { presenter, stream }) => {
				if(presenter.id === presenterId) {
					acc[stream.type] = true;

					if(layout.backgroundVideo === stream) {
						if(stream.type === StreamType.Speaker) {
							acc.speakerBg = true;
						}
						else if(stream.type === StreamType.Content) {
							acc.contentBg = true;
						}
					}
				}
				return acc;
			}, {} as StreamsInLayout);
	}

	private setPresenters(presenters: IPresenter[]): void {
		this.ngZone.run(() => {
			this.presentersById = indexBy(presenters, p => p.id);
			this.presentersSubject.next(presenters);
		});
	}

	public updateStream(streams: IInboundStream[], stream: IInboundStream, updates: Partial<IInboundStream>): IInboundStream[] {
		return streams.map(s => s !== stream ? s : {
			...stream,
			...updates
		});
	}

	public getProducer(presenterId: string, producerId: string): IInboundStream {
		const presenter = this.getPresenter(presenterId);
		return presenter?.streams.find(stream => stream.producer.id === producerId);
	}

	public getVolume(presenterId: string): number {
		const volume = this.activeSpeakerVolume[presenterId];
		if(volume >= 0) {
			//quietest volume seems to be around 0.4
			const quiet = 0.4;
			return Math.max(0, (volume - quiet) / (1 - quiet));
		}
	}

	public isSpeaking(presenterId): boolean {
		return this.activeSpeakerVolume[presenterId] > 0.5;
	}

	private setTalking(isTalking: boolean, fromPeerId: string, toPeerId: string) {
		const fromPresenter = this.presentersById[fromPeerId];

		this.replacePresenter(fromPeerId, {
			...fromPresenter,
			isPushToTalk: isTalking,
			pushToTalkUser: toPeerId
		});
	}
}

function getPlaybackStreams(presenter: IPresenter, streams: IInboundStream[]): Partial<IPresenter> {
	const streamTypes = getPresenterStreams(streams);

	return {
		cameraStream: syncStream(streamTypes.speaker, presenter.cameraStream),
		captureStream: syncStream(streamTypes.content, presenter.captureStream)
	};
}

export function getLayoutVideo(presenter: IPresenter, type: StreamType): IProducerLayoutVideo {
	const streams = getPresenterStreams(presenter.streams);
	const stream = streams[type === StreamType.Audio ? StreamType.Speaker : type];
	const audioProducerIds = [stream.audio?.producer.id];
	const micAudio = type !== StreamType.Speaker && streams.speaker.audio;
	if(micAudio) {
		audioProducerIds.push(micAudio.producer.id);
	}

	return {
		userId: presenter.id,
		type,
		videoProducerId: type !== StreamType.Audio ? stream.video?.producer.id : undefined,
		audioProducerIds
	};
}


export function getPresenterStreams(streams: IInboundStream[]): IPresenterStreams {
	return streams.reduce((data, stream) => {
		const trackType = stream.producer.kind;
		const source = stream.producer.streamType;
		if(trackType && source) {
			data[source][trackType] = stream;
		}
		return data;
	}, {
		speaker: { audio: null, video: null },
		content: { audio: null, video: null }
	}) as IPresenterStreams;
}

function syncStream(streams: IPresenterAVStream, existingStream: MediaStream): MediaStream {
	const audio = streams.audio?.consumer?.track;
	const video = streams.video?.consumer?.track;

	if(audio !== existingStream?.getAudioTracks()[0] ||
		video !== existingStream?.getVideoTracks()[0]) {
		const tracks = [audio, video].filter(Boolean);
		return !tracks.length ? null : new MediaStream(tracks);
	}
	return existingStream;
}

function readPresenter(presenter: any): IPresenter {
	return {
		id: presenter.id,
		name: presenter.name,
		muted: presenter.muted,
		imgUri: presenter.imgUri,
		streams: (presenter.producers || []).map(producer => ({ producer })),
		externalPresenterEmail: presenter.externalPresenterEmail
	};
}
