import { Directive, ElementRef } from '@angular/core';

/**
 * A simple directive to scroll to bottom.
 */
@Directive({
	selector: '[vbScrollToBottom]'
})

export class VbScrollToBottomDirective {

	constructor(private elementRef: ElementRef<HTMLElement>) {
	}

	public isScrollNearBottom(): boolean {
		if (!this.elementRef?.nativeElement) {
			return false;
		}
		const threshold = 10;
		// Add a small threshold for decimals and inconcistencies
		return this.elementRef.nativeElement.scrollTop + this.elementRef.nativeElement.offsetHeight > this.elementRef.nativeElement.scrollHeight - threshold;
	}

	public scrollToBottom(): void {
		if (!this.elementRef?.nativeElement) {
			return;
		}
		this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight;
	}
}
