import { Directive, Input, forwardRef, OnChanges } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[vbMax]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMaxDirective),
			multi: true
		}
	]
})
export class VbMaxDirective implements Validator, OnChanges {
	@Input() public vbMax: any;
	private control: AbstractControl;

	public ngOnChanges(): void {
		this.control?.updateValueAndValidity();
	}

	public validate(control: AbstractControl): ValidationErrors {
		if(!this.control && !(control as any).dummy) {
			this.control = control;
		}

		const value = control.value;
		if (this.vbMax == null || value == null || value === '') {
			return null;
		}

		return value > this.vbMax ? { vbMax: true } : null;
	}
}
