

export function getSessionStorage(key: string): any {
	try {
		const json = window.sessionStorage.getItem(key);
		return json ? JSON.parse(json) : undefined;
	} catch(e) {
		//ignore
	}
}

export function setSessionStorage(key: string, item: any): void {
	window.sessionStorage.setItem(key, JSON.stringify(item));
}
