import { BrandingHeaderType } from './BrandingHeaderType';
import { FeaturedCarouselType } from 'rev-portal/branding/FeaturedCarouselType';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { FeaturedContentType } from 'rev-portal/admin/systemSettings/brandingSettings/FeaturedVideoOptions.Component';

export class ThemeSettings {
	public accentColor: string;
	public accentFontColor?: string;
	public fontFile?: FileWrapper;
	public fontFileName?: string;
	public fontId?: string;
	public fontUri?: string;
	public logoFile?: FileWrapper;
	public logoImageId?: string;
	public logoUri?: string;
	public primaryColor: string;
	public primaryFontColor: string;
}

export class HeaderSettings {
	public backgroundColor: string;
	public brandingHeaderType?: BrandingHeaderType;
	public customHelpLinks?: CustomHelpLink[];
	public fontColor: string;
	public helpUri?: string;
	public useTransparentHeader?: boolean;
	public userProfileImageDimensions?: string;
}

export class FeaturedVideosSettings
{
	public autoscrollDelay: number;
	public backgroundColor: string;
	public backgroundImageFile?: FileWrapper;
	public backgroundImageId: string;
	public backgroundImageUri?: string;
	public featuredCarouselType: FeaturedCarouselType;
	public featuredContent: FeaturedContentType;
}

export class BrandingSettings {
	constructor() {
		this.homepageSettings = new HomepageSettings();
		this.featuredVideosSettings = new FeaturedVideosSettings();
		this.headerSettings = new HeaderSettings();
		this.themeSettings = new ThemeSettings();
	}

	public featuredVideosSettings?: FeaturedVideosSettings;
	public headerSettings?: HeaderSettings;
	public homepageSettings?: HomepageSettings;
	public isLoaded?: boolean;
	public previewResumeTab?: number;
	public themeSettings: ThemeSettings;
}

export class CustomHelpLink {
	public label: string;
	public type: HelpLinkType;
	public value: string;
}

export enum HelpLinkType {
	Email = 'Email',
	Uri = 'Uri'
}

export class HomepageSettings {
	public carousels: CarouselConfig[];
	public homepageTemplateType: HomepageTemplateType;
	public sidebarWidgetTypes: SidebarWidgetType[];
}

export class CarouselConfig {
	public category?: any;
	public categoryId?: string;
	public categoryName?: string;
	public linkId?: string;
	public linkName?: string;
	public linkUserId?: string;
	public playlist?: { playlistId: string, name: string };
	public team?: any;
	public type: CarouselType;
	public sortOrder?: CarouselSortOrder;
}

export enum CarouselType {
	Category = 'Category',
	ContinueWatching = 'ContinueWatching',
	LiveEvents = 'LiveEvents',
	LiveVideos = 'LiveVideos',
	Playlist = 'Playlist',
	RecentVideos = 'RecentVideos',
	Recommended = 'Recommended',
	Teams = 'Teams',
	TeamVideos = 'TeamVideos',
	Subscriptions = 'Subscriptions'
}

export enum CarouselSortOrder {
	Recommended = 'recommended',
	UploadDate = 'whenUploaded',
	Title = 'title.sort',
	Views = 'viewCount'
}

export enum SidebarWidgetType {
	Categories = 'Categories',
	Event = 'Event',
	Teams = 'Teams'
}

export enum HomepageTemplateType {
	Classic = 'Classic',
	CarouselsWithSidebar = 'CarouselsWithSidebar'
}
