import { inject } from '@angular/core';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastVideoSource';

export const getInitialDtmfCodeFn = () => {
	const mediaFeatures: MediaFeaturesService = inject(MediaFeaturesService);
	const features = mediaFeatures.accountFeatures;
	return source => {
		switch(source) {
			case WebcastVideoSource.PEXIP:
				return features.pexipSettings?.dtmfInfo?.defaultInitialCodes;

			case WebcastVideoSource.SIP_ADDRESS:
				return features.vciSettings?.dtmfInfo?.defaultInitialCodes;

			case WebcastVideoSource.ZOOM:
				return features.zoomSettings?.dtmfInfo?.defaultInitialCodes;

			default:
				return '';
		}
	};
};
