import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

export enum AudioCueUrls {
	startBeep = '/sound/start.wav',
	stopBeep = '/sound/stop.wav'
}

@Injectable({
	providedIn: 'root'
})
export class AudioCueService {
	private context: AudioContext;
	private buffers: { [url: string]: any };

	constructor(
		private readonly http: HttpClient
	) {}

	public init(): Promise<any> {
		this.context = new AudioContext();
		this.buffers = {};

		return Promise.all([
			this.cacheFile(AudioCueUrls.startBeep),
			this.cacheFile(AudioCueUrls.stopBeep)
		]);
	}

	public stop(): void {
		this.context = null;
		this.buffers = {};
	}

	public cacheFile(url: string): Promise<any> {
		return lastValueFrom(this.http.get(url, { responseType: 'arraybuffer' }))
			.then((response: any) => this.context.decodeAudioData(response))
			.then(buf => {
				this.buffers[url] = buf;
			});
	}

	public play(url: string, startTime: number = 0): void {
		const buf = this.buffers[url];
		if(!buf) {
			console.error('sound not loaded: ', url);
			return;
		}

		const source = this.context.createBufferSource();
		source.buffer = buf;
		source.connect(this.context.destination);
		source.start(startTime);
	}
}
