import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CollapsableSectionComponent } from './CollapsableSection.Component';
import { CollapsableSectionV2Component } from './CollapsableSectionV2.Component';

const components = [
	CollapsableSectionComponent,
	CollapsableSectionV2Component
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		TranslateModule,
		TooltipModule,
		CollapseModule
	],
	exports: components
})
export class CollapsableSectionAngularModule {}
