import { Component, Input } from '@angular/core';
import { AlertDialogAngularComponent } from './AlertDialogAngular.Component';

@Component({
	selector: 'storyset-dialog',
	templateUrl: './StorysetDialog.Component.html'
})

export class StorysetDialogComponent extends AlertDialogAngularComponent {
	@Input() public imageUrl: string;
}
