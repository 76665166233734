import { Directive, NgZone, OnDestroy, OnInit, inject } from '@angular/core';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { noop } from 'rev-shared/util';

@Directive({})
export class BaseUploadComponent implements OnInit, OnDestroy {
	public features: any;
	public accountId: string;
	public userEmail: string;
	public maxFileUploadSizeBytes: number;
	public chunkUploadSizeBytes: number;
	public viewingHoursUnavailable: boolean;

	private unsubscribeBasePushHandlers: IUnsubscribe;

	public AccountLicense: AccountLicenseService = inject(AccountLicenseService);
	public MediaFeatures: MediaFeaturesService = inject(MediaFeaturesService);
	public UserAccount: UserAccountService = inject(UserAccountService);
	public PushBus: PushBus = inject(PushBus);
	public UserContext: UserContextService = inject(UserContextService);
	public Zone: NgZone = inject(NgZone);

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;
		this.userEmail = this.UserContext.getUser().email;
		this.unsubscribeBasePushHandlers = this.subscribeBasePushHandlers();
	}

	public ngOnDestroy(): void {
		this.unsubscribeBasePushHandlers?.();
	}

	public loadServiceDetails(): Promise<any> {
		return Promise.all([
			this.loadFeatures(),
			this.loadAccount(),
			this.loadLicense()
		]).then(() => {
			this.Zone.run(noop);
		});
	}

	private subscribeBasePushHandlers(): IUnsubscribe {
		return this.PushBus.subscribe(this.accountId, this.UserAccount.RouteScope, {
			AccountDetailsSaved: data => this.storeDetails(data)
		});
	}

	private loadAccount() {
		return this.UserAccount
			.fetchAccountDetail(this.accountId)
			.then(() => this.storeDetails(this.UserAccount.fullAccountDetails[this.accountId]));
	}

	private loadFeatures() {
		return this.MediaFeatures
			.getFeatures(this.accountId)
			.then(features => {
				this.features = features;
			});
	}

	private loadLicense() {
		return this.AccountLicense.reload()
			.then(() => this.viewingHoursUnavailable = !this.AccountLicense.mediaViewingAllowed);
	}

	private storeDetails(data: any): void {
		this.maxFileUploadSizeBytes = data.maxFileUploadSizeGb * FileUtil.Gb;
		this.chunkUploadSizeBytes = data.chunkUploadSizeBytes;
	}
}
