import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { CameraSpinnerComponent } from './CameraSpinner.Component';
import { ConnectingSpinnerComponent } from './ConnectingSpinner.Component';
import { InitializingSpinnerComponent } from './InitializingSpinner.Component';

const components = [
	CameraSpinnerComponent,
	ConnectingSpinnerComponent,
	InitializingSpinnerComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		SpinnerAngularModule,
		TranslateModule
	]
})
export class StatusSpinnersAngularModule {}
