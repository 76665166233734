import { TranslateService } from '@ngx-translate/core';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';

export enum WebcastVideoSource {
	CAPTURE = 'Capture',
	MSTEAMS = 'MicrosoftTeams',
	PEXIP = 'Pexip',
	PRESENTATION = 'PresentationProfile',
	PRODUCER = 'Producer',
	RTMP = 'Rtmp',
	SIP_ADDRESS = 'SipAddress',
	VOD = 'Vod',
	WEBEX = 'WebexTeam',
	WEBEX_EVENTS = 'WebexEvents',
	WEBEX_LIVE_STREAM = 'WebexLiveStream',
	WEBRTC_SINGLE_PRESENTER = 'WebrtcSinglePresenter',
	ZOOM = 'Zoom',
	REV_CONNECT_STREAM = 'RevConnectStream',
	EXTERNAL = 'External'
}


export function getAvailableWebcastSources(TranslateService: TranslateService, features: IMediaFeatures): any[] {
	return [
		{
			label: TranslateService.instant('Microsoft_Teams'),
			value: WebcastVideoSource.MSTEAMS,
			isValid: () => features.vciSettings.isMSTeamsEnabled
		}, {
			label: TranslateService.instant('Event_Webcast_PresentationButton'),
			value: WebcastVideoSource.PRESENTATION
		}, {
			label: TranslateService.instant('Event_Webcast_Rtmp_Rtmps'),
			value: WebcastVideoSource.RTMP,
			isValid: () => !features.onPrem
		}, {
			label: TranslateService.instant('Event_Webcast_Video_Address'),
			value: WebcastVideoSource.SIP_ADDRESS,
			isValid: () => features.vciSettings.isEnabled
		}, {
			label: TranslateService.instant('Event_Webcast_WebexMeetingStream'),
			value: WebcastVideoSource.WEBEX_LIVE_STREAM,
			isValid: () => features?.webexSitesSettings?.some(site => site.enableWebexLiveStream)
		}, {
			label: TranslateService.instant('Features_Spark_Header'),
			value: WebcastVideoSource.WEBEX,
			isValid: () => features.enableSpark
		}, {
			label: TranslateService.instant('Event_Webcast_Pexip'),
			value: WebcastVideoSource.PEXIP,
			isValid: () => features.vciSettings.isEnabled
		}, {
			label: TranslateService.instant('Event_Webcast_Zoom'),
			value: WebcastVideoSource.ZOOM,
			isValid: () => features.zoomSettings.isEnabled
		}, {
			label: TranslateService.instant('Event_Webcast_Vod'),
			value: WebcastVideoSource.VOD,
			isValid: () => !features.onPrem && !features.webcastSources.vodDisabled
		}, {
			label: TranslateService.instant('Event_SelfProduced'),
			value: WebcastVideoSource.WEBRTC_SINGLE_PRESENTER,
			isValid: () => !features.onPrem && !features.webcastSources.webrtcSinglePresenterDisabled
		}, {
			label: TranslateService.instant('Event_Producer'),
			value: WebcastVideoSource.PRODUCER,
			isValid: () => features.producerEnabled
		}
	]
		.filter(source => !source.isValid || source.isValid())
		.filter(Boolean)
		.map(source => ({ label: source.label, value: source.value }));
}
