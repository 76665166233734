import { Injectable } from '@angular/core';

import { Dictionary, forEach } from 'underscore';

import { setOpacity } from 'rev-portal/branding/ColorUtility';

import { isString } from 'rev-shared/util';

import { parseQueryString } from './UrlUtil';
import { escape } from './index';

//Adds params to an existing URL query string.
export function addQueryParams(url: string, params: any): string {
	return modifyQueryParams(url, existing => ({
		...existing,
		...params
	}));
}

/**
 * Programatically creates a pulse animation on the element, uses a hardcoded --theme-primary-txt color
 * @param element - HTMLElement to pulse
 */
export function pulseElement(element: HTMLElement) {
	element.classList.add('pulseAnimation');
	element.addEventListener('animationend', () => {
		element.classList.remove('pulseAnimation');
	});
}

// Programatically downloads a URI
export function downloadURI(uri, fileName): void {
	const link = document.createElement('a');
	link.href = uri;
	link.download = fileName;
	link.click();
}

// Gets user's initials from first and last name
export function getUserInitials(firstName: string, lastName: string): string {
	let initials = '';
	if (firstName) {
		initials += firstName.charAt(0);
	}
	if (lastName) {
		initials += lastName.charAt(0);
	}
	return initials;
}

/**
 * Primarily used in SSO scenario.
 * construction of final url of this method is not as expected for regular window url.
 */
export function modifyQueryParams(url: string, updateFn: (_: any) => any): string {
	let hashIndex = url.indexOf('#');
	if(hashIndex < 0) {
		hashIndex = url.length;
	}
	const hash = url.substring(hashIndex, url.length);

	const queryIndex = url.indexOf('?');
	let query = '';

	if(queryIndex >= 0) {
		query = url.substring(queryIndex + 1, hashIndex);
	}

	const queryParams = updateFn(parseQueryString(query));

	query = urlEncode(queryParams);

	const urlHostAndPath = url.substring(0, queryIndex >= 0 ? queryIndex : hashIndex);

	return `${urlHostAndPath}${query ? '?' : ''}${query}${hash}`;
}

/**
 * add/update/delete url params.
 */
export function modifyUrlQueryParams(url: string, updateFn: (_: any) => any): string {
	const queryIndex = url.indexOf('?');
	let query = '';

	if(queryIndex >= 0) {
		query = url.substring(queryIndex + 1, url.length);
	}

	const queryParams = updateFn(parseQueryString(query));

	query = urlEncode(queryParams, true);

	const urlHostAndPath = queryIndex > 0 ? url.substring(0, queryIndex) : url;

	return `${urlHostAndPath}${query ? '?' + query : ''}`;
}

export function decodeQueryString(): any {
	const qs = window.location.search.substring(1);
	return parseQueryString(qs);
}

/**
 * @param  {string} url
 * @returns string
 * This returns url path (discarding domain name and query params)
 * e.g. - if the url is of format https://dme1.vbrick.com/HLS/myevent/playlist.m3u8?vbtkn=exp=1545318499$id=f7fba1ed-
 * This method returns back /HLS/myevent/playlist.m3u8
 */
export function getUrlPath(url: string): string {
	if(!isString(url)) {
		throw new Error('url should be string');
	}

	const anchorElement = document.createElement('a');
	anchorElement.href = url;

	const path = anchorElement.pathname;
	return path.startsWith('/') ? path : '/' + path; //IE11 not adding slash on the front..
}

/**
	Encodes an object for form submission.
	Param obj:	shallow object - name/value pairs
	Return: url encoded String
**/
export function urlEncode(obj: Dictionary<any>, keepTrue?: boolean): string {
	const parts = [];
	forEach(obj, (value, key) => {
		if(!keepTrue && value === true) {
			parts.push(encodeURIComponent(key));
		}
		else if (value !== undefined) {
			parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
		}
	});
	return parts.join('&');
}

/**
 *
 * @param macAddress
 * @returns a mac address with all special characters removed
 */
export function formatMacAddressNumbersOnly(macAddress: string): string {
	return macAddress.replace(/[^0-9a-zA-Z]+/g, '').toUpperCase();
}

export function getCopyCommandSupported(): boolean {
	return document.queryCommandSupported('copy');
}

export function getWindowOrigin() {
	return window.location.origin;
}

//TODO: Make the remaining functions standalone, remove the UtilService class
@Injectable({ providedIn: 'root' })
export class UtilService {
	public addQueryParams = addQueryParams;
	public decodeQueryString = decodeQueryString;
	public formatMacAddressNumbersOnly = formatMacAddressNumbersOnly;
	public getUrlPath = getUrlPath;
	public urlEncode = urlEncode;
	public getCopyCommandSupported = getCopyCommandSupported;

	public compareSemanticVersion(versionA: string, versionB: string): number {
		const a = versionA.split('.');
		const b = versionB.split('.');

		const len = Math.min(a.length, b.length);

		for(let i = 0; i < len; i++) {
			if(+a[i] > +b[i]) {
				return 1;
			}
			else if(+a[i] < +b[i]) {
				return -1;
			}
		}

		return a.length > b.length ? 1 :
			a.length < b.length ? -1 : 0;
	}

	public readonly htmlEncode = escape;

	public checkPropExists(obj: any, prop: string): boolean {
		return prop in obj;
	}

}
