import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FileUtil } from 'rev-shared/util/FileUtil';

import { SafeHtmlPipe } from './SafeHtml.Pipe';
import { SafeUrlPipe } from './SafeUrl.Pipe';
import { TranslateHtmlPipe } from './TranslateHtml.Pipe';
import { VbTitleCasePipe } from './VbTitleCase.Pipe';

@Pipe({ name: 'fileSize' })
export class FileSizeAngularPipe implements PipeTransform {
	public transform = FileUtil.formatFileSize;
}

const exports = [
	FileSizeAngularPipe,
	SafeHtmlPipe,
	SafeUrlPipe,
	TranslateHtmlPipe,
	VbTitleCasePipe
];

@NgModule({
	declarations: exports,
	exports
})
export class PipesAngularModule {
}
