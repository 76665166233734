export const MAX_LIVE_EVENTS_COUNT = 10;

export enum GridType {
	SESSIONS = 'sessions',
	EVENTS = 'items',
	LIVE_EVENTS = 'events'
}

export enum AnalyticsViewContext {
	Unknown = 'Unknown',
	Embedded = 'Embedded',
	Portal = 'Portal',
	MsTeams = 'MSTeams',
	VideoOnlySharing = 'VideoOnlySharing'
}
