import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
	constructor(
		private sanitizer: DomSanitizer
	) {}

	public transform(value: string): SafeUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value);
	}
}
