import { isNumber } from 'rev-shared/util';

const macRegex = /^([a-f0-9]{2}[.:\-]?){5}[a-f0-9]{2}$/i;

export const ValidationRules = {

	// check if something is an integer/whole number
	checkInteger(value: string|number): boolean {
		if(isNumber(value)) {
			value = value + '';
		}
		return !value || !!(/^-?[0-9]+$/).exec(value);
	},
	// check if something is an email with an intentionally lax regex
	checkEmail(value: string): boolean {
		return !value || !!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
	},

	// check if something is a 'short' text field (100 characters)
	checkShortText(value: string): boolean {
		return this.checkLengthBetween(value, 0, 100);
	},
	// check if something is a 'medium' text field (500 characters)
	checkMediumText(value: string): boolean {
		return this.checkLengthBetween(value, 0, 500);
	},
	// check if something is a 'long' text field (5000 characters)
	checkLongText(value: string): boolean {
		return this.checkLengthBetween(value, 0, 5000);
	},
	// helper function to check if something is between a certain length (useful for postal codes, phone numbers, etc.)
	checkLengthBetween(value: string, min: number, max: number): boolean {
		return !value || checkRange(value.length, min, max);
	},
	// helper function to check if a string is a valid hostname
	// allowed: lower case letters, numbers, dots, and hyphens
	// dots and hyphens cannot be the first character
	checkHostnameText(value: string): boolean {
		return !value || !!value.match(/^[a-z0-9][a-z0-9\-.]+$/i);
	},

	checkMacAddress(value: string): boolean {
		return !value || !!value.match(macRegex);
	},

	checkUrl(value: string, allowedSchemes?: string[], bypassIpV6?: boolean): boolean {
		//very loose URL check
		//http://tools.ietf.org/html/rfc3986
		if(!value) {
			return true;
		}

		const url = '' + value;

		const match = url.toLowerCase().match(/^([a-z][a-z0-9.\-+]+):\/\/((\[[0-9a-f:.]+\])|([a-z0-9][a-z0-9\-.]+))[^\s]*$/i);
		const valid = !!match && (!allowedSchemes || allowedSchemes.includes(match[1]));

		const occurrences = (url.match(/:/g) || []).length;
		if (!bypassIpV6 && occurrences > 2) {
			// It contains ipv6 address, make additional check.  Because the above regex is too loose.  It does not fail an invalid url like http://2001:db8::1/
			const ipv6Match = url.toLowerCase().match(/^([a-z][a-z0-9.\-+]+):\/\/(\[[0-9a-f:.]+\])[^\s]*$/i);
			return valid && !!ipv6Match;
		}

		return valid;
	},

	checkValidCommaSeparatedEmails(value: string): boolean {
		if (!value) {
			return true;
		}
		const emails = value.split(',');
		return emails.every((email, i) => this.checkEmail(email) && emails.indexOf(email, i+1) === -1);
	}

};

function checkRange(value: number, min: number, max: number): boolean{
	return	(!isNumber(min) || value >= min) &&
			(!isNumber(max) || value <= max);
}
