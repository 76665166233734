import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { forwardRef, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { toggleError } from 'rev-shared/util/directives/form/validation/validationUtil';

/**
 * Validation directive that trims the input string.
 * adds required attr for usability
 */
@Directive({
	selector: '[vbRequired]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbRequiredDirective),
		multi: true
	}],

	host: {
		'required': 'required',
	}
})
export class VbRequiredDirective implements OnChanges {
	@Input() public vbRequired: boolean;

	private control: AbstractControl;

	constructor(
		$el: ElementRef
	) {
		const el = $el.nativeElement as HTMLElement;
		if(!el.matches('input[type=text],input[type=password],textarea')) {
			throw new Error('vbRequired can only be used on input[type=text], input[type=password], or textarea');
		}
	}

	public ngOnChanges(): void {
		if (this.control) {
			setTimeout(() => {
				toggleError(this.control, 'required', this.isInvalid());
			});
		}
	}

	public validate(control: AbstractControl): ValidationErrors {
		if(!this.control && !(control as any).dummy) {
			this.control = control;
		}
		return this.isInvalid() ? { required : true } : null;
	}

	public isInvalid(): boolean {
		return this.vbRequired !== false && !this.control?.value?.trim();
	}
}
