<label [ngClass]="[styles.tglBtnLabel, disabled ? styles.tglDisabled : '']" [attr.for]="toggleId">
	<input type="checkbox" role="checkbox" [ngClass]="[styles.tglInput, styles.visuallyHidden]" [id]="toggleId" [(ngModel)]="value" [disabled]="disabled" [attr.aria-label]="((value ? 'TurnOff' : 'TurnOn') | translate) + ' | ' + ariaLabel" [attr.aria-checked]="value" (ngModelChange)="onChange()">
	<div [ngClass]="styles.tglBtn">
		<div [ngClass]="styles.tglBtnTrack">
			<div [ngClass]="styles.check" class="vb-icon vb-icon-round-check"></div>
		</div>
	</div>
	@if (labelName) {
		<span [ngClass]="labelCssClass">{{ labelName }}</span>
	}
</label>
