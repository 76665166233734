import { NgModule } from '@angular/core';

import { BrandingAngularModule } from './BrandingAngular.Module';
import { BrandingLogoComponent } from './BrandingLogo.Component';
import { ThemeStylesComponent } from './ThemeStyles.Component';


const components = [
	BrandingLogoComponent,
	ThemeStylesComponent
];

@NgModule({
	imports: [
		BrandingAngularModule
	],
	exports: components
})
export class BrandingRootModule { }
