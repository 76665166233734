<div layout="row" layout-wrap="false" role="tablist">
	<button *ngFor="let tab of tabs" role="tab" type="button" [attr.aria-controls]="tabPanelId" [attr.aria-selected]="tab === selectedTab" [attr.id]="tab.tabId" [ngClass]="[
			styles.tab,
			styles.tabFixedColor,
			themed ? styles.tabThemed : '',
			tab === selectedTab ?
				themed ? themedTabSelectedClass : tabSelectedClass
				: '',
			tab.isInvalid ? styles.tabInvalid : ''
		]" (click)="selectTab(tab)">
		<span *ngIf="tab.headerIconClass" [ngClass]="[styles.headerIcon, tab.headerIconClass]"></span>
		<span [ngClass]="tab.headerIconClass ? styles.hideHeaderLabelInSmallDevices : ''">
			{{ tab.heading }}
		</span>
	</button>
	<ng-container *ngTemplateOutlet="tabPanelInfoTemplate">
	</ng-container>
</div>

<div *ngIf="selectedTab" [attr.aria-labelledby]="selectedTab.tabId" [attr.id]="tabPanelId" [attr.flex]="verticalFill ? 'fill' : undefined" role="tabpanel" [ngClass]="[
		styles.tabPanel,
		themed ? 'theme-primary theme-primary-font-border-fade-50' : styles.tabPanelFixedColor
	]">
	<ng-content></ng-content>
</div>
