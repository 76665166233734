import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { distinctUntilChanged, map, skip, BehaviorSubject, Observable, Subscription, lastValueFrom, switchMap, of, ignoreElements } from 'rxjs';

import { IPresenter, StreamType } from 'rev-shared/webrtc/Contract';
import { LayoutDefs, LayoutHeight, LayoutType } from 'rev-shared/webrtc/Layouts';
import { ListenerProducerService } from 'rev-shared/webrtc/ListenerProducer.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { WebRtcListenerConnectionService } from 'rev-shared/webrtc/WebRtcListenerConnection.Service';
import { IProducerBgImage, WebcastModel } from 'rev-portal/scheduledEvents/webcast/model/WebcastModel';
import { getLayoutVideo } from 'rev-shared/webrtc/ListenerPresenters';
import { objectDefaults } from 'rev-shared/util';
import { stopStream } from 'rev-shared/webrtc/streamUtil';

import { IProducerOptions, IProducerLayout, IProducerStreamsCfg, IProducerLayoutVideo, IScene, layoutEquals, IExternalPresenter } from './Contract';
import { IVideoStreamProcessor } from './backstage/banner/VideoStreamProcessor';
import { insertLetterbox } from 'rev-shared/webrtc/LayoutUtil';
import { BannerRendererComponent } from './backstage/banner/BannerRenderer.Component';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { UploadService } from 'rev-shared/media/Upload.Service';
import { AudioCueService, AudioCueUrls } from './AudioCue.Service';

const MAXSCENES = 10;

@Injectable({
	providedIn: 'root'
})
export class ProducerService {
	private readonly producerStreamsSubject$ = new BehaviorSubject<IProducerStreamsCfg>({} as any);
	public readonly producerStreams$ = this.producerStreamsSubject$.asObservable();
	public get streams() {
		return this.producerStreamsSubject$.value;
	}

	private sub = new Subscription();

	private bannerRenderer: BannerRendererComponent;

	private webcast: WebcastModel;

	constructor(
		private readonly http: HttpClient,
		private readonly ListenerProducerService: ListenerProducerService,
		private readonly PushService: PushService,
		private readonly PushBus: PushBus,
		private readonly WebRtcListener: WebRtcListenerConnectionService,
		private readonly UploadService: UploadService,
		private readonly AudioCueService: AudioCueService
	) {}

	public init(webcast: WebcastModel): Promise<void> {
		this.webcast = webcast;

		return this.updateProducerCfg()
			.then(() => {
				this.sub.add(this.subscribePush().subscribe({
					error: e => console.error('ProducerService push error', e)
				}));
				this.subscribeProducerStreams();
			});
	}

	public start(): Promise<void> {
		const isMuted = !!this.producerStreamsSubject$.value.micMuted;
		return this.ListenerProducerService.init(this.webcast.id, this.webcast.webRtcListenerUrl, false, isMuted)
			.then(() => this.updateProducerStreams(this.producerStreamsSubject$.value))
			.then(() => {
				this.sub.add(this.producerStreams$.pipe(
					map(streams => !!streams.micMuted),
					distinctUntilChanged(),
					skip(1)
				).subscribe(micMuted => {
					this.WebRtcListener.toggleMutePresenter(micMuted)
						.catch(e => console.warn('toggleMutePresenter: ', e));
				}));
			});
	}

	public stop(): void {
		console.log('Stopping ProducerService');
		this.webcast = null;
		this.bannerRenderer = null;
		stopProducerStreams(this.producerStreamsSubject$.value);
		this.ListenerProducerService.stop(false);

		this.sub.unsubscribe();
		this.sub = new Subscription();
		this.producerStreamsSubject$.next({} as any);
	}

	public updateProducerStreams(producerStreams: IProducerStreamsCfg): void {
		this.producerStreamsSubject$.next(producerStreams);
		this.syncListenerProducerServiceStreams();
	}

	private subscribeProducerStreams(): void {
		this.sub.add(this.producerStreamsSubject$.pipe(
			switchMap<IProducerStreamsCfg, Observable<MediaStream>>(
				producerStreams => producerStreams?.cameraOutputStream$ ?? of(null))
		).subscribe({
			next: cameraStream => {
				if(cameraStream !== this.ListenerProducerService.cameraStream) {
					this.ListenerProducerService.setCameraStream(cameraStream, true)
						.catch(e => console.error('ProducerService, setCameraStreams', e));
				}
			},
			error: e => {
				console.error('producerStreams.cameraOutputStream$ error: ', e);
			}
		}));
	}

	private syncListenerProducerServiceStreams(): void {
		const producerStreams = this.producerStreamsSubject$.value;

		if(producerStreams.micStream !== this.ListenerProducerService.micStream) {
			this.ListenerProducerService.setMicStream(producerStreams.micStream)
				.catch(e => console.error('ProducerService, setMicStream', e));
		}

		if(producerStreams.displayCaptureStream !== this.ListenerProducerService.screenCaptureStream) {
			this.ListenerProducerService.setDisplayCaptureStream(
				producerStreams.displayCaptureStream,
				null,
				true
			)
				.catch(e => console.error('ProducerService, setDisplayCaptureStream', e));
		}
	}

	private addAudioToPreview(audio: IProducerLayoutVideo): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return this.savePreviewLayout({
			...layout,
			audioOnlyStreams: [...(layout.audioOnlyStreams || []), audio]
		});
	}

	public initLayout(video: IProducerLayoutVideo): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = removePresenterFromLayout(settings.backstageLayout, video.userId, video.type);
		return this.savePreviewLayout({
			...layout,
			name: LayoutType.FullFrame,
			videos: [video]
		});
	}

	public addPresenterToPreview(video: IProducerLayoutVideo, index?: number): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = removePresenterFromLayout(settings.backstageLayout, video.userId, video.type);
		if(index === undefined) {
			index = Math.max(0, layout.videos.findIndex(v => !v.userId));
		}

		return this.savePreviewLayout({
			...layout,
			videos: layout.videos.map((v, i) => i !== index ? v : video)
		});
	}

	public addPresenterBg(video: IProducerLayoutVideo): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = removePresenterFromLayout(settings.backstageLayout, video.userId, video.type);
		return this.savePreviewLayout({
			...layout,
			backgroundVideo: video
		});
	}

	public swapPreviewSlots(a: number, b: number) {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		const videos = layout.videos;

		return this.savePreviewLayout({
			...layout,
			videos: videos.map((v, i) => {
				if(i !== a && i !== b) {
					return v;
				}
				return {
					...videos[i === a ? b : a],
					rect: v.rect
				};
			})
		});
	}

	public removePresenterFromPreview(presenterId: string, type?: StreamType): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return this.savePreviewLayout(removePresenterFromLayout(layout, presenterId, type));
	}

	public togglePresenterPreview(presenter: IPresenter, type: StreamType, isBackground: boolean, setActive: boolean): Promise<void> {
		if(!setActive) {
			return this.removePresenterFromPreview(presenter.id, type);
		}
		const video = getLayoutVideo(presenter, type);

		if(type === StreamType.Audio) {
			return this.addAudioToPreview(video);
		}

		if(isBackground) {
			return this.addPresenterBg(video);
		}

		if(!this.webcast.producerOptions.backstageLayout.videos.length) {
			return this.initLayout(video);
		}

		return this.addPresenterToPreview(video);
	}

	public clearPreviewLayoutSlot(index: number): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return this.savePreviewLayout({
			...layout,
			videos: layout.videos.map((v, i) => i !== index ? v : {} as any)
		});
	}

	public sendPreviewLive(getScreenShot: () => Promise<HTMLCanvasElement>): Promise<any> {
		const producerOptions = this.webcast.producerOptions;
		const oldScenes = producerOptions.scenes;
		const index = oldScenes.findIndex(s => layoutEquals(s.layout, producerOptions.backstageLayout));
		const scenes$ = index >= 0
			? Promise.resolve([oldScenes[index], ...oldScenes.filter((_, i) => i !== index)])
			: getScreenShot()
				.then(screen => this.saveSceneImage(screen))
				.catch(e => {
					console.error('Error saving scene img: ', e);
					return {};
				})
				.then(data => ([{
					imageId: data.imageId,
					imageUrl: data.imageUrl,
					layout: producerOptions.backstageLayout
				},
				...producerOptions.scenes]));

		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;
		return Promise.all([
			scenes$.then(scenes =>
				this.saveProducerSettings({
					...settings,
					backstageLayout: settings.onStageLayout.name ? settings.onStageLayout: layout,
					onStageLayout: layout,
					scenes: scenes.slice(0, MAXSCENES)
				})),
			this.WebRtcListener.sendLive(this.getListenerLayout(layout))
		]);
	}

	public updateLiveRecording(): void {
		const producer = this.ListenerProducerService.getCaptureProducer();
		const settings = this.webcast.producerOptions;
		const presenters = this.WebRtcListener.presenters?.getLayoutPresenters(settings.onStageLayout);
		if (presenters) {
			const i = settings.onStageLayout.videos.findIndex(v => v.videoProducerId === producer.id);
			if (i < 0) {
				return;
			}

			const video = settings.onStageLayout.videos[i];
			const presenter = presenters.find(p => p.id === video.userId);
			this.WebRtcListener.updateLiveLayout(this.getListenerLayoutVideo(video, presenter, i))
				.catch(e => console.error('updateLiveRecording error: ', e));
		}
	}

	public setBackstageLayout(layoutType: LayoutType): Promise<any> {
		const settings = this.webcast.producerOptions;
		const layout = settings.backstageLayout;

		return this.savePreviewLayout({
			...layout,
			name: layoutType,
			audioOnlyStreams: layout.audioOnlyStreams,
			videos: layout.videos.slice(0, LayoutDefs[layoutType]?.length)
		});
	}

	public resendExternalPresenterEmail(webcastId: string, externalPresenter: IExternalPresenter): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:ResendExternalPresenterEmail', { webcastId, externalPresenter });
	}

	private savePreviewLayout(layout: IProducerLayout): Promise<any> {
		const settings = this.webcast.producerOptions;

		return Promise.all([
			this.WebRtcListener.isConnected() && this.WebRtcListener.updatePreviewStreams(this.getListenerLayout(readLayout(layout))),
			this.saveProducerSettings({
				...settings,
				backstageLayout: layout
			})
		]);
	}

	public saveProducerSettings(producerOptions: IProducerOptions): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:SaveProducerOptions', {
			webcastId: this.webcast.id,
			producerOptions: getProducerOptionsCmd(producerOptions)
		});
	}

	public setDefaultBackground(backgroundId: string): Promise<any> {
		return this.setBackground(backgroundId, getDefaultBgImgUrl(backgroundId));
	}

	public setCustomBackground(img: IProducerBgImage): Promise<any> {
		if(!img.original) {
			return Promise.reject('Img missing url');
		}

		return this.setBackground(img.id, img.original, true);
	}

	private setBackground(backgroundId: string, url: string, isCustomBg?: boolean): Promise<any> {
		const settings = this.webcast.producerOptions;

		return Promise.all([
			this.WebRtcListener.uploadBackgroundImage(backgroundId, url),
			this.saveProducerSettings({
				...settings,
				backstageLayout: {
					...settings.backstageLayout,
					isCustomBg,
					backgroundId,
					backgroundVideo: null
				}
			})
		]);
	}

	public clearBackground(): Promise<void> {
		const settings = this.webcast.producerOptions;
		return this.saveProducerSettings({
			...settings,
			backstageLayout: {
				...settings.backstageLayout,
				backgroundId: null,
				backgroundVideo: null,
				isCustomBg: null
			}
		});
	}

	public saveSceneImage(canvas: HTMLCanvasElement): Promise<any> {
		return new Promise<Blob>((resolve, reject) => canvas.toBlob(blob => blob ? resolve(blob) : reject('unable to capture scene'), 'image/jpg'))
			.then(img => this.PushService.dispatchCommand('scheduledEvents:AddImageToWebcast', {
				webcastId: this.webcast.id,
				context: 'Webcast'
			}, 'ImageCreated')
				.then(data => {
					const { imageId, uploadUri } = data.message;
					const result = this.PushBus.awaitMessage({
						route: imageId,
						events: 'ImageStoringFinished',
						rejectEvents: 'ImageStoringFailed'
					});
					const formData = new FormData();
					formData.append('image', img, 'blob.jpg');
					return result.subscribed
						.then(() => lastValueFrom(this.http.post(uploadUri, formData)))
						.then(() => result)
						.then(data => {
							return { imageId, imageUrl: data.message.thumbnailUri };
						});
				}));
	}

	private subscribePush(): Observable<any> {
		return this.PushBus.getObservable(this.webcast.id, 'Webcast.Presenter', {
			ProducerOptionsSaved: ({ producerOptions }) => this.webcast.update({
				producerOptions: readProducerOptions(producerOptions)
			})
		});
	}

	private updateProducerCfg(): Promise<void> {
		return lastValueFrom(this.http.get(`/scheduled-events/${this.webcast.id}/producer-cfg`))
			.then(({ producerOptions, producerBgImages }: any) => {
				this.webcast.update({
					producerOptions: readProducerOptions(producerOptions),
					producerBgImages
				});
			});
	}

	public setBannerRenderer(bannerRenderer: BannerRendererComponent): void {
		this.bannerRenderer = bannerRenderer;
	}

	public getStreamProcessor(stream: MediaStream): IVideoStreamProcessor {
		return this.bannerRenderer.getStreamProcessor(stream);
	}

	private getListenerLayout(layout: IProducerLayout): any {
		const presenters = this.WebRtcListener.presenters.getLayoutPresenters(layout);
		const videos = layout.videos;
		const getVideo = (video, z) =>
			this.getListenerLayoutVideo(video, presenters.find(p => p.id === video.userId), z);
		const bgId = layout.backgroundId;
		const hasBg = layout.isCustomBg
			? this.webcast.producerBgImages.some(img => img.id === bgId)
			: !this.webcast.producerOptions.deletedDefaultBgs.includes(bgId as any);

		return {
			backgroundName: hasBg ? bgId : null,
			backgroundVideo: layout.backgroundVideo && getVideo(layout.backgroundVideo, 0),

			layout: {
				name: layout.name,
				slots: videos
					.filter(v => v.videoProducerId)
					.map((video, i) => getVideo(video, i + 1)),

				audioProducers: videos
					.concat(
						layout.audioOnlyStreams || [],
						layout.backgroundVideo || [])
					.flatMap(stream => stream.audioProducerIds?.map(producerId => ({
						userId: stream.userId,
						producerId
					})) || [])
			}
		};
	}

	private getListenerLayoutVideo(video: IProducerLayoutVideo, presenter: IPresenter, i: number): any {
		const ratio = 720 / LayoutHeight;
		const scale = p => Math.round(ratio * p);
		let rect = video.rect;

		if (video.type === StreamType.Content) {
			const stream = this.webcast.currentUser.id === presenter.id ?
				this.producerStreamsSubject$.value.displayCaptureStream :
				presenter.captureStream;

			const videoSettings = stream?.getVideoTracks()[0]?.getSettings();
			if(videoSettings) {
				rect = insertLetterbox(rect, videoSettings.width / (videoSettings.height || 1));
			}
		}

		return {
			x: scale(rect.x),
			y: scale(rect.y),
			w: scale(rect.w),
			h: scale(rect.h),
			z: i,
			slotNumber: i,
			mode: 'pad',
			producerId: video.videoProducerId,
			userId: video.userId
		};
	}

	public addBackgroundImage(webcastId: string, file: FileWrapper): Promise<any> {
		return this.UploadService.uploadImage('Webcast', file)
			.then(data => {
				const result = data.message;
				return this.PushService.dispatchCommand('scheduledEvents:SaveProducerBgImage', {
					webcastId,
					imageId: result.imageId
				})
					.then(() => result);
			});
	}

	public removeBackgroundImage(webcastId: string, imageId: string): Promise<any> {
		return this.PushService.dispatchCommand('scheduledEvents:RemoveProducerBgImage', {
			webcastId,
			imageId
		});
	}

	public startPushToTalk(presenterId?: string): Promise<() => void> {
		return this.WebRtcListener.startPushToTalk(presenterId)
			.then(() => {
				this.AudioCueService.play(AudioCueUrls.startBeep);
				this.producerStreamsSubject$.next({
					...this.producerStreamsSubject$.value,
					pushToTalk: { presenterId }
				});
				return () => {
					this.WebRtcListener.stopPushToTalk(presenterId)
						.catch(e => console.error('Stop push to talk: ', e))
						.finally(() => this.producerStreamsSubject$.next({
							...this.producerStreamsSubject$.value,
							pushToTalk: null
						}));
				};
			});
	}

	public muteScreenshare(isMute: boolean): void {
		const track = this.streams.displayCaptureStream?.getAudioTracks()[0];
		if(track) {
			track.enabled = !isMute;
		}
	}
}

export function stopProducerStreams(streams: IProducerStreamsCfg): void {
	stopStream(streams.cameraStream);
	stopStream(streams.micStream);
	stopStream(streams.displayCaptureStream);
}

export function readProducerOptions(options?: IProducerOptions): IProducerOptions {
	options = options || {} as any;

	return {
		...options,
		backstageLayout: readLayout(options.backstageLayout || {} as any),
		onStageLayout: readLayout(options.onStageLayout || {} as any),
		banner: objectDefaults(options.banner, {
			timing: '',
			theme: ''
		}),
		scenes: (options.scenes || []).map(readScenes),
		deletedDefaultBgs: options.deletedDefaultBgs || []
	};
}

function readScenes(scene: IScene): IScene {
	return {
		...scene,
		layout: readLayout(scene.layout)
	};
}

function readLayout(layout: IProducerLayout): IProducerLayout {
	const rects = LayoutDefs[layout?.name] || [];
	return {
		...layout,
		audioOnlyStreams: layout?.audioOnlyStreams || [],
		videos: rects.map((rect, i) => {
			return {
				...layout.videos[i],
				rect
			};
		}),

		backgroundVideo: layout.backgroundVideo ? {
			...layout.backgroundVideo,
			rect: LayoutDefs.FullFrame[0]
		} : null
	};
}

function getProducerOptionsCmd(producerOptions: IProducerOptions): IProducerOptions {
	return {
		backstageLayout: getLayoutCmd(producerOptions.backstageLayout),
		onStageLayout: getLayoutCmd(producerOptions.onStageLayout),
		banner: producerOptions.banner,
		scenes: producerOptions.scenes.map(s => ({
			...s,
			layout: getLayoutCmd(s.layout)
		})),
		deletedDefaultBgs: producerOptions.deletedDefaultBgs
	};
}

function getLayoutCmd(layout: IProducerLayout): IProducerLayout {

	const getVideo = (v: IProducerLayoutVideo) => ({
		videoProducerId: v.videoProducerId,
		audioProducerIds: v.audioProducerIds,
		type: v.type,
		userId: v.userId
	});

	return {
		name: layout.name,
		videos: layout.videos?.map(getVideo),
		audioOnlyStreams: layout.audioOnlyStreams?.map(a => ({
			audioProducerIds: a.audioProducerIds,
			type: a.type,
			userId: a.userId
		})),
		isCustomBg: layout.isCustomBg || false,
		backgroundId: layout.backgroundId,
		backgroundVideo: layout.backgroundVideo &&
			getVideo(layout.backgroundVideo)
	};
}

export function getDefaultBgImgUrl(backgroundId: string): string {
	return backgroundId && `/img/producerBackgrounds/${backgroundId}.png`;
}

function removePresenterFromLayout(layout: IProducerLayout, presenterId: string, type?: StreamType): IProducerLayout {
	// when removing audio user, clear all slots
	const checkType = type && type !== StreamType.Audio;

	const filterVideo = v => v.userId !== presenterId ||
		checkType && v.type !== type;

	return {
		...layout,
		videos: layout.videos.map(v => filterVideo(v) ? v : {} as any),
		audioOnlyStreams: layout.audioOnlyStreams?.filter(filterVideo),
		backgroundVideo: layout.backgroundVideo && filterVideo(layout.backgroundVideo) ?
			layout.backgroundVideo :
			null
	};
}
