import { Component, Input, ViewEncapsulation } from '@angular/core';

import type {
	CellClassParams,
	ColDef,
	IRowNode,
	Module,
	RowDragEndEvent,
	RowDragMoveEvent
} from '@ag-grid-community/core';

import { COMPONENT_HOST, COMPONENT_PROVIDERS, VbUiDataGridComponent } from '../VbUiDataGrid.Component';

import styles from './VbUiTreeGrid.Component.module.less';

interface ICellClassRules {
	[className: string]: string | ((cellClassParams: CellClassParams) => boolean);
}

const DRAG_ICON_ADD_AS_CHILD: string = 'ag-icon-plus';
const DRAG_ICON_INSERT_AS_SIBLING: string = 'ag-icon-group';

@Component({
	selector: 'vb-ui-tree-grid',
	template: '',
	host: COMPONENT_HOST,
	providers: COMPONENT_PROVIDERS,
	encapsulation: ViewEncapsulation.None
})
export class VbUiTreeGridComponent extends VbUiDataGridComponent {
	@Input() public pathField: string;

	private cellHoverClassRules: ICellClassRules;
	private dragCurrentDropNode: IRowNode;
	private dragGhostIconElement: HTMLElement;
	private isDragSiblingOver: boolean;

	public ngOnInit(): void {
		super.ngOnInit();

		this.animateRows = true;
		this.excludeChildrenWhenTreeDataFiltering = true;
		this.getDataPath = data => this._getDataPath(data);
		this.treeData = true;

		this.subscriptions.push(
			this.rowDragEnd.subscribe(event => this.onTreeRowDragEnd(event)),
			this.rowDragMove.subscribe(event => this.onTreeRowDragMove(event)),
			this.rowDragLeave.subscribe(() => this.onTreeRowDragLeave()),
			this.rowDragEnter.subscribe(() => this.onTreeRowDragEnter())
		);
	}

	public ngAfterContentInit(): void {
		super.ngAfterContentInit();

		this.cellHoverClassRules = this.getCellHoverClassRules();
		this.applyColumnConfigurationOverrides();
	}

	protected getAdditionalModules(): Promise<Module[]> {
		return Promise.all([
			super.getAdditionalModules(),
			this.getRowGroupingModule()
		])
			.then(([superModules, rowGroupingModule]) => [
				...superModules,
				rowGroupingModule
			]);
	}

	private applyColumnConfigurationOverrides(): void {
		this.applyCellClassRules(this.autoGroupColumnDef);

		(this.columnDefs as ColDef[])?.forEach(col => this.applyCellClassRules(col));
	}

	private applyCellClassRules(column: ColDef): void {
		column.cellClassRules = {
			...column.cellClassRules,
			...this.cellHoverClassRules
		};
	}

	private getCellHoverClassRules(): ICellClassRules {
		return {
			[styles.hoverOver]: params => params.node === this.dragCurrentDropNode && !this.isDragSiblingOver,
			[styles.hoverOverParentAdd]: params => params.node === this.dragCurrentDropNode && this.isDragSiblingOver
		};
	}

	private getRowGroupingModule(): Promise<Module> {
		return import(/* webpackChunkName: "RowGroupingModule" */ './RowGroupingModule')
			.then(moduleExports => moduleExports.RowGroupingModule);
	}

	private arePathsEqual(path1: string[], path2: string[]): boolean {
		if (path1.length !== path2.length) {
			return false;
		}

		return path1.reduce<boolean>(
			(output, path1CurrentPiece, currentIndex) => output && path1CurrentPiece === path2[currentIndex],
			true
		);
	}

	private _getDataPath(data: any): string[] {
		return data[this.pathField];
	}

	private isPotentialParentAChildOfNode(potentialParentPath: string[], movingNodePath: string[]): boolean {
		return potentialParentPath.includes(movingNodePath[movingNodePath.length - 1]);
	}

	private moveToPath(newParentPath: string[], node: IRowNode, allUpdatedNodes: any[]): void {
		const oldPath: string[] = this._getDataPath(node.data);
		const newChildPath: string[] = [
			...newParentPath,
			oldPath[oldPath.length - 1]
		];

		node.data[this.pathField] = newChildPath;

		allUpdatedNodes.push(node.data);

		if (node.childrenAfterGroup) {
			node.childrenAfterGroup.forEach(childNode => this.moveToPath(newChildPath, childNode, allUpdatedNodes));
		}
	}

	private onTreeRowDragEnd(event: RowDragEndEvent): void {
		const movingNodePath: string[] = this._getDataPath(event.node.data);

		const newParentNode: IRowNode = this.isDragSiblingOver ?
			event.overNode.parent :
			event.overNode;

		const newParentPath: string[] = newParentNode.data ?
			this._getDataPath(newParentNode.data) :
			[]; // no data means it's the root node

		const needToChangeParent: boolean = !this.arePathsEqual(newParentPath, movingNodePath);
		const isInvalidMode: boolean = this.isPotentialParentAChildOfNode(newParentPath, movingNodePath);

		if (!isInvalidMode && needToChangeParent) {
			const updatedRows: any[] = [];

			this.moveToPath(newParentPath, event.node, updatedRows);

			this.api.applyTransaction({
				update: updatedRows
			});
			this.api.clearFocusedCell();
		}

		this.setPotentialParentForNode();
	}

	private onTreeRowDragEnter(): void {
		this.dragGhostIconElement = Array.from(document.body.children)
			.find(child => child.matches('.ag-dnd-ghost'))
			.querySelector('.ag-icon');
	}

	private onTreeRowDragLeave(): void {
		this.dragGhostIconElement = null;
		this.setPotentialParentForNode();
	}

	private onTreeRowDragMove(event: RowDragMoveEvent): void {
		this.setPotentialParentForNode(event);
	}

	private setPotentialParentForNode(event?: RowDragMoveEvent): void {
		const dragNewDropNode: IRowNode = event ?
			event.overNode :
			null;

		this.isDragSiblingOver = dragNewDropNode ?
			event.y - dragNewDropNode.rowTop < 10 :
			false;

		const rowsToRefresh: IRowNode[] = [
			...new Set([
				this.dragCurrentDropNode,
				dragNewDropNode
			].filter(Boolean))
		];

		this.dragCurrentDropNode = dragNewDropNode;

		this.api.refreshCells({
			force: true,
			rowNodes: rowsToRefresh
		});

		this.updateDragGhostIcon(event && dragNewDropNode === event.node);
	}

	private updateDragGhostIcon(isOverSelf: boolean = false): void {
		if (!this.dragGhostIconElement) {
			return;
		}

		const { classList } = this.dragGhostIconElement;
		const isDropNode: boolean = !!this.dragCurrentDropNode;

		classList.toggle(DRAG_ICON_ADD_AS_CHILD, isDropNode && !this.isDragSiblingOver && !isOverSelf);
		classList.toggle(DRAG_ICON_INSERT_AS_SIBLING, isDropNode && this.isDragSiblingOver);
	}
}
