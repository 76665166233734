import { Observable } from 'rxjs';
import { StreamType, Rect } from 'rev-shared/webrtc/Contract';
import { LayoutType } from 'rev-shared/webrtc/Layouts';
import { IVideoStreamProcessor } from './backstage/banner/VideoStreamProcessor';

export interface IProducerStreamsCfg {
	cameraDevices: MediaDeviceInfo[];
	cameraStream: MediaStream;
	cameraOutputStream$: Observable<MediaStream>;
	cameraStreamProcessor: IVideoStreamProcessor;
	cameraDevice: MediaDeviceInfo;
	cameraLabel: string;
	cameraStarted: boolean;

	micDevices: MediaDeviceInfo[];
	micStream: MediaStream;
	micMuted: boolean;
	micDevice: MediaDeviceInfo;
	micLabel: string;

	displayCaptureStream: MediaStream;
	//Needed for resize monitor
	displayCaptureVideo: HTMLVideoElement;

	pushToTalk?: { presenterId: string };
	mediaDevicesLoaded: boolean;
}

export interface IProducerOptions {
	onStageLayout: IProducerLayout;
	backstageLayout: IProducerLayout;
	banner: IProducerBannerOptions;
	scenes: IScene[];
	deletedDefaultBgs: DefaultProducerBackground[]
}

export interface IProducerLayout {
	name: LayoutType;
	videos?: IProducerLayoutVideo[];
	audioOnlyStreams?: IProducerLayoutVideo[];
	backgroundVideo?: IProducerLayoutVideo;
	backgroundId: string;
	isCustomBg: boolean;
}


export function layoutEquals(a: IProducerLayout, b: IProducerLayout): boolean {
	return a.name === b.name &&
		a.backgroundId === b.backgroundId &&
		layoutVideoEquals(a.backgroundVideo, b.backgroundVideo) &&
		a.videos.length === b.videos.length &&
		a.audioOnlyStreams.length === b.audioOnlyStreams.length &&
		a.videos.every((v, i) => layoutVideoEquals(v, b.videos[i])) &&
		a.audioOnlyStreams.every((v, i) => layoutVideoEquals(v, b.audioOnlyStreams[i]));
}

export interface IProducerBannerOptions {
	accentColor: string;
	theme: BannerTheme;
	timing: BannerTiming;
}

export interface IProducerLayoutVideo {
	type: StreamType;
	userId: string;
	audioProducerIds: string[];
	videoProducerId?: string;

	rect?: Rect;
}

export interface IExternalPresenter {
	name: string;
	title: string;
	email: string;
}

export function layoutVideoEquals(a: IProducerLayoutVideo, b: IProducerLayoutVideo): boolean {
	if(!a || !b) {
		return a == b;
	}

	return a.type === b.type &&
		a.userId === b.userId &&
		a.videoProducerId === b.videoProducerId &&
		a.audioProducerIds?.length === b.audioProducerIds?.length &&
		(a.audioProducerIds || []).every((p, i) => p === b.audioProducerIds[i]);
}

export interface IScene {
	imageId: string;
	imageUrl: string;
	layout: IProducerLayout;
}

export enum DefaultProducerBackground {
	Bg1 = 'bg_1',
	Bg2 = 'bg_2',
	Bg3 = 'bg_3',
	Bg4 = 'bg_4',
	Bg5 = 'bg_5'
}

export enum BannerTheme {
	Default = '',
	Bubble = 'Bubble'
}

export enum BannerTiming {
	Default = '',
	Always = 'Always',
	Auto10s = 'Auto10s'
}
