import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ISearchResult } from 'rev-portal/search/InsightSearchHelper.Service';

import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { isString, noop } from 'rev-shared/util';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { SearchConstants } from './SearchConstants';
import { SearchQueryBuilder } from './SearchQueryBuilder';
import { SearchUtil } from './SearchUtil.Service';

export interface ISearchHits {
	totalHits: number;
	scrollId: string;
}

export interface IVideoSearchHits extends ISearchHits {
	videos: any[];
}

export interface IAccessEntitySearchHits extends ISearchHits {
	accessEntities: IAccessEntity[];
}

export interface IAccessEntity extends IAccessEntityIdentifier {
	name: string;
	sourceType: string;

	// User Specific Fields
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	groupIds: string[];
	profileImageUri?: string [];
	userStatus: string;
	itemStatus: string;
	isRootUser: string;
	sourceId: string;
	sourceRemoved: string;
	groupCount: number;
	teamIds: string[];
	// Group Specific Fields
	userCount: number;
}

export interface IAccessEntityIdentifier {
	id: string;
	type: AccessEntityType;
}

@Injectable({
	providedIn: 'root'
})
export class SearchService {
	public itemTypes = SearchConstants.itemTypes;
	public initialPageSize = SearchConstants.initialPageSize;
	public pageSize = SearchConstants.pageSize;

	private zoneRun = v => {
		setTimeout(() => this.zone.run(noop));
		return v;
	};

	constructor(
		private SecurityContext: SecurityContextService,
		private SearchUtil: SearchUtil,
		private http: HttpClient,
		private zone: NgZone
	) {
	}

	public queryAccessEntities(searchParams: any): Promise<IAccessEntitySearchHits> {
		return this.searchAccessEntities(SearchConstants.itemTypes.accessEntities, searchParams)
			.then(result => ({
				totalHits: result.totalHits,
				accessEntities: result.hits,
				scrollId: result.scrollId
			}));
	}


	public queryAttendees(searchParams: any): Promise<ISearchHits & { attendees: any[] }>{

		searchParams.query = new SearchQueryBuilder()
			.anyMatch(searchParams.query)
			.value('WebcastId', searchParams.webcastId)
			.buildQuery();

		return this.search(SearchConstants.itemTypes.attendee, searchParams)
			.then(result => ({
				totalHits: result.totalHits,
				attendees: result.hits,
				scrollId: result.scrollId
			}));
	}

	public getVideos(searchParams: any): Promise<IVideoSearchHits>{

		searchParams.query = new SearchQueryBuilder()
			.value('Approval.status', searchParams.pendingApproval ? ApprovalStatus.PENDING_APPROVAL : null)
			.values('Approval.stepId', searchParams.stepIds)
			.value('CategoryPathIds', searchParams.categoryPathIds)
			.value('CategoryIds', searchParams.category)
			.value('IsActive', searchParams.isActive)
			.value('IsLive', searchParams.isLive)
			.value('Is360', searchParams.is360)
			.value('Status', searchParams.ready ? 'Ready' : null)
			.value('mySubscriptions', searchParams.mySubscriptions)
			.noValues('CategoryIds', searchParams.uncategorized)
			.parsedQuery(searchParams.parsedQuery)
			.query(searchParams.query)
			.buildQuery();

		searchParams.filter = new SearchQueryBuilder()
			.parsedQuery(searchParams.parsedFilter)
			.value('TeamIds', searchParams.teamId)
			.buildQuery();

		return this.getVideoSearchResult(searchParams);
	}

	public getVideosForChannelSettings(searchParams: any): Promise<IVideoSearchHits> {
		searchParams.query = new SearchQueryBuilder()
			.query(searchParams.query)
			.buildQuery();

		searchParams.filter = new SearchQueryBuilder()
			.values('IsActive', searchParams.isActive)
			.buildQuery();

		return this.getVideoSearchResult(searchParams);
	}

	public getFilteredVideos(searchParams: any): Promise<IVideoSearchHits> {
		searchParams.query = new SearchQueryBuilder()
			.value('CategoryIds', searchParams.categoryId)
			.value('LegalHold', searchParams.legalHold)
			.parsedQuery(searchParams.parsedQuery)
			.buildQuery();
		return this.getVideoSearchResult(searchParams);
	}

	public getVideosForApprovalQueue(searchParams: any): Promise<IVideoSearchHits> {
		//NOTE: currently backend is not capable of handling
		//filter data and search data together in query paramter
		//That's why sending query and filter data in different
		//properties.
		searchParams.query = new SearchQueryBuilder()
			.query(searchParams.query)
			.buildQuery();

		searchParams.filter = new SearchQueryBuilder()
			.values('Approval.status', searchParams.statusList)
			.buildQuery();

		return this.getVideoSearchResult(searchParams);
	}

	//searches by title, videoId, or videoUrl
	public getVideoSuggestions(params: any): Promise<IVideoSearchHits> {
		return this.searchVideos(`/search/accounts/${params.accountId}/videos/suggest-video-search`, params);
	}

	public getVideosForSelection(params: any): Promise<IVideoSearchHits> {
		return this.searchVideos(`/search/accounts/${params.accountId}/videos/select-video`, params);
	}

	private searchVideos(url: string, params: any): Promise<IVideoSearchHits> {
		return lastValueFrom<any>(this.http.get(url, {
			params: {
				accountId: params.accountId,
				q: params.query,
				count: params.count,
				scrollId: params.scrollId,
				fl: params.fl,
				includeLiveVideos: params.includeLiveVideos,
				noScroll: params.noScroll || undefined
			}
		}))
			.then(result => ({
				totalHits: result.totalHits,
				videos: this.SearchUtil.readSearchResult(result.hits),
				scrollId: result.scrollId
			}))
			.then(this.zoneRun);
	}

	public getMfStbList(searchParams: any): Promise<ISearchHits & { MfStbs: any[] }> {
		searchParams.query = new SearchQueryBuilder()
			.query(searchParams.query)
			.buildQuery();

		searchParams.filter = new SearchQueryBuilder()
			.value('IsPending', searchParams.isPendingStb)
			.value('DeviceType', 'MfStb' )
			.buildQuery();

		return this.search(SearchConstants.itemTypes.stb, searchParams)
			.then(result => {
				return {
					totalHits: result.totalHits,
					MfStbs: result.hits,
					scrollId: result.scrollId
				};
			});
	}

	public getDeviceLogs(searchParams: any): Promise<ISearchHits & { deviceLogs: any[] }> {
		searchParams.query = new SearchQueryBuilder()
			.query(searchParams.query)
			.buildQuery();

		searchParams.filter = new SearchQueryBuilder()
			.value('DeviceId', searchParams.deviceId)
			.buildQuery();


		return this.search(SearchConstants.itemTypes.deviceLogs, searchParams)
			.then(result => {
				return {
					totalHits: result.totalHits,
					deviceLogs: result.hits,
					scrollId: result.scrollId,
				};
			});
	}

	public getCount(accountId: string, itemType: string, q: string): Promise<number>{
		return lastValueFrom<any>(this.http.get(`/search/accounts/${accountId}/${itemType}/count`, { params: { q } }))
			.then(result => result.count)
			.then(this.zoneRun);
	}

	public expirationsVideoCount(accountId: string): Promise<number> {
		return Promise.resolve(this.SecurityContext.$promise)
			.then(() => {
				if (!this.SecurityContext.checkAuthorization('media.add')) {
					return 0;
				}

				const [todayIsoString] = new Date().toISOString().split('T');
				const query = new SearchQueryBuilder()
					.range('expiryDate', todayIsoString, SearchConstants.maxDate)
					.buildQuery();

				return this.getCount(accountId, SearchConstants.itemTypes.videos, query);
			});
	}

	public userHasEditableVideos(accountId: string, teamId: string): Promise<boolean> {
		return this.getVideos({
			accountId,
			teamId,
			count: 0,
			noScroll: true,
			useEditAcl: true
		})
			.then(result => !!result.totalHits);
	}

	public userHasEditAccessToVideo(accountId: string, videoId: string): Promise<boolean> {
		const filterString = new SearchQueryBuilder()
			.value('id', videoId)
			.value('HasHls', 'true')
			.value('Is360', 'false')
			.value('HasAudioOnly', 'false')
			.value('LegalHold', 'false')
			.value('Approval.status', 'Approved')
			.buildQuery();
		return this.getVideos({
			accountId: accountId,
			useEditAcl: true,
			parsedFilter: filterString
		}).then(searchResults => {
			return searchResults.totalHits === 1;
		});
	}

	public closeScrollId(accountId: string, scrollId: string): Promise<void> {
		if(scrollId) {
			return lastValueFrom<any>(this.http.delete(`/search/accounts/${accountId}/scroll/${scrollId}`))
				.then(this.zoneRun);
		}
	}

	private search(itemType: string, searchParams: any): Promise<any> {
		const baseUrl = `/search/accounts/${searchParams.accountId}/${itemType}`;
		const url = searchParams.downloadSearch ? baseUrl + '/csv' : baseUrl;

		return lastValueFrom(this.http.get(url, {
			params: {
				useEditAcl: searchParams.useEditAcl,
				q: searchParams.query,
				sortField: (!searchParams.sortField || searchParams.sortField.toLowerCase() === SearchConstants.defaultSortField) ? '' : searchParams.sortField,
				sortDirection: searchParams.sortAscending ? SearchConstants.sortAscending : SearchConstants.sortDescending,
				count: searchParams.count,
				subtitles: searchParams.subtitles,
				scrollId: searchParams.scrollId,
				fl: searchParams.fl,
				filter: searchParams.filter,
				noScroll: searchParams.noScroll || undefined
			}
		}))
			.then(this.formatResultHitsOutput)
			.then(this.zoneRun);
	}

	private searchAccessEntities(itemType: string, searchParams: any): Promise<any> {
		return lastValueFrom(this.http.get(`/search/accounts/${searchParams.accountId}/accessEntity`, {
			params: {
				itemType,
				q: searchParams.query,
				sortField: (!searchParams.sortField || searchParams.sortField.toLowerCase() === SearchConstants.defaultSortField) ? '' : searchParams.sortField,
				sortDirection: searchParams.sortAscending ? SearchConstants.sortAscending : SearchConstants.sortDescending,
				count: searchParams.count,
				scrollId: searchParams.scrollId,
				fl: searchParams.fl,
				accessEntityTypes: searchParams.type,
				sourceType: searchParams.sourceType,
				ids: searchParams.ids,
				groupIds: searchParams.groupIds,
				teamIds: searchParams.teamIds,
				perm: (searchParams.accountPermissions || []).join(',') || undefined,
				noScroll: searchParams.noScroll || undefined,
				showAllChannels: searchParams.showAllChannels || undefined
			}
		}))
			.then(this.formatResultHitsOutput)
			.then(this.zoneRun);
	}

	private getVideoSearchResult(searchParams: any): Promise<IVideoSearchHits>{
		return this.search(SearchConstants.itemTypes.videos, searchParams)
			.then(result => {
				if (!result) {
					return;
				}
				//TODO - UI should get approval object literal
				//but now getting as json string..should be fixed in backend.
				const videos = (result.hits || []).map(hit => {
					if(isString(hit.approval)) {
						hit.approval = JSON.parse(hit.approval);
					}

					if(searchParams.thumbnailSheets) {
						hit.thumbnailSheets = hit.thumbnailSheets && JSON.parse(hit.thumbnailSheets);
					}
					return hit;
				});

				return {
					totalHits: result.totalHits,
					videos,
					scrollId: result.scrollId
				};
			});
	}

	private formatResultHitsOutput = (result: any) => {
		if (!result) {
			return;
		}
		return {
			totalHits: result.totalHits,
			hits: this.SearchUtil.readSearchResult(result.hits),
			scrollId: result.scrollId
		};
	};

	public searchFnToLoader(searchFn: (query: string, opts: any, scrollId: string) => Promise<ISearchResult>): (query: string, opts: any) => () => Promise<ISearchResult> {
		return (query: string, opts: any) => {
			let scrollId = null;
			return () => searchFn(query, opts, scrollId)
				.then((result: ISearchResult) => {
					scrollId = result.scrollId;
					return result;
				});
		};
	}
}
