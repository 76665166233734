import { VideoStatus } from './VideoStatus';

export const VIDEO_PLAYBACK_ROUTE = 'Media.VideoPlayback';

export const LIVE_EVENT_STATUSES: VideoStatus[] = [
	VideoStatus.RECORDING,
	VideoStatus.STREAMING_RECORDING,
	VideoStatus.NOT_UPLOADED,
	VideoStatus.STREAMING_WAITING,
	VideoStatus.RECORDING_CONNECTING
];
