<vb-title [title]="'Title_Videos' | translate"></vb-title>

<div class="media-layout-wrapper" layout="column" layout-wrap="false" [ngStyle]="{ height: height }">
	<vb-media-toolbar (vbOffsetHeightModel)="onToolbarHeightChange($event.offsetHeight)" [approvalProcessTemplateCount]="approverProcessTemplates?.length" [pendingVideoCount]="pendingVideoCount" [team]="teamBranding">
	</vb-media-toolbar>
	<div class="media-list-results" layout="column" layout-wrap="false" flex="fill" [ngStyle]="{'padding-top': toolbarHeight + 'px'}">
		<div [hidden]="StateChangeStatus.changing" ui-view="content" layout="column" layout-wrap="false" flex="fill" class="overflow-hidden"></div>
		<vb-loading-spinner class="" [hidden]="!StateChangeStatus.changing" [block]="true" [size]="'large'"></vb-loading-spinner>
	</div>
</div>


