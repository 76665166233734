<div class="vb-insight" (keydown.enter)="preventEnter($event)" (keydown.escape)="closeOptions()" (keydown.arrowup)="arrowUp($event)" (keydown.arrowdown)="arrowDown($event)" (keydown.arrowleft)="arrowLeftRight(-1)" (keydown.arrowright)="arrowLeftRight(1)">

	<div class="search-field-wrapper" [class.focus]="showOptions">

		<div class="search-field-inner">
			<div class="flex-fill">
				<div class="search-field">
					<span class="glyphicons search icon"></span>
					<button type="button" class="btn btn-close" [hidden]="!showOptions" (click)="closeOptions()">
						{{strings.done}}
					</button>
					<input class="input" name="query" #queryInput autocomplete="off" [(ngModel)]="query" (ngModelChange)="optionsDataSource.setLoading(); onQueryInput()" (focus)="onSearchFocused()" [attr.aria-label]="strings.findItemsPlaceholder" [placeholder]="strings.findItemsPlaceholder">
				</div>
			</div>
		</div>

		<div *ngIf="showOptions && query">
			<ng-template #defaultOptionTemplate let-item="item" let-insight="insight">
				<div class="insight-option-row">
					<div (click)="insight.toggleItemAssignment(item)">
						<button type="button" class="btn btn-link float-right" [class.btn-assigned]="item.assigned" [attr.aria-label]="'AddItem' | translate : { '0': item[insight.insightOptions.fieldDefs.display] }">
							<span class="glyphicons" [class.ok_2]="item.assigned" [class.plus]="!item.assigned">
							</span>
						</button>
					</div>
					<div (click)="insight.toggleItemAssignment(item)">
						<span class="circle glyphicons" [ngClass]="insight.getIconClass(item)">
						</span>
					</div>
					<div class="flex-fill text-ellipsis insight-option" (click)="insight.toggleItemAssignment(item)">

						<span [innerHTML]="item[insight.insightOptions.fieldDefs.display] | insightHilight
							: insight.query
							: insight.insightOptions.fieldDefs.edgeHilight"></span>

						<span class="insight-sub-display" *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [innerHTML]="item[insight.insightOptions.fieldDefs.subDisplay] | insightHilight
								: insight.query
								: insight.insightOptions.fieldDefs.edgeHilight"></span>
					</div>
				</div>
			</ng-template>

			<div class="insight-options">
				<div class="insight-scroll-container">

					<div class="status-message margin-top-10" *ngIf="!optionsDataSource.items.length"> {{
							optionsDataSource.loading ?
								strings.searching :
								strings.noResultsFound
						}}
					</div>
					<cdk-virtual-scroll-viewport #optionsViewport itemSize="44">
						<div *cdkVirtualFor="let item of optionsDataSource; index as index" class="insight-row" [ngClass]="[
								item.assigned ? 'assigned' : '',
								'insight-row-' + index
							]" (focusin)="onRowFocused(index, $event)">
							<ng-container *ngTemplateOutlet="(optionTemplate || defaultOptionTemplate); context: { item: item, insight: this }"></ng-container>
						</div>
					</cdk-virtual-scroll-viewport>
				</div>
				<div [hidden]="!optionsDataSource.overLimit" class="message">
					<p class="message-content">{{strings.recordLimit}}</p>
				</div>
			</div>
		</div>
	</div>

	<div class="widget-members" [class.focus]="showOptions">
		<div class="insight-scroll-container">
			<div [hidden]="assignedItems.length !== 0" class="no-items">
				{{strings.noItemsAssigned}}
			</div>

			<ng-template #defaultAssignedTemplate let-item="item" let-insight="insight">
				<div class="insight-option-row">
					<div>
						<span class="circle glyphicons" [ngClass]="insight.getIconClass(item)"></span>
					</div>
					<div class="flex-fill text-ellipsis insight-option">
						<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
						<span class="insight-sub-display" *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]">{{ item[insight.insightOptions.fieldDefs.subDisplay] }}</span>
					</div>
					<div>
						<button type="button" class="btn btn-link float-right remove-assigned-item" (click)="insight.removeItem(item)" [attr.aria-label]="'RemoveItem' | translate : { '0': item[insight.insightOptions.fieldDefs.display] }">
							<span class="glyphicons remove_2"></span>
						</button>
					</div>
				</div>
			</ng-template>

			<cdk-virtual-scroll-viewport #assignedViewport class="insight-scroll-container" [itemSize]="44" [minBufferPx]="346" [maxBufferPx]="346">
				<div *cdkVirtualFor="let item of assignedOptionsDataSource; index as index" class="insight-row" [ngClass]="'insight-row-' + index" (focusin)="onRowFocused(index, $event)">
					<ng-container *ngTemplateOutlet="(assignedTemplate || defaultAssignedTemplate); context: { item: item, insight: this }"></ng-container>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
