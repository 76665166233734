import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';

import { VbProfilePictureComponent } from './VbProfilePicture.Component';

const components = [
	VbProfilePictureComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		CommonModule,
		DirectivesAngularModule
	],
})
export class VbProfilePictureModule {}
