import type { SortChangedEvent } from '@ag-grid-community/core';
import { EventEmitter } from '@angular/core';

export const ROW_HEIGHT_SMALL = 90;
export const ROW_HEIGHT_LARGE = 130;
export const IMAGE_WIDTH_LARGE = 190;
export const IMAGE_WIDTH_SMALL = 80;

export const sortFieldMapping = {
	ownerName: 'ownerName.sort',
	title: 'title.sort'
};

export interface ITableViewApi {
	reloadTableView: () => void;
	loadNexBlockData:() => Promise<void>;
	sortChanged: EventEmitter<SortChangedEvent<any>>,
	resetSelection: () => void;
}

export enum TABLE_DATA_MODE {
	Category = 'Category',
	Video = 'Video',
	CategoryAndVideo = 'CategoryAndVideo',
	Expiration = 'Expiration'
}

export function getTableDataMode(expirationState: boolean, rootCategory: boolean, categoryId: string): TABLE_DATA_MODE {
	return expirationState ? TABLE_DATA_MODE.Expiration :
		rootCategory ? TABLE_DATA_MODE.Category :
		categoryId ? TABLE_DATA_MODE.CategoryAndVideo : TABLE_DATA_MODE.Video;
}
