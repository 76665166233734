import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';

import { Filter, SearchTerm } from './SearchFilterTypes';
import { getStaticFilters } from './SearchFilterDefinitions.Service';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

export interface ISearchFilters {
	[key: string]: Filter;
}

@Injectable({
	providedIn: 'root'
})
export class SearchFilterStateService {
	private readonly changeSubject$: Subject<ISearchFilters> = new Subject<ISearchFilters>();
	public change$: Observable<ISearchFilters> = this.changeSubject$.asObservable();
	public paramsSubscription: Subscription;
	public filters: ISearchFilters;

	constructor(
		private $uiRouterGlobals: UIRouterGlobals
	) {}

	public startService(): void {
		this.paramsSubscription = this.$uiRouterGlobals.params$.subscribe((params: any) => {
			if (params?.filters) {
				this.update(JSON.parse(params.filters));
			}
		});
	}

	public stopService(): void {
		this.initialize();
		this.paramsSubscription?.unsubscribe();
	}

	public buildQuery(queryBuilder?: SearchQueryBuilder): string {
		return buildQuery(this.filters, queryBuilder);
	}

	public getQueryValues(): any {
		const values = {};
		forEachFilter(this.filters, (filter, key) => values[key] = filter.getQueryValue());
		return values;
	}

	public clear(notify: boolean = true): void {
		this.forEachFilter(filter => filter.clear());

		if (notify) {
			this.changeSubject$.next(this.filters);
		}
	}

	public clearAllOverrides(): void {
		this.forEachFilter(filter => filter.clearValueOverride());
	}

	public clone(): ISearchFilters {
		const clone = {};

		this.forEachFilter((field, key) => clone[key] = field.clone());

		return clone;
	}

	public initialize(searchTerm?: boolean): void {
		this.filters = getStaticFilters();
		if(searchTerm) {
			this.filters.searchTerm = new SearchTerm();
		}
	}

	public update(filterValues?: any): void {
		setFilters(this.filters, filterValues || {});
		this.changeSubject$.next(this.filters);
	}

	public setFilters(filters:ISearchFilters): void {
		this.filters = filters;
		this.changeSubject$.next(this.filters);
	}

	private forEachFilter(fn: (field: Filter, key: string) => any): void {
		forEachFilter(this.filters, fn);
	}

	public go(filters: any, $state: StateService): void {
		filters = Object.keys(filters).length > 0 ? filters: '';
		$state.go('.', { filters: JSON.stringify(filters) }, { reload: false });
	}
}

export function getFiltersQuery(filterValues: any, queryBuilder?: SearchQueryBuilder): string {
	const filters = getStaticFilters();
	setFilters(filters, filterValues);
	return buildQuery(filters, queryBuilder);
}

function buildQuery(filters: ISearchFilters, queryBuilder?: SearchQueryBuilder): string {
	queryBuilder = queryBuilder || new SearchQueryBuilder();

	forEachFilter(filters, filter => filter.addToQuery(queryBuilder));

	return queryBuilder.buildQuery();
}

function forEachFilter(filters: ISearchFilters, fn: (field: Filter, key: string) => any): void {
	if (filters) {
		Object.keys(filters)
			.forEach(key => fn(filters[key], key));
	}
}

export function setFilters(filters: ISearchFilters, filterValues: any): void {
	forEachFilter(filters, (filter, key) => {
		const filterValue = filter.isValue(filterValues[key]) ? filterValues[key] : filter.getDefaultValue();
		filter.update(filterValue);
	});
}

export function getFilterValues(filters: ISearchFilters, checkHasValue?: boolean): ISearchFilters {
	const values = {};
	forEachFilter(filters, (filter, key) => {
		if(!checkHasValue || filter.hasValue) {
			values[key] = filter.getValue();
		}
	});
	return values;
}
