<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>

<ng-template #itemListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
	<div [ngClass]="styles.videoList">
		<div class="navbar" [ngClass]="styles.videoListButtonContainer">
			<div vbUiRadioBtnGroup>
				<button type="button" (click)="refreshData()" [vbUiRadioBtn]="false" [(ngModel)]="isAllVideos">
					{{ 'Media_UserVideos' | translate }}
				</button>
				<button type="button" (click)="refreshData()" [vbUiRadioBtn]="true" [(ngModel)]="isAllVideos">
					{{ 'Media_AllVideos' | translate }}
				</button>
			</div>
			<button type="button" [attr.aria-label]="'Close'| translate" [ngClass]="styles.closeBtn" class="btn btn-transparent" (click)="hide()">
				<span class="glyphicons remove_2"></span>
			</button>
		</div>
		<cdk-virtual-scroll-viewport itemSize="25" #itemsViewport [ngClass]="styles.viewport">
			<div *cdkVirtualFor="let match of dataSource; index as index" [ngClass]="[styles.videoListItem]" [class.active]="isActive(index)" (click)="selectMatch(index, $event)" (mouseenter)="activateItem(index)">
				<div [ngClass]="styles.itemContainer">
					<span [ngClass]="styles.duration">{{ formatDuration(match.durationMs) }}</span>
					<vb-ui-thumbnail-sheet-preview [thumbnailUri]="match.thumbnailUri || '/shared/img/default-thumbnail.png'" [thumbnailSheetCfg]="match.thumbnailSheets" [width]="120" [height]="67">
					</vb-ui-thumbnail-sheet-preview>
					<div [ngClass]="styles.videoInfo">
						<div [ngClass]="styles.videoInfoItem">
							<span [ngClass]="[styles.infoTitle, styles.infoBold]">{{ 'Title' | translate }}</span>
							<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Description' | translate }}</span>
						</div>
						<div [ngClass]="[styles.videoInfoItem, styles.infoLarge]">
							<span [ngClass]="[styles.infoTitle, styles.infoTxt]" [innerHTML]="match.title | highlight : search : styles.highlight : true"></span>
							<span [ngClass]="styles.infoTxt">{{match.indexedDescription}}</span>
						</div>
						<div [ngClass]="styles.videoInfoItem">
							<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Media_Videos_Owner' | translate }}</span>
							<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Uploaded' | translate }}</span>
						</div>
						<div [ngClass]="styles.videoInfoItem">
							<span [ngClass]="styles.infoTxt">{{match.ownerFirstName}} {{match.ownerLastName}}</span>
							<span [ngClass]="styles.infoTxt">{{match.whenUploaded | vbDateMedium }}</span>
						</div>
					</div>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</ng-template>

<div>
	<div *ngIf="!isValueSelected">
		<div [ngClass]="[styles.inputContainer, styles.videoInputContainer]">
			<span [ngClass]="styles.inputIcon" class="glyphicons search"></span>
			<input #queryInput [(ngModel)]="search" [typeahead]="typeaheadInput$" [typeaheadAsync]="true" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="maxInt" [optionsListTemplate]="itemListTemplate" [typeaheadHideResultsOnBlur]="false" (typeaheadOnSelect)="onSelect($event)" [typeaheadWaitMs]="200" typeaheadOptionField="title">
			<button type="button" *ngIf="search" (click)="clearSelection()" role="button">
				<span class="glyphicons remove_2"></span>
			</button>
		</div>
	</div>
	<div *ngIf="isValueSelected">
		<div [ngClass]="styles.selectedItemContainer">
			<div class="position-relative">
				<vb-ui-thumbnail-sheet-preview [thumbnailUri]="value.thumbnailUri || '/shared/img/default-thumbnail.png'" [thumbnailSheetCfg]="value.thumbnailSheets" [width]="120" [height]="67">
				</vb-ui-thumbnail-sheet-preview>
				<span [ngClass]="styles.duration">{{ formatDuration(value.durationMs) }}</span>
			</div>

			<div [ngClass]="styles.videoInfo">
				<div [ngClass]="styles.videoInfoItem">
					<span [ngClass]="[styles.infoTitle, styles.infoBold]">{{ 'Title' | translate }}</span>
					<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Description' | translate }}</span>
				</div>
				<div [ngClass]="[styles.videoInfoItem, styles.infoLarge]">
					<span [ngClass]="[styles.infoTitle, styles.infoTxt]"> {{value.title }}</span>
					<span [ngClass]="styles.infoTxt">{{value.indexedDescription}}</span>
				</div>
				<div [ngClass]="styles.videoInfoItem">
					<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Media_Videos_Owner' | translate }}</span>
					<span [ngClass]="[styles.infoTxt, styles.infoBold]">{{ 'Uploaded' | translate }}</span>
				</div>
				<div [ngClass]="styles.videoInfoItem">
					<span [ngClass]="styles.infoTxt">{{value.ownerFirstName}} {{value.ownerLastName}}</span>
					<span [ngClass]="styles.infoTxt">{{value.whenUploaded | vbDateMedium }}</span>
				</div>
			</div>
			@if(!readonly) {
				<button (click)="clearSelection()">
					<span class="glyphicons remove_2"></span>
				</button>
			}
		</div>
	</div>
</div>
