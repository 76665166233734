import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, first, timeout } from 'rxjs/operators';
import { Observable, of, merge } from 'rxjs';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { SameSite, getCookie, setCookie, unsetCookie } from 'rev-shared/util/CookieUtil';

import { UserContextService } from './UserContext.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

interface ILegacyIEDocument extends Document {
	documentMode?: number;
}

@Injectable({
	providedIn: 'root'
})
export class UserLocalIPService {
	private readonly userIpCookie: string = 'userIp';

	private bootstrapUserLocation: any;
	private userIp: string;
	private loadIpPromise: Promise<void>;

	constructor(
		private UserContext: UserContextService,
		private http: HttpClient
	) {}

	public get currentUserIp(): string {
		return this.userIp;
	}

	public init(): void {
		this.UserContext.userIdChanged$.subscribe(() => this.onUserContextChange());
		this.bootstrapUserLocation = BootstrapContext.userLocation || {};
		this.userIp = getCookie(this.userIpCookie);
	}

	public checkUserLocation(): Promise<any> {

		if(!this.shouldGetIp()) {
			if (!this.userLocationEnabled()) {
				this.userIp = BootstrapContext.userIp;
			}
			return Promise.resolve();
		}

		if (!this.loadIpPromise) {
			this.loadIpPromise = lastValueFrom(this.loadLocalIp([this.bootstrapUserLocation.url, this.bootstrapUserLocation.fallbackUrl]))
				.then(ip => {
					if (ip) {
						this.userIp = ip;
						setCookie(this.userIpCookie, ip, { sameSite: SameSite.None });
					} else {
						this.userIp = undefined;
						unsetCookie(this.userIpCookie);
					}
				});
		}

		return this.loadIpPromise;
	}

	private onUserContextChange(): void {
		unsetCookie(this.userIpCookie);
		this.userIp = undefined;
		this.loadIpPromise = undefined;
	}

	private loadLocalIp(urls: string[]): Observable<string> {
		const getIps$ = urls
			.filter(Boolean)
			.map(url => this.getIp(url).pipe(
				catchError(e => {
					console.log('ULS Error:', e);
					return of(null);
				})
			));

		return merge(...getIps$).pipe(
			first(Boolean, null),
			timeout({
				each: BootstrapContext.userLocationTimeoutMs,
				with: () => of(null)
			}),
			map(ip => ip || BootstrapContext.userIp)
		);
	}

	public getIp(userLocationUrl: string): Observable<string> {
		const parseIp = txt => txt.split(',')[0].trim();

		if((document as ILegacyIEDocument).documentMode <= 9) {
			return this.http.jsonp<{ data: { ip: string } }>(userLocationUrl, 'callback').pipe(
				map(callback => parseIp(callback.data.ip))
			);
		}

		return this.http.get<{ ip: string }>(userLocationUrl).pipe(
			map(result => parseIp(result.ip))
		);
	}

	private shouldGetIp(): boolean {
		return this.userLocationEnabled() && !this.userIp;
	}

	private userLocationEnabled(): boolean {
		return this.bootstrapUserLocation?.enabled && this.bootstrapUserLocation?.url;
	}
}
