import { InjectionToken } from '@angular/core';

import { LicenseType } from 'rev-shared/security/LicenseType';
import { ModuleType } from 'rev-shared/security/ModuleType';
import { PushService } from 'rev-shared/push/PushService';

import { IRevConnectLicense } from './IRevConnectLicense';

type AccountSaveModel = AccountModel & {
	revConnectEnabled: boolean;
	revConnectPeerMeshAllotment: number;
};

let _PushService: PushService;

export const ACCOUNT_DETAIL_SERVICE_TOKEN: InjectionToken<typeof AccountModel> = new InjectionToken('AccountDetail');

export function getAccountDetailService(PushService: PushService) {
	//TODO: AccountDetail should not depend on PushService
	_PushService = PushService;
	return AccountModel;
}

export class AccountModel{
	public billingAddress: any;
	public children: any[];
	public contactAddress: any;
	public contactEmail: string;
	public contactFirstName: string;
	public contactLastName: string;
	public contactPhone: string;
	public dmeLockdownFeatureAvailableForAccount: boolean;
	public hostname: string;
	public id: string;
	public language: string;
	public licenseCount: number;
	public activeUsersLicenseCount: number;
	public concurrentUsersLicenseCount: number;
	public licenseType: LicenseType;
	public modules: ModuleType[];
	public unlimitedECDN: boolean;
	public parentUnlimitedECDN: boolean;
	public keyManagementEnabled: boolean;
	public maxFileUploadSizeGb: number;
	public name: string;
	public parentAccountId: string;
	public isParentRootAccount: boolean;
	public isRootAccount: boolean;
	public revConnectLicense: IRevConnectLicense;
	public timezone: string;
	public viewingHoursBuckets: any[];
	public distributionHoursBuckets: any[];
	public aiCreditsBuckets: any[];
	public aiCreditsFeature: string;
	public type: string;
	public autoIngestRecordingHoursPerDay: number;
	public allocatedAutoIngestRecordingHoursPerDay: number;
	public userProvisioningEnabled?: boolean;
	public billingFirstName?: string;
	public billingLastName?: string;
	public billingEmail?: string;
	public billingPhone?: string;

	constructor(data){
		Object.assign(this, {
			billingAddress: {},
			children: [],
			contactAddress: {},
			timezone: 'UTC',
			viewingHoursBuckets: [],
			distributionHoursBuckets: [],
			aiCreditsBuckets: []
		}, data);
	}

	public update(data): void {
		Object.assign(this, data);
	}

	public save(): any {
		return this.id ? this.saveAccount() : this.openAccount();
	}

	public pushChildAccount(data): void {
		const childAccount = this.children.find(child => child.id === data.id);
		if (childAccount){
			Object.assign(childAccount, data);
		} else {
			this.children = [...this.children, data];
		}
	}

	public useContactInfoForBilling(): void {
		Object.assign(this, {
			billingFirstName: this.contactFirstName,
			billingLastName: this.contactLastName,
			billingAddress: { ...this.contactAddress },
			billingPhone: this.contactPhone,
			billingEmail: this.contactEmail
		});
	}

	public isConcurrentUsersLicense(): boolean {
		return this.licenseType === LicenseType.CONCURRENT_USERS;
	}

	private openAccount(): Promise<void>{
		const data = this.shapeDataForSave(this.parentAccountId);

		return _PushService.dispatchCommand('network:OpenAccount', data);
	}

	public saveAccount(){
		const data = this.shapeDataForSave(this.id);

		return _PushService.dispatchCommand('network:SaveAccountDetails', data);
	}

	private shapeDataForSave(accountId: string): AccountSaveModel {
		const revConnectLicense = this.revConnectLicense || {} as IRevConnectLicense;

		return Object.assign({}, this, {
			accountId,
			revConnectEnabled: !!revConnectLicense.enabled,
			revConnectPeerMeshAllotment: revConnectLicense.allocated || 0,
			revConnectLicense: undefined,
			licenseCount: this.licenseType === LicenseType.ACTIVE_USERS ? this.activeUsersLicenseCount
				: this.licenseType === LicenseType.BY_NAMED_USERS ? this.licenseCount
				: this.licenseType === LicenseType.CONCURRENT_USERS ? this.concurrentUsersLicenseCount
				: 0
		});
	}
}
