import { IVbUiDataGridValueFormatterParams } from './IVbUiDataGridValueFormatterParams';

import { formatMediumDateTime, formatMediumDate } from 'rev-shared/date/DateFormatters';

export function mediumDateTimeValueFormatter(params: IVbUiDataGridValueFormatterParams): string {
	return formatMediumDateTime(params.value);
}

export function mediumDateValueFormatter(params: IVbUiDataGridValueFormatterParams): string {
	return formatMediumDate(params.value);
}
