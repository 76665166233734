import { NgModule } from '@angular/core';

import { SpinnerAngularModule } from './SpinnerAngular.Module';
import { VbCenteredSpinnerComponent } from './VbCenteredSpinner.Component';
import { VbLoadingSpinnerComponent } from './VbLoadingSpinner.Component';

const components = [
	VbCenteredSpinnerComponent,
	VbLoadingSpinnerComponent
];

@NgModule({
	imports: [
		SpinnerAngularModule
	],
	exports: components
})
export class SpinnerRootModule { }
