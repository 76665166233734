import { Injectable } from '@angular/core';

import { AuthenticationSourceType } from 'rev-shared/security/AuthenticationSourceType';
import styles from 'rev-shared/ui/insight/templates/InsightTemplates.module.less';
import { IInsightRecord } from 'rev-shared/ui/insight/ngx/Insight.Contract';

import { SearchConstants } from './SearchConstants';
import { SearchService, IAccessEntitySearchHits } from './Search.Service';

const infiniteScrollPageSize = 25;

export interface ISearchResult {
	scrollId?: string;
	items: any[];
	count: number;
}

export interface IAccessEntityOpts {
	accountId: string;
	scrollId?: string;
	users?: boolean;
	groups?: boolean;
	teams?: boolean;
	permissions?: string[];
	sourceType?: AuthenticationSourceType;
	showEditAccessInput?: (item: IInsightRecord) => boolean;
	showAllChannels?: boolean;
	enableScroll?: boolean;
	getTeamIds?: () => string[];
	getEntityIds?: () => string[];

	//Optional search api. If not defined, default search api will be used
	loadQueryPage?: (query: string) => Promise<ISearchResult>;
	mapQueryPage?: (page: ISearchResult) => ISearchResult;
	pauseSearch?: () => boolean;
	disableAssignedInfiniteScroll?: boolean;
	onAssignedInfiniteScroll?: () => Promise<any[]> | undefined; // todo: this should not be used long term. For now needed because common impl does not load data in the exact order its scrolled in the ui.
}

@Injectable({
	providedIn: 'root'
})
export class InsightSearchHelper {

	constructor(
		private SearchService: SearchService
	){ }

	public buildAccessEntityInsightOptions(opts: IAccessEntityOpts): any {

		return {
			fieldDefs: {
				identifier: 'id',
				display: 'name',
				subDisplay: 'username',
				dataType: 'type',
				orderBy: 'name',
				ascending: true,
				edgeHilight: true,
				profileImageUri: 'profileImageUri'
			},
			dataTypes: {
				User: 'user',
				Team: styles.teamIcon,
				Group: 'group'
			},
			dataTypeLabels: {
				User: 'User',
				Team: 'Channel',
				Group: 'Group'
			},
			loadQueryPage: (query: string) => {
				if (!query.length) {
					return;
				}
				const result = opts.loadQueryPage ?
					opts.loadQueryPage(query) :
					this.loadAccessEntityQueryPage(opts, query);

				return opts.mapQueryPage ?
					result.then(opts.mapQueryPage) :
					result;
			},
			onAssignedInfiniteScroll: this.getAccessEntityAssignedInfiniteScrollHandler(opts),
			showEditAccessInput: opts.showEditAccessInput
		};
	}

	public loadAccessEntityQueryPage(opts: IAccessEntityOpts, query: string): Promise<ISearchResult> | undefined {
		if(query.length < SearchConstants.minAccessEntitySearchQueryLength ||
			opts.pauseSearch && opts.pauseSearch()){
			return;
		}

		return this.SearchService
			.queryAccessEntities({
				accountId: opts.accountId,
				query,
				accountPermissions: opts.permissions,
				type: [
					opts.users && SearchConstants.accessEntityTypes.user,
					opts.groups && SearchConstants.accessEntityTypes.group,
					opts.teams && SearchConstants.accessEntityTypes.team
				].filter(Boolean),
				scrollId: opts.scrollId,
				sourceType: opts.sourceType,
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				teamIds: opts.getTeamIds ? opts.getTeamIds() : undefined,
				count: SearchConstants.accessEntityPageSize,
				noScroll: !opts.enableScroll,
				showAllChannels: opts.showAllChannels || undefined
			})
			.then(result => {
				return {
					items: result.accessEntities,
					scrollId: result.scrollId,
					count: result.totalHits
				};
			});
	}

	private getAccessEntityAssignedInfiniteScrollHandler(opts: IAccessEntityOpts): () => Promise<any> {
		if(opts.disableAssignedInfiniteScroll) {
			return;
		}
		if (opts.onAssignedInfiniteScroll) {
			return opts.onAssignedInfiniteScroll;
		}

		let selectedEntitiesLoaded = false;
		let offset = 0;
		let entityIds: any[];

		const types = [
			opts.users && SearchConstants.accessEntityTypes.user,
			opts.groups && SearchConstants.accessEntityTypes.group,
			opts.teams && SearchConstants.accessEntityTypes.team
		].filter(Boolean);

		return () => {
			entityIds = entityIds || (opts.getEntityIds ? opts.getEntityIds() : []);
			if (selectedEntitiesLoaded || !entityIds.length) {
				return Promise.resolve({
					results: [],
					complete: true
				});
			}
			const entitiesToLoad = entityIds.slice(offset, offset + infiniteScrollPageSize);

			return this.getAccessEntities(opts.accountId, entitiesToLoad, types, opts.showAllChannels)
				.then(({ accessEntities }) => {
					offset += infiniteScrollPageSize;
					selectedEntitiesLoaded = offset >= entityIds.length;

					return {
						results: accessEntities,
						complete: selectedEntitiesLoaded
					};
				});
		};
	}

	public getAccessEntities(accountId: string, ids: string[], types?: string[], showAllChannels?: boolean): Promise<IAccessEntitySearchHits> {
		return this.SearchService
			.queryAccessEntities({
				accountId,
				ids,
				type: types || [
					SearchConstants.accessEntityTypes.user,
					SearchConstants.accessEntityTypes.group,
					SearchConstants.accessEntityTypes.team
				],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: ids.length,
				noScroll: true,
				showAllChannels: showAllChannels || undefined
			});
	}

	public getUserSearchLoader(accountId: string, rights: string[]): (query: string) => () => Promise<ISearchResult> {
		return query => () => {
			let scrollId = null;
			return this.loadAccessEntityQueryPage({
				accountId: accountId,
				enableScroll: true,
				permissions: rights,
				scrollId: scrollId,
				users: true
			}, query).then((result: ISearchResult) => {
				scrollId = result.scrollId;
				return result as ISearchResult;
			});
		};
	}
}
