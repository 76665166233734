<div *ngIf="sidebarConfig" [ngClass]="[
		styles.sidebarButtonContainer,
		themed ? 'theme-primary theme-primary-txt theme-primary-font-border-fade-50 sidebarButtonsWrapper' : styles.sidebarButtonContainerFixedColor
	]" [class.noBorder]="noBorder" role="tablist" [attr.aria-label]="'SideBarMenu' | translate">
	<button *ngFor="let button of sidebarConfig.buttons; let firstBtn = first; let btnIndex = index" #sidebarButtonsRef type="button" role="tab" [attr.tabindex]="(firstBtn && !isOpen) || (button.id === activeSidebarButtonId) ? 0 : -1" [attr.aria-selected]="button.id === activeSidebarButtonId" [class.active]="button.id === activeSidebarButtonId" [class.noBorder]="noBorder" [ngClass]="[
			styles.sidebarButton,
			themed ? 'theme-primary-txt theme-primary-font-border-fade-50' : styles.sidebarButtonFixedColor
		]" [attr.aria-label]="button.label" [attr.id]="button.id" (mousedown)="onSidebarButtonMousedown($event, button)" (focus)="onSidebarButtonFocus(button)" (keydown.enter)="onSidebarButtonEnter(button)" (keydown.arrowup)="onSidebarButtonArrowUp(btnIndex)" (keydown.arrowdown)="onSidebarButtonArrowDown(btnIndex)" (keydown.arrowleft)="onSidebarButtonArrowLeft($event)" (keydown.arrowright)="onSidebarButtonArrowRight($event)" [tooltip]="button.label" container="body" [adaptivePosition]="false" [containerClass]="isMobileWindow ? styles.tooltipContainer : ''" [placement]="isMobileWindow ? 'top left' : 'left'">
		<span class="glyphicons" [ngClass]="[styles.btnIcon, button.iconClass]"></span>
		<span class="glyphicons bell" [ngClass]="[styles.btnIcon, styles.notificationIcon]" [hidden]="!button.notification?.showIcon">
		</span>
		<span [ngClass]="[styles.btnIcon, styles.notificationCount]" *ngIf="button.notification?.count">
			{{ getNotificationCountDisplay(button.notification.count) }}
		</span>
	</button>
</div>

<div flex="fill" role="tabpanel" [attr.layout-lt-md]="isMobileLayoutDisabled ? null : 'column'" [class.open]="isOpen" [class.noBorder]="noBorder" [ngClass]="[
		styles.sidebarPanel,
		themed ? 'theme-primary theme-primary-txt theme-primary-font-border-fade-50 sidebarPanelWrapper' : styles.sidebarPanelFixedColor,
		enableAnimation ? styles.transitionWidth : ''
	]" [style.width]="isOpen && openWidthPx ? openWidthPx + 'px' : ''">
	<div [attr.flex-lt-md]="isMobileLayoutDisabled ? null : 'fill'" layout="column" layout-wrap="false" [ngClass]="styles.sidebarPanelLayout" [style.width]="isOpen && openWidthPx ? openWidthPx + 'px' : ''">

		<header layout="row" layout-wrap="false" [ngClass]="styles.header">

			<div flex="fill">
				{{ buttonConfigLastClick?.label }}
			</div>

			<div>
				<ng-content select="[slot='headerControls']"></ng-content>
			</div>

			<button *ngIf="!sidebarConfig.closeBtnHidden" type="button" class="margin-left-10" [attr.aria-label]="'Event_CloseSidebarPanel' | translate" vbBtnPrimary (click)="closeSidebar()">
				<span class="glyphicons remove_2"></span>
			</button>
		</header>

		<div *ngIf="isOpen" flex="fill" flex-align="center" class="vb-scrollbar" [ngClass]="styles.sidebarPanelBody" ui-view="sidebar">
			<ng-content select="[slot='panelBody']"></ng-content>
		</div>
	</div>
</div>
