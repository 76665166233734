import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import type { IHeaderAngularComp } from '@ag-grid-community/angular';
import type { AgEvent, IHeaderParams, GridApi } from '@ag-grid-community/core';

import { INFINITE_GRID_BATCH_LOAD_COMPLETE, InfiniteGridBatchLoadCompleteEvent } from '../infiniteScroll/VbUiInfiniteScrollGridDataSource';
import { VbUiInfiniteScrollGridComponent } from '../infiniteScroll/VbUiInfiniteScrollGrid.Component';

import styles from './HeaderSelectAllCheckboxRenderer.Component.module.less';

export interface ISelectionChangedEvent extends AgEvent {
	isSelectedAll?: boolean;
	rowIds?: string[];
	[key: string]: any;
}

export enum SELECTION_EVENTS {
	VbSelectionChanged = 'vbSelectionChanged',
	VbSelectAllChanged = 'VbSelectAllChanged'
}

/**
 * A Header Select All Component to be used with Infinite Ag-grid.
 * The load all grid supports header select All out of box but not infinite grid.
 */
@Component({
	selector: 'header-select-all-check-box-renderer',
	templateUrl: './HeaderSelectAllCheckboxRenderer.Component.html'
})
export class HeaderSelectAllCheckboxRendererComponent implements OnDestroy, IHeaderAngularComp {
	public isChecked: boolean;
	public isDisabled: boolean;
	public readonly styles = styles;
	public isThemed: boolean;

	private gridApi: GridApi;
	private loadComplete = ($event: InfiniteGridBatchLoadCompleteEvent) => this.onDataLoadComplete($event);
	private totalLoadedRows: number;
	private parentGrid: VbUiInfiniteScrollGridComponent;
	private subscription: Subscription = new Subscription();
	private trackSelectAllOnly: boolean;
	private trackOtherProps: (selectedRows: any[]) => { [key: string]: any};

	public eGridHeader: HTMLElement;

	public agInit(params: IHeaderParams): void {
		this.updateCell(params);
		this.gridApi.addEventListener(INFINITE_GRID_BATCH_LOAD_COMPLETE, this.loadComplete);
		this.subscription.add(this.parentGrid.selectionChanged.subscribe(() => this.onRowSelected()));
		this.subscription.add(this.parentGrid.filterChanged.subscribe(() => this.onResetSelectAll()));
		this.subscription.add(this.parentGrid.sortChanged.subscribe(() => this.onResetSelectAll()));

		this.eGridHeader = params.eGridHeader;
	}

	public refresh(params: IHeaderParams): boolean {
		this.isChecked = false;
		this.updateCell(params);

		return true;
	}

	public onCheckboxChange(): void {
		if (this.trackSelectAllOnly) {
			this.handleSelectAllOnlyChange();
			return;
		}
		this.selectRows(0, this.totalLoadedRows, this.isChecked);
		this.dispatchSelectionChangeEvent();
	}

	public handleSelectAllOnlyChange(): void {
		this.dispatchSelectAllChangedEvent();
		this.selectRows(0, this.totalLoadedRows, this.isChecked);
	}

	public ngOnDestroy(): void {
		this.gridApi.removeEventListener(INFINITE_GRID_BATCH_LOAD_COMPLETE, this.loadComplete);
		this.subscription?.unsubscribe();
	}

	public onKeyPressed(event: KeyboardEvent): void {
		this.isChecked = !this.isChecked;
		this.onCheckboxChange();
	}

	private updateCell(params: IHeaderParams): void {
		this.gridApi = params.api;
		this.parentGrid = params.context.grid;
		this.isThemed = params.context.isThemed;
		this.trackSelectAllOnly = params.context.trackSelectAllOnly;
		this.trackOtherProps = params.context.trackOtherProps;
	}

	private onDataLoadComplete(data: InfiniteGridBatchLoadCompleteEvent): void {
		this.totalLoadedRows = data.batchLastIndex;
		if (this.isChecked) {
			this.selectRows(data.batchStartIndex, data.batchLastIndex, true);
		}
	}

	private selectRows(startIndex: number, endIndex: number, checked: boolean): void {
		for(let i = startIndex; i <= endIndex; i++) {
			this.gridApi.getRowNode(i.toString())?.setSelected(checked);
		}
	}

	private onRowSelected(): void {
		if (!this.trackSelectAllOnly) {
			this.isChecked = this.totalLoadedRows && this.totalLoadedRows === this.gridApi.getSelectedRows()?.length;
		}
		this.dispatchSelectionChangeEvent();
	}

	private onResetSelectAll(): void {
		this.isChecked = false;
		this.dispatchSelectionChangeEvent();
	}

	private dispatchSelectionChangeEvent(): void {
		this.gridApi.dispatchEvent({
			isSelectedAll: this.isChecked,
			rowIds: this.gridApi.getSelectedRows()?.map(row => row.id),
			...this.trackOtherProps?.(this.gridApi.getSelectedRows()),
			type: SELECTION_EVENTS.VbSelectionChanged
		} as ISelectionChangedEvent);
	}

	private dispatchSelectAllChangedEvent(): void {
		this.gridApi.dispatchEvent({ isSelectedAll: this.isChecked, type: SELECTION_EVENTS.VbSelectAllChanged } as ISelectionChangedEvent);
	}
}
