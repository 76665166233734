const ip4Regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
const ip6Regex = /^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i;


const cidr_ip4_regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})(\/(\d|[12]\d|3[0-2]))?$/;
const cidr_multicast_ipv4 = /^((22[4-9]|23\d)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(\d{1,3})\.(\d{1,3}))(\/(\d|[12]\d|3[0-2]))?$/;

const ipv6CidrPattern = /([\da-f:.]{3,39})\/(\d{1,2}|1[0-1]\d|12[0-8])$/i;
const ipv6CidrMulticastPattern = /(ff[\da-f:.]{3,39})\/(\d{1,2}|1[0-1]\d|12[0-8])$/i;

const IPV6_NUM_GROUPS: number = 8;
export const IP_V4_MULTICAST_MIN: string = '224.0.0.0';
export const IP_V4_MULTICAST_MAX: string = '239.255.255.255';
export const IP_V6_MULTICAST_MIN: string = 'ff00::';
export const IP_V6_MULTICAST_MAX: string = 'ffff:ffff:ffff:ffff:ffff:ffff:ffff:ffff';

export interface IPRange {
	start?: string;
	end?: string;
	cidr?: string;
}

/**
 * Validates an IP address (v4 or v6)
 */
export function validateIP(ip: string): boolean {
	return !ip || validateIPv4(ip) || validateIPv6(ip);
}

export function validateIPv4(value: string): boolean {
	if(!value) {
		return true;
	}

	const match = value.match(ip4Regex);
	if(!match) {
		return false;
	}

	for(let i=1, len=match.length; i < len; i++) {
		const octet = +match[i];
		if(octet < 0 || octet > 255) {
			return false;
		}
	}

	return true;
}

export function validateIPMulticast(ip: string): boolean {
	return ip
		&& (validateIPv4(ip)
			&& validateIPRange({ start: IP_V4_MULTICAST_MIN, end: ip })
			&& validateIPRange({ start: ip, end: IP_V4_MULTICAST_MAX })
			||
			validateIPv6(ip)
			&& validateIPRange({ start: IP_V6_MULTICAST_MIN, end: ip })
			&& validateIPRange({ start: ip, end: IP_V6_MULTICAST_MAX })
		);
}

export function validateIPRangeMulticast(ipRange: IPRange): boolean {
	if (!ipRange || !(ipRange.start && ipRange.end)) {
		return false;
	}

	return validateIPRange({ start: ipRange.start, end: ipRange.end })
		&& validateIPMulticast(ipRange.start)
		&& validateIPMulticast(ipRange.end);
}

export function validateIPv6(ip: string): boolean {
	return ip6Regex.test(ip);
}

export function validateIPRange(range: IPRange): boolean {
	let start: string[];
	let end: string[];
	let base: number;

	if(validateIPv4(range.start) && validateIPv4(range.end)) {
		start = range.start.split('.');
		end = range.end.split('.');
		base = 10;
	}
	else if(validateIPv6(range.start) && validateIPv6(range.end)) {
		start = getNormalizedIPv6Split(range.start);
		end = getNormalizedIPv6Split(range.end);
		base = 16;
	}
	else {
		return false;
	}

	for(let i=0, len=start.length; i < len; i++) {
		const a = parseInt(start[i], base);
		const b = parseInt(end[i], base);

		if(a > b) {
			//end ip is before start
			return false;
		}

		if(a < b) {
			//end ip is after start
			return true;
		}
	}

	//start == end, invalid
	return false;
}

export function validateCIDR(ip: string): boolean {
	return cidr_ip4_regex.test(ip) || validateIPv6CIDRByPattern(ip, ipv6CidrPattern);
}

export function validateMulticastCIDR(ip: string): boolean {
	return cidr_multicast_ipv4.test(ip) || validateIPv6CIDRByPattern(ip, ipv6CidrMulticastPattern);
}


/**
 * Normalizes an IPv6 address to compensate for the '::' shorthand.
 * The split will produce an empty string entry in the array, which removed. Then 0 entries are filled in at that position to meet the expected array length of a full address.
 * It also takes care of leading and trailing ::
 * @param ipv6Address IPv6 address string
 */
function getNormalizedIPv6Split(ipv6Address : string): string[] {
	// take care of leading and trailing ::
	const splitAddress: string[] = ipv6Address.replace(/^:|:$/g, '').split(':');

	const shortHandIndex: number = splitAddress.indexOf('');

	if (shortHandIndex < 0) {
		return splitAddress;
	}

	const numAdditional: number = IPV6_NUM_GROUPS - (splitAddress.length - 1);
	const additionalGroups: string[] = new Array(numAdditional).fill('0');
	const output = [...splitAddress]; // keeping it pure

	output.splice(shortHandIndex, 1, ...additionalGroups);

	return output;
}

function validateIPv6CIDRByPattern(ip: string, pattern: RegExp) : boolean {
	const match = ip.match(pattern);
	if (match) {
		const address = match[1];
		return address === address.trim() && validateIPv6(address);
	}
	return false;
}
