import { TranslateService } from '@ngx-translate/core';
import { SearchQueryBuilder } from 'rev-portal/search/SearchQueryBuilder';
import { isString } from 'rev-shared/util';

type IFilterFormatter = (value: any) => string;
type IFilterFactory = (t: TranslateService) => IFilterFormatter;

export type IFilterTag = {
	name: string;
	teamId: string;
};

interface IFilterConfig {
	searchField: string;
	queryProperty?: string;
	formatter?: IFilterFormatter;
	formatterFactory?: IFilterFactory;
	value?: any;
}

interface IFilterData {
	data: any;
	value: any;
}

//Base filter class. Matches exact value
export class Filter {
	protected data: any;
	protected exactMatch: boolean;
	protected searchField: string;
	public readonly queryProperty: string;
	public readonly formatter: IFilterFormatter;
	public readonly formatterFactory: IFilterFactory;
	protected _valueStack: any[];
	protected _blockUserUpdates: boolean;

	public value: any = this.getDefaultValue();

	/**
	 * cfg:
	 *	searchField, string: Name of the search field
	 *	queryProperty - if value is an object, use this property when building the query
	 *
	 *  formatter, function(value) - function that returns a display value
	 *
	 *  formatterFactory, function - injectable function that returns a formatter function.
	 *		filterTranslations can be injected, defined in search filter list template
	 */
	constructor(cfg: IFilterConfig) {
		Object.assign(this, {
			exactMatch: true,
			data: {}
		}, cfg);
	}

	//If true, the filter has a value that will be set on the search query
	public get hasValue(): boolean {
		return this.isValue(this.value);
	}

	public getValue(): any {
		return this.value;
	}

	//Filter has a value that was input by the user
	public get hasUserEnteredValue(): boolean {
		return this.hasValue && !this.hasValueOverride;
	}

	//Filter has a value that was set automatically. For example by the state configuration
	public get hasValueOverride(): boolean {
		return this._valueStack && !!this._valueStack.length;
	}

	//Value has been overridden, and the filter will not be displayed or editable
	public get blockUserUpdates(): boolean {
		return this._blockUserUpdates;
	}

	//Filter supports only single values. If false, value will be an array
	public get isSingleValue(): boolean {
		return true;
	}

	public clear(): void {
		if(!this.hasValueOverride || !this.blockUserUpdates){
			this.value = this.getDefaultValue();
			this._valueStack = null;
		}
	}

	public getDefaultValue(): any {
		return null;
	}

	public clone() {
		return Object.create(this);
	}

	public update(filterData: any): void {
		if(!this.hasValueOverride || !this.blockUserUpdates){
			this.clearValueOverride();
			Object.assign(this, {
				value: filterData
			});
		}
	}

	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		queryBuilder.value(this.searchField, this.getQueryValue());
	}

	//Used by state definitions to set state-specific filters
	//For example, on browse videos, categoryId on the browse videos state
	//Also on browse videos, override IsActive filter to true, but don't block user updates
	public overrideValue(value: any, blockUserUpdates?: boolean): void {
		this._blockUserUpdates = blockUserUpdates !== false;

		this._valueStack = this._valueStack || [];
		this._valueStack.push(this.value);
		this.value = value;
	}

	public clearValueOverride(): void {
		if(this._valueStack && this._valueStack.length){
			this.value = this._valueStack.pop();
		}

		this._blockUserUpdates = false;
	}

	public getQueryValue(): any {
		if(this.hasValue) {
			return this.value[this.queryProperty] || this.value;
		}
	}

	public isValue(value: any): boolean {
		return value != null && value !== '';
	}
}

//Used for matching text fields with a user-entered query
export class TextFilter extends Filter {
	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		queryBuilder.text(this.searchField, this.getQueryValue());
	}
}

//Allows matching any item in an array of possible values
export class MultiValueFilter extends Filter {
	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		if (this.hasValue) {
			queryBuilder.values(this.searchField, this.getQueryValue());
		}
	}

	public removeSingleValue(index: number): void {
		if (this.value.length){
			this.value = this.value.filter((_, i) => i !== index);
		}
	}

	public get isSingleValue(): boolean {
		return false;
	}

	public getQueryValue(): any {
		if (this.queryProperty && this.value) {
			return this.value.map(value => isString(value) ? value : value[this.queryProperty]);
		}

		return this.value;
	}

	public getValue(): void {
		return this.queryProperty ?
			this.value.map(value => isString(value) ? value : ({
				name: value.name,
				[this.queryProperty]: value[this.queryProperty]
			})) :
			this.value;
	}

	public getDefaultValue(): any {
		return [];
	}

	public isValue(value: any): boolean {
		return value?.length > 0;
	}
}

export class RangeFilter extends Filter {
	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		const value = this.value;

		if (value) {
			queryBuilder.range(this.searchField, value.from, value.to);
		}
	}

	public getDefaultValue(): any {
		return {};
	}

	public update(filterData: any): void {
		if(!this.hasValueOverride || !this.blockUserUpdates) {
			if(!filterData) {
				Object.assign(this, {
					value: this.getDefaultValue()
				});
			} else {
				this.clearValueOverride();
				if(filterData.from) {
					filterData.from = new Date(filterData.from);
				}
				if(filterData.to) {
					filterData.to = new Date(filterData.to);
				}
				Object.assign(this, {
					value: filterData
				});
			}
		}
	}

	public isValue(value: any): boolean {
		return value?.from || value?.to;
	}
}

export class DateRangeFilter extends RangeFilter {
	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		const value = this.value;

		if(value) {
			queryBuilder.dateRange(this.searchField, value.from, value.to, { useCalendarDates: true });
		}
	}
}

//Stub to allow search term to be added to filters list
export class SearchTerm extends Filter {
	constructor() {
		super({ searchField: null });
	}

	public addToQuery(queryBuilder: SearchQueryBuilder): void {
		//noop
	}
}
