import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FileUploadAngularModule } from 'rev-shared/ui/fileUpload/FileUpload.AngularModule';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { InsightAngularModule } from 'rev-shared/ui/insight/InsightAngular.Module';
import { InsightModule } from 'rev-shared/ui/insight/ngx/Insight.Module';
import { ManageTeamComponent } from './ManageTeam.Component';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TeamService } from './Team.Service';
import { ToolbarNgxModule } from 'rev-shared/ui/toolbar/ToolbarNgx.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbUiColorSelectorModule } from 'rev-shared/ui/color-selector/VbUiColorSelector.Module';

@NgModule({
	imports: [
		PushModule,
		SecurityAngularModule,
		FormGroupAngularModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		DialogAngularModule,
		DirectivesAngularModule,
		ValidationModule,
		InsightModule,
		InsightAngularModule,
		FileUploadAngularModule,
		ToolbarNgxModule,
		SpinnerAngularModule,
		UIRouterModule.forChild(),
		VbUiColorSelectorModule,
		ButtonsAngularModule,
		NgxTrimDirectiveModule,
		ValidationAngularModule
	],
	providers: [
		TeamService
	],
	declarations: [
		ManageTeamComponent
	],
	exports: [
		ManageTeamComponent
	]
})
export class SharedTeamAngularModule {
}
