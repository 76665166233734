import { Transition } from '@uirouter/angular';
import { ResolvableLiteral } from '@uirouter/angular';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';

import { ForgotPasswordComponent } from './ForgotPassword.Component';
import { LoginComponent, LoginResolve, LoginResolveNg2 } from './Login.Component';
import { SSOLogoutComponent, SSOLogoutNg2Resolve } from './SSOLogout.Component';
import { SSOLogoutResolve } from './SSOLogout.Component';
import { UserAgreementResolve, UserAgreementComponent, UserAgreementNg2Resolve } from './UserAgreement.Component';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';


export function getCommonSecurityStatesNg2(
	initializationResolve: ResolvableLiteral[],
	loginComponent: typeof LoginComponent = LoginComponent,
	userAgreementComponent: typeof UserAgreementComponent = UserAgreementComponent
): IVbNg2StateDeclaration[] {
	return [
		{
			name: 'login',
			component: loginComponent,
			url: '/login?fwdUrl&initiateLogin',
			params: {
				fwdUrl: {
					value: '',
					replace: [{ // prevents the empty string from being replaced with undefined
						from: '',
						to: ''
					}]
				},
				forceLogout: undefined,
				initiateLogin: { value: false, type: 'bool', squash: true }
			},
			resolve: [
				...initializationResolve,
				...LoginResolveNg2
			],
			allowAllAccess: true
		},

		{
			name: 'forgot-password',
			url: '/forgot-password',
			resolve: initializationResolve,
			component: ForgotPasswordComponent,
			authorizationKey: 'resetPassword',
			allowAllAccess: true
		},

		{
			name: 'user-agreement',
			url: '/user-agreement?fwdUrl&userId&ssoLogin',
			resolve: [
				...initializationResolve,
				...UserAgreementNg2Resolve
			],
			component: userAgreementComponent,
			allowAllAccess: true,
			params: {
				fwdUrl: { value: '', squash: true },
				userId: { value: '', squash: true },
				ssoLogin: { value: false, type: 'bool', squash: true }
			}
		},

		{
			name: 'logout',
			params: {
				fwdUrl: undefined,
				sessionEnded: undefined,
				forceLogout: undefined
			},
			resolve: initializationResolve,
			redirectTo(transition: Transition) {
				const loginRedirectService = transition.injector().get<LoginRedirectService>(LoginRedirectService);

				return loginRedirectService.getLogoutRedirect(transition.params());
			}
		},

		{
			name: 'sso-logout',
			url:'/sso-logout?sessionEnded&fwdUrl',
			params: {
				fwdUrl: {
					value: '',
					replace: [{ // prevents the empty string from being replaced with undefined
						from: '',
						to: ''
					}]
				}
			},
			resolve: [
				...initializationResolve,
				...SSOLogoutNg2Resolve
			],
			component: SSOLogoutComponent,
			allowAllAccess: true
		},

		//Empty state for <a> tags with no href.
		//Workaround for links defined with href=javascript:, Prevents CSP errors.
		//Do not use this. all <a> tags should have valid href or uiSref. If there is no href, use a <button> element instead.
		{
			name: 'void',
			url: '/void',
			allowAllAccess: true,
			onEnter() {
				return false;
			}
		}
	];
}
