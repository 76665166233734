import { Injectable } from '@angular/core';

import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoSourceType } from 'rev-shared/media/VideoSourceType';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RecordingService {

	constructor(
		private PushService: PushService,
		private UserContext: UserContextService,
		private http: HttpClient
	) {
		'ngInject';
	}

	public recordVideoConference(videoTitle: string, sipAddress: string, sipPin: string, bitrateKbps: number, microsoftTeamsMeetingUrl?: string, teamId?: string, dtmf?: string): Promise<{id; videoUploadUri}> {
		return this.PushService.dispatchCommand('media:StartVcRecording', {
			title: videoTitle,
			sipAddress,
			sipPin,
			bitrateKbps,
			microsoftTeamsMeetingUrl,
			teamId,
			dtmf
		}, 'VideoAddedToAccountForVcRecording')
			.then(({ message }) => (
				{
					id: message.videoId,
					videoUploadUri: message.videoUploadUri
				}
			));
	}

	public stopVideoConferenceRecording(video: any): Promise<void> {
		const route: string = video.sourceType === VideoSourceType.VIDEO_CONFERENCE
			? 'media:StopVcRecording'
			: 'media:StopWebexLiveStreamRecording';

		return this.PushService.dispatchCommand(route, {
			videoId: video.id
		});
	}

	public uploadVideoConferenceRecording(videoId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:UploadVcRecording', {
			videoId
		});
	}

	public reconnectVideoConferenceRecording(videoId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:ReconnectVc', {
			videoId
		});
	}

	public recentVideoConferenceCalls(): Observable<any> {
		const userId = this.UserContext.getUser().id;
		return this.http.get(`/media/users/${userId}/recent-vc-calls`);
	}
}
