<vb-loading-spinner [hidden]="!(status.loading)" [block]="true" [size]="'large'">
</vb-loading-spinner>

<tabset justified="true" *ngIf="!status.loading">
	<tab heading="{{ 'Uploads_UploadFiles' | translate }}" tabOrder="1" role="menuitem">
		<upload-file [features]="features" [accountId]="accountId" [teamId]="teamId" [maxFileUploadSizeBytes]="maxFileUploadSizeBytes" [chunkUploadSizeBytes]="chunkUploadSizeBytes" [viewingHoursUnavailable]="viewingHoursUnavailable"></upload-file>
	</tab>
	<tab *ngIf="isRecordingAllowed" heading="{{ 'Media_Recording_Tab_Header' | translate }}" tabOrder="2" role="menuitem">
		<recording-menu [teamId]="teamId" [webexTeamsEnabled]="features.enableSpark" [onPrem]="features.onPrem" [isWebrtcSinglePresenterDisabled]="features.webcastSources.webrtcSinglePresenterDisabled" [vciEnabled]="features.vciSettings.isEnabled" [vciMsTeamsEnabled]="features.vciSettings.isMSTeamsEnabled" [zoomEnabled]="features.zoomSettings.isEnabled" [zoomSipAddressSuffix]="features.zoomSettings.sipAddressSuffix" [pexipEnabled]="features.pexipSettings.isEnabled" [mediaFeatures]="features">
		</recording-menu>
	</tab>
	<tab *ngIf="features.enableAddUrls" heading="{{ 'AddUrls' | translate }}" tabOrder="3" role="menuitem" (selectTab)="loadPresentationProfiles()">
		<div class="container-fluid" [hidden]="!(status.error)">
			<div class="row">
				<div class="col-xs-12">
					<div class="card" style="padding:1px;">
						<div class="centered-copy">
							<div role="alert" class="alert alert-danger">
								<span class="glyphicons circle_exclamation_mark"></span>&nbsp;{{ 'UnhandledError' | translate }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<form #form="ngForm" novalidate (submit)="addVideoLink()">
			<div class="link-url-wrapper group">
				<vb-ui-form-group>
					<label for="createVideoUsing" #uiLabel>{{ 'CreateUsing' | translate }}</label>
					<vb-ui-select uiControl>
						<select id="createVideoUsing" class="form-select" #selectElement [(ngModel)]="videoLink.linkType" name="createVideoUsing">
							<option [value]="UploadUrlType.DIRECT_URL">
								{{ 'DirectUrl' | translate }}
							</option>
							<option [value]="UploadUrlType.PRESENTATION_PROFILE">
								{{ 'PresentationProfile' | translate }}
							</option>
						</select>
					</vb-ui-select>
				</vb-ui-form-group>
				<vb-ui-form-group *ngIf="videoLink.linkType === UploadUrlType.DIRECT_URL">
					<label for="uvWebAddress" #uiLabel>{{ 'WebAddress' | translate }}</label>
					<input type="text" id="uvWebAddress" name="uvWebAddress" #url="ngModel" placeholder="{{ 'LinkToUrl' | translate }}" vbUiTextInput required uiControl vbUiUrl class="form-control" [ngClass]="{'form-control-focused': focus}" [(ngModel)]="videoLink.directUrl.url">
					<div class="has-error control-label" role="alert" vbUiErrorMessage uiValidation>
						<span *ngIf="!form.valid && form.touched">
							{{ 'InvalidUrl' | translate }}
						</span>
					</div>
				</vb-ui-form-group>
				<vb-ui-form-group *ngIf="videoLink.linkType === UploadUrlType.PRESENTATION_PROFILE">
					<label for="uvPresentationProfile" #uiLabel>{{ 'PresentationProfile' | translate }}</label>
					<vb-ui-select uiControl>
						<select id="uvPresentationProfile" #selectElement class="form-select" required="required" [(ngModel)]="videoLink.presentationProfile" name="uvPresentationProfile">
							<option [ngValue]="option" *ngFor="let option of presentationProfiles">{{option.name}}</option>
						</select>
					</vb-ui-select>
				</vb-ui-form-group>
				<div class="flex-navigation-bar equal-spacing">
					<div [hidden]="!(videoLink.linkType === UploadUrlType.DIRECT_URL)">
						<vb-ui-form-group>
							<label id="uvVideoType" #uiLabel>{{ 'VideoType' | translate }}</label><br/>
							<div vbUiRadioBtnGroup aria-labelledby="uvVideoType" uiControl>
								<button type="button" name="type" [(ngModel)]="videoLink.directUrl.type" [vbUiRadioBtn]="DirectUrlType.LIVE">
									{{ 'Live' | translate }}
								</button>
								<button type="button" name="type" [(ngModel)]="videoLink.directUrl.type" [vbUiRadioBtn]="DirectUrlType.VOD">
									{{ 'VOD' | translate }}
								</button>
							</div>
						</vb-ui-form-group>
					</div>
					<div [hidden]="!(videoLink.linkType === UploadUrlType.DIRECT_URL)">
						<vb-ui-form-group>
							<label for="uvEncodingType" #uiLabel>{{ 'EncodingType' | translate }}</label>
							<vb-ui-select uiControl>
								<select id="uvEncodingType" class="form-select selectize-style" name="uvEncodingType" type="text" #selectElement [(ngModel)]="videoLink.directUrl.encodingType" required ng-options="encodingType for encodingType in EncodingTypes">
									<option *ngFor="let encodingType of EncodingTypes">{{ encodingType }}</option>
								</select>
							</vb-ui-select>
						</vb-ui-form-group>
					</div>
					<div class="fixed padding-left-0">
						<vb-ui-form-group>
							<label #uiLabel>&nbsp;</label><br/>
							<button type="submit" uiControl [disabled]="!form.valid" [hidden]="status.processing" class="btn-block btn-icon-left" vbBtnPrimary>
								<span class="glyphicons plus"></span>
								{{ 'Add' | translate }}
							</button>
							<button [disabled] uiControl [attr.aria-label]="'Processing' | translate " [hidden]="!(status.processing)" class="btn-block" vbBtnPrimary>
								<vb-loading-spinner></vb-loading-spinner>
							</button>
						</vb-ui-form-group>
					</div>
				</div>
			</div>
		</form>

		<!-- TODO: when scrolling lists throughout app, underlying page should NOT scroll -->
		<div class="scrollable-area-wrapper" [hidden]="!(videoLinks.length)">
			<ol class="list-unstyled scrollable-area">
				<li *ngFor="let video of videoLinks" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
				                'upload-error': video.status.error}">
					<div class="upload-file">
						<h4>
							<a uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" [hidden]="video.status.error" class="upload-name btn-icon-left">
								{{ video.title }}
							</a>
							<span [hidden]="!(video.status.error)" class="upload-name btn-icon-left">
								<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
								{{ video.title }}
							</span>
						</h4>

						<p class="upload-date" [hidden]="!(video.status.complete && !video.status.error)">
							{{ video.uploadDate | vbDateTimeMedium }}
							<span class="glyphicons circle_ok"></span>
						</p>

						<p class="error-msg" role="alert" [hidden]="!(video.status.error)">{{ 'UploadError' | translate }}</p>
					</div>
				</li>
			</ol>
		</div>
		<div class="menu-footer group">
			<button type="button" class="btn-transparent float-left text-color-inherit" [hidden]="!(videoLinks.length)" (click)="dismissAllVideoLinks()">
				{{ 'ClearCompleted' | translate }}
			</button>
			<a class="float-right text-color-inherit" *vbAuthorizationKey="'media.add'" uiSref="portal.media.uploads">
				{{ 'Uploads_SeeAllUploads' | translate }}
			</a>
			<div class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning" [hidden]="!(viewingHoursUnavailable)">
				{{ 'Uploads_ViewingHoursNotAvailable' | translate }}
			</div>
		</div>
	</tab>
	<tab heading="{{ 'Uploads_Import_Meetings' | translate }}" tabOrder="4" role="menuitem">
		<button type="button" class="btn-transparent upload-import-button" (click)="importWebEx()" [hidden]="!(isWebexManualImportEnabled)">
			<span class="upload-import-button-logo webex-logo"></span>
			{{ 'Uploads_Import_WebEx' | translate }}
		</button>

		<button *ngIf="isZoomManualImportEnabled" type="button" class="btn-transparent upload-import-button" (click)="importZoom()">
			<span class="upload-import-button-logo zoom-logo"></span>
			<span>{{ 'ZoomMeetings' | translate }}</span>
		</button>

		<!-- TODO: when scrolling lists throughout app, underlying page should NOT scroll -->
		<div class="scrollable-area-wrapper" [hidden]="!(videoImports().length)">
			<ol class="list-unstyled scrollable-area">
				<li *ngFor="let video of videoImports()" class="upload-file-wrapper" [ngClass]="{ 'upload-complete': video.status.complete,
				                'upload-error': video.status.error,
						'upload-loading': !video.status.complete && !video.status.error}">
					<div class="upload-file">
						<h4>
							<a [hidden]="!(!video.status.error && video.id)" uiSref="portal.video-settings" [uiParams]="{videoId: video.id}" class="upload-name btn-icon-left">
								{{ video.title }}
							</a>
							<span [hidden]="!(video.status.error)" class="upload-name btn-icon-left">
								<span class="glyphicons circle_exclamation_mark margin-right-3"></span>
								{{ video.title }}
							</span>
							<span [hidden]="!(!video.id && !video.status.error)">
								{{ video.title }} <vb-loading-spinner></vb-loading-spinner>
							</span>
						</h4>

						<p class="upload-date" [hidden]="!(video.status.complete && !video.status.error)">
							{{ video.uploadDate | vbDateTimeMedium }}
							<span class="glyphicons circle_ok"></span>
						</p>

						<p role="alert" class="error-msg" [hidden]="!(video.status.error)">{{ 'UploadError' | translate }}</p>
					</div>
				</li>
			</ol>
		</div>
		<div class="menu-footer group">
			<button type="button" class="btn-transparent float-left text-color-inherit" [hidden]="!(videoImports().length)" (click)="dismissAllImports()">
				{{ 'ClearCompleted' | translate }}
			</button>
			<a class="float-right text-color-inherit" *vbAuthorizationKey="'media.add'" uiSref="portal.media.uploads">
				{{ 'Uploads_SeeAllUploads' | translate }}
			</a>
			<div class="util-white-space-normal util-clear-both alert-danger upload-viewing-hours-warning" [hidden]="!(viewingHoursUnavailable)">
				{{ 'Uploads_ViewingHoursNotAvailable' | translate }}
			</div>
		</div>
	</tab>
</tabset>
