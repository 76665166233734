import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';
import { PipesAngularModule } from 'rev-shared/pipes/Pipes.AngularModule';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ToolbarNgxModule } from 'rev-shared/ui/toolbar/ToolbarNgx.Module';
import { VbGridListModule } from 'rev-shared/ui/gridList/VbGridList.Module';
import { VbNgSelectWrapModule } from 'rev-shared/ui/ng-select/VbNgSelectWrap.Module';

import { ImportWebexDialogComponent } from './ImportWebexDialog.Component';
import { ImportZoomDialogComponent } from './ImportZoomDialog.Component';
import { WebExService } from './WebEx.Service';
import { ZoomRecordingService } from './ZoomRecording.Service';

@NgModule({
	declarations: [
		ImportWebexDialogComponent,
		ImportZoomDialogComponent
	],
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		FormsModule,
		FormDirectivesAngularModule,
		NgSelectModule,
		PipesAngularModule,
		SpinnerAngularModule,
		TranslateModule,
		ToolbarNgxModule,
		VbGridListModule,
		VbNgSelectWrapModule,
		SelectAngularModule
	],
	providers: [
		WebExService,
		ZoomRecordingService
	]
})
export class VbrickImportModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('importWebexDialog', {
			component: ImportWebexDialogComponent,
			class: 'back-drop'
		});
		dialog.register('importZoomDialog', {
			component: ImportZoomDialogComponent,
			class: 'back-drop'
		});
	}
}
