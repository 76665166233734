import { Injectable, computed, signal } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';
import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';

import { PlaylistService } from './Playlist.Service';
import { IDynamicPlaylistSeachFilter, IPlaylist, IPlaylistData, PlaylistType } from './IPlaylist';
import { UNCATEGORIZED } from './MediaConstants';

@Injectable({
	providedIn: 'root'
})
export class PlaylistContextService {
	private _isEditMode = signal<boolean>(false);
	private _playlist = signal<IPlaylistData>({} as any);
	public playlist = this._playlist.asReadonly();
	public isDynamicPlaylistType = computed(() => this.playlist().playlistType === PlaylistType.Dynamic);
	public isEditMode = this._isEditMode.asReadonly();
	private sub: Subscription;

	constructor(
		private MediaState: MediaStateService,
		private PlaylistService: PlaylistService,
		private UserContext: UserContextService,
		private SecurityContext: SecurityContextService,
		private SearchFilterState: SearchFilterStateService,
		private TranslateService: TranslateService
	)
	{}

	public loadPlaylist(playlistId: string): Promise<void> {
		if(playlistId === 'featured') {
			return this.initFeatured();
		}

		return this.PlaylistService.getPlaylist(playlistId)
			.then(playlist => {
				const filterData = (playlist as any).additionalDetails;
				(playlist as any).additionalDetails = undefined;
				this._playlist.set(playlist);
				if(playlist.playlistType === PlaylistType.Dynamic) {
					this.startDynamic(filterData);
				}
			});
	}

	private startDynamic(filterData: any): void {
		this.MediaState.setIsSortingDisabled(true);

		[filterData.owners, filterData.uploaders, filterData.userTags]
			//Prevent insight from reloading the same data
			.forEach(users => users.forEach(user => user._loaded = true));
		filterData.categories.forEach(c => {
			if(c.categoryId === UNCATEGORIZED) {
				c.name = this.TranslateService.instant('Media_Uncategorized');
			}
		});

		this.SearchFilterState.initialize(true);
		this.SearchFilterState.update({
			...this.playlist().searchFilter,
			ownerUserId: filterData.owners,
			uploaderUserId: filterData.uploaders,
			userTags: filterData.userTags,
			categoryIds: filterData.categories,
			teamIds: filterData.channels,
		});

		this.sub = this.SearchFilterState.change$.pipe(
			switchMap(() => {
				const playlistDetails = {
					...this.playlist().searchFilter,
					...this.SearchFilterState.getQueryValues()
				} as any;
				return this.modifyDynamicPlaylist(playlistDetails);
			})
		)
			.subscribe({
				error: err => console.error('PlaylistContext SearchFilterState Error', err)
			});
	}

	private initFeatured(): Promise<void> {
		return this.PlaylistService.getFeaturedVideos(this.UserContext.getAccount().id)
			.then((playlist: IPlaylist) => this._playlist.set({
				...playlist,
				id: 'featured',
				playlistType: PlaylistType.Static,
				isFeatured: true,
				canEdit: this.SecurityContext.checkAuthorization('media.featuredPlaylist')
			} as any));
	}

	public reset(): void {
		this._isEditMode.set(false);
		this._playlist.set({} as any);
		this.sub?.unsubscribe();
		this.sub = null;
		this.SearchFilterState.initialize();
		this.MediaState.setIsViewModeDisabled(false);
		this.MediaState.setIsSortingDisabled(false);
	}

	public setEditMode(editMode: boolean) {
		this.MediaState.setIsViewModeDisabled(editMode);
		if(editMode) {
			this.MediaState.setViewMode(MediaViewMode.TABLE);
		}
		this._isEditMode.set(editMode);
	}

	public modifyDynamicPlaylist(searchFilter: IDynamicPlaylistSeachFilter): Promise<any> {
		const accountId = this.UserContext.getAccount().id;
		return this.modifyPlaylist({ searchFilter: searchFilter })
			.then(() => this.PlaylistService.getDynamicPlaylistVideos( accountId, this.playlist().searchFilter))
			.then(videos => {
				const playlist = this.playlist();
				this._playlist.set({
					...playlist,
					videos
				});
				this.MediaState.searchResultsState.mediaCount = videos.length;
			});
	}

	public modifyPlaylist(data: Partial<IPlaylistData>): Promise<any> {
		const playlist = {
			...this._playlist(),
			...data
		};

		if(data.searchFilter) {
			playlist.searchFilter = {
				...playlist.searchFilter,
				...data.searchFilter
			};
		}

		return this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name: playlist.name,
			videoIds: playlist.playlistType === PlaylistType.Static
				? playlist.videos.map(video => video.id)
				: [],
			searchFilter: playlist.searchFilter
		})
			.then(() => this._playlist.set(playlist));
	}

}
