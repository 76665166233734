export enum SameSite {
	None = 'None',
	Lax = 'Lax',
	Strict = 'Strict'
}

export interface ISetCookieOptions {
	expires?: Date;
	path?: string;
	sameSite?: SameSite;
	partitioned?: boolean;
}

export const isHttps: boolean = location.protocol === 'https:';

export function getCookie(cookie: string): string {
	const valueMatch = new RegExp(`${cookie}=([^;]+)`).exec(document.cookie);
	return valueMatch ? decodeURIComponent(valueMatch[1]) : null;
}

// return true only if the the cookie value has the string 'true' otherwise false
export function getCookieBoolean(cookie: string): boolean {
	return getCookie(cookie) === 'true';
}

export function setCookie(cookie: string, value: string, options?: ISetCookieOptions): void {
	value = encodeURIComponent(value);
	let cookieString = cookie + '=' + value;

	options = options || {};
	cookieString += ';path=' + (options.path ? options.path : '/');
	cookieString += options.expires ? ';expires=' + options.expires.toUTCString() : '';
	cookieString += (options.sameSite && (isHttps || options.sameSite !== SameSite.None)) ? `;SameSite=${options.sameSite}` : '';
	cookieString += isHttps ? ';secure' : '';
	cookieString += isHttps && (options.partitioned !== false) ? ';partitioned' : '';
	document.cookie = cookieString;
}

export function unsetCookie(cookie: string): void {
	const expires = new Date();
	expires.setTime(expires.getTime() - 1000);

	setCookie(cookie, '', { expires });

	if(isHttps) {
		setCookie(cookie, '', { expires, partitioned: false });
	}
}
