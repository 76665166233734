<div [ngClass]="[
		styles.loadingContainer,
		block ? 'loading-container ' + size : 'box-inline',
		customStylesClass ? customStylesClass : ''
	]">

	<ng-container *ngIf="!msgDisplayBelowSpinner && !msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg"><ng-content></ng-content></div>
	</ng-container>

	<ng-container *ngIf="!msgDisplayBelowSpinner && msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg">{{ msg }}</div>
	</ng-container>


	<span [ngClass]="styles.iconWrap">
		<span class="theme-accent-icon" [ngClass]="iconNgClass">
		</span>
		<vb-ui-spinner [size]="size" [state]="state"></vb-ui-spinner>
	</span>

	<ng-container *ngIf="msgDisplayBelowSpinner && !msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg"><ng-content></ng-content></div>
	</ng-container>

	<ng-container *ngIf="msgDisplayBelowSpinner && msg && block">
		<div class="status-msg vb-loading-spinner-status-msg" [ngClass]="styles.msg">
			{{ msg }}
		</div>
	</ng-container>
</div>
