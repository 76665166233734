
import {
	Component,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SelectionModel } from '@angular/cdk/collections';

import { compare, orderBy } from 'rev-shared/util/SortUtil';
import { TranscriptionLanguagesService, ILanguageCode } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';

import { ZoomRecordingService } from './ZoomRecording.Service';

import styles from './ImportZoom.module.less';
import './import-zoom-dialog.less';

@Component({
	selector: 'import-zoom-dialog',
	templateUrl: './ImportZoomDialog.Component.html'
})
export class ImportZoomDialogComponent implements OnInit {
	public isLoading: boolean;
	public errorGeneric: boolean;
	public errorEmailNotFound: boolean;
	public selectionModel: SelectionModel<any>;
	public transcriptionLanguageCodes: ILanguageCode[] = [];
	public zoomRecordings: any[];

	public readonly styles = styles;

	public isSelectAll: boolean;

	constructor(
		private modalInstance: BsModalRef,
		private ZoomRecordingService: ZoomRecordingService,
		private readonly TranscriptionLanguagesService: TranscriptionLanguagesService
	) {
	}

	public ngOnInit(): void {
		this.isLoading = false;
		this.errorGeneric = false;
		this.errorEmailNotFound = false;
		this.selectionModel = new SelectionModel(
			true //enable multi select
		);

		this.TranscriptionLanguagesService.loadAiServiceLanguages().then(result => {
			this.transcriptionLanguageCodes = this.TranscriptionLanguagesService.aiServiceLanguages;
		});
		this.loadRecordings();
	}

	public onRowSelectionChange(recording: any): void {
		this.selectionModel.toggle(recording);
		this.isSelectAll = this.selectedZoomImports.length === this.zoomRecordings.length;
	}

	public getImportedColumnIconClass(item: { isImported: boolean }): string {
		return item.isImported ? 'glyphicons circle_ok' : '';
	}

	public import(): void {
		//loading state to prevent dupes
		this.isLoading = true;

		//import the selected recordings
		this.selectedZoomImports.forEach(selectedImport =>
			this.ZoomRecordingService.importRecording(selectedImport.id, selectedImport.recordingName, selectedImport.recordingFileUrl,
				selectedImport.fileType, selectedImport.vttFileUrl, selectedImport.vttLanguageCode)
		);

		//close the modal
		this.close();
	}

	public loadRecordings(): void {
		this.isLoading = true;
		this.errorGeneric = false;
		this.errorEmailNotFound = false;

		this.ZoomRecordingService.getRecordings()
			.then(result => {
				this.zoomRecordings = result ? orderBy(result, item => item.creationDate, false) : [];
			}).catch(error => {
				if (error.error.code === 'EmailNotFound') {
					this.errorEmailNotFound = true;
				}
				this.errorGeneric = true;
			}).finally(() => {
				this.isLoading = false;
			});
	}

	public syncSelectionHeaderModel(): void {
		window.setTimeout(() => {
			if(this.zoomRecordings && this.zoomRecordings.length > 0) {
				this.toggleSelectAll();
			}
		});
	}

	public toggleSelectAll(): void {
		(this.zoomRecordings || []).forEach(item => {
			if (!item.isImported) {
				item.selected = this.isSelectAll;
				item.selected ? this.selectionModel.select(item) : this.selectionModel.deselect(item);
			}
		});
	}

	public close(): void {
		this.modalInstance.hide();
	}

	public get selectedZoomImports(): any[] {
		return this.selectionModel?.selected ?? [];
	}

	public trackByIndex(index: number): number {
		return index;
	}
}
