import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';

/**
 * Handles press and hold user interaction. Either mouse/touch, or keyboard space and enter keys.
 */
@Directive({
	selector: '[vbPushHold]',
	host: {
		'(pointerdown)': 'startMouse($event)',
		'(keydown)': 'startKb($event)'
	}
})
export class VbPushHoldDirective implements OnDestroy {
	@Output() public onStart = new EventEmitter();
	@Output() public onStop = new EventEmitter();

	private stop: () => void;
	private stopKb: (e?: KeyboardEvent) => void;

	public ngOnDestroy(): void {
		document.body.removeEventListener('pointerup', this.stop);
		document.body.removeEventListener('keyup', this.stopKb);
		this.stop?.();
	}

	public startMouse(e: PointerEvent) {
		if(!e.button) {
			this.start();
		}
	}

	public startKb(e: KeyboardEvent) {
		if(!e.repeat &&
			(e.code === 'Enter' || e.code === 'Space')) {
			this.start();
		}
	}

	public start(): void {
		let stopped = false;
		if(this.stop) {
			console.log('VbPushHoldDirective cannot start');
			return;
		}

		this.stop = () => {
			document.body.removeEventListener('pointerup', this.stop);
			document.body.removeEventListener('keyup', this.stopKb);
			this.stop = null;
			this.stopKb = null;
			stopped = true;
			this.onStop.emit();
		};

		this.stopKb = (e: KeyboardEvent) => {
			if(e.code === 'Enter' || e.code === 'Space') {
				this.stop();
			}
		};

		//listen on body in case mouse is moved off the original button
		document.body.addEventListener('pointerup', this.stop);
		document.body.addEventListener('keyup', this.stopKb);

		this.onStart.emit();
	}
}
