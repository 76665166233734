const IOS_VERSION_REGEX = /OS (\d\d?_\d(_\d)?)/;
const userAgent = window.navigator.userAgent;

export function isAndroidChrome(): boolean {
	return !!userAgent.match(/Android.*Chrome/);
}

export function getIosVersion(): number {
	const versionResult = IOS_VERSION_REGEX.exec(userAgent);

	if (!isIosSafari() || !versionResult) {
		return -1;
	}

	const [major, minor] = versionResult[1].split('_');

	return +`${major}.${minor}`;
}

export function isChrome(): boolean {
	return userAgent.includes('Chrome') &&
		!isEdge();
}

export function isChromium(): boolean {
	return isChrome() ||
		isEdge();
}

export function isEdge(): boolean { // Chromium Edge
	return userAgent.includes('Edg/');
}

export function isLegacyEdge(): boolean {
	return userAgent.includes('Edge');
}

export function isMobileSafariWithDialogBlocking(): boolean {
	return getIosVersion() >= 9.3;
}

export function isIe(): boolean {
	return userAgent.includes('Trident');
}

export function isIeOrLegacyEdge(): boolean {
	return isIe() || isLegacyEdge();
}

export function isIosSafari(): boolean {
	return /(iPad|iPhone)/.test(userAgent) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); //iPad 13 and up
}

export function isMobile(): boolean {
	return isAndroidChrome() || isIosSafari() || isMobileSafari() || isServiceNowMobileApp();
}

export function isMobileSafari(): boolean {
	//can not use isIosSafari method above as it is failing for
	//ipad on iOS 14. visit later.
	return typeof window.orientation === 'number' //not new. got this from player
		&& isSafari();
}

export function isSafari(): boolean {
	return !isChromium() &&
		userAgent.includes('Safari');
}

export function isDesktop(): boolean {
	return !isMobile();
}

export function isApple(): boolean {
	return (/Mac OS X/i).test(userAgent) ||
		isIosSafari();
}

export function isZoomDesktop(): boolean {
	return (/ZoomWebKit/i).test(userAgent);
}

//just temp fix. need to think on how are we planning to handle third party user agent??
export function isServiceNowMobileApp(): boolean {
	return userAgent.includes('SnMobile');
}
