import { BootstrapContext } from '../bootstrap/BootstrapContext';

export function getPreferredTranscribeLanguage(allTranscriptionLanguages: [{ name: string, code: string }]): { code: string, name: string } | undefined {
	const systemLanguages = BootstrapContext.systemLanguages;

	const findTranscriptionLanguage = (code: string) => allTranscriptionLanguages.find(lang => lang.code === code);

	if (!systemLanguages) {
		return;
	}

	return findTranscriptionLanguage(systemLanguages.transcriptionDefault)
		|| findTranscriptionLanguage(systemLanguages.user)
		|| findTranscriptionLanguage(systemLanguages.account)
		|| findTranscriptionLanguage(systemLanguages.headers?.[0])
		|| findTranscriptionLanguage(systemLanguages._default)
		|| findTranscriptionLanguage('en'); // Fallback to English
}
