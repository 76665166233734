import {
	Directive,
	Input,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, ControlValueAccessor } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbUiUrl]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbUrlDirective),
		multi: true
	}]
})
export class VbUrlDirective {
	@Input() public vbUrl: string[]; // optional array of valid schemes for the url. If not present, any scheme is allowed.
	@Input() public bypassIpV6: boolean; // optional flag to bypass IPV6 validation

	public validate(control: AbstractControl): ValidationErrors {
		return (!control?.value || ValidationRules.checkUrl(control.value, this.vbUrl, this.bypassIpV6)) ?
			null :
			{ vbUrl: true };
	}
}
