import { NgZone } from '@angular/core';
import type { IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { throttle } from 'underscore';

import { noop } from 'rev-shared/util';

import { IGetInfiniteScrollRows } from './IGetInfiniteScrollRows';
import { IVbUiInfiniteScrollGridDataSourceParams } from './IVbUiInfiniteScrollGridDataSourceParams';
import { AgEvent, GridApi } from '@ag-grid-community/core';

import { getGridFilterDetails } from '../DataGridUtil';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export const INFINITE_GRID_BATCH_LOAD_COMPLETE = 'infiniteGridBatchLoadComplete';
export interface InfiniteGridBatchLoadCompleteEvent extends AgEvent {
	batchStartIndex: number;
	batchLastIndex: number;
	totalRows: number;
}

export class VbUiInfiniteScrollGridDataSource implements IDatasource {
	public currentPage: number = 0;
	public scrollId: string;
	public previousScrollId: string;
	public scrollExpired: boolean;
	private noRecordSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public noRecord$: Observable<boolean> = this.noRecordSubject$.asObservable();

	private throttledDispatchLoadEvent = throttle((batchStartIndex: number, batchLastIndex: number, totalRows: number) => this.dispatchLoadCompleteEvent(batchStartIndex, batchLastIndex, totalRows), 500);

	constructor(
		protected getInfiniteScrollRows: IGetInfiniteScrollRows,
		protected isSortable: boolean,
		private zone: NgZone,
		private api: GridApi,
		private dataFetchContext?: any
	) {}

	public getRows(params: IGetRowsParams): void {
		const pageSize: number = params.endRow - params.startRow;
		const [sortModel] = params.sortModel;

		if (!params.startRow) {
			this.reset();
		}

		this.scrollExpired = false;
		const infiniteScrollParams = this.buildParams(params, pageSize, sortModel);

		this.getInfiniteScrollRows(infiniteScrollParams)
			.then(result => {
				++this.currentPage;
				this.scrollId = result.scrollId;
				this.previousScrollId = null; //scroll must be deleted by api when passed

				const { data, totalRows } = result;
				this.setNoRecords(totalRows);
				const lastRow: number = (this.currentPage - 1) * pageSize + data.length < totalRows ?
					-1 :
					totalRows;

				params.successCallback(data, lastRow);

				//lastRow = -1 then getDisplayedRowCount returns one count extra. not sure why..
				const batchLastIndex = lastRow === totalRows ? this.api.getDisplayedRowCount() : this.api.getDisplayedRowCount() - 1;
				this.throttledDispatchLoadEvent(params.startRow, batchLastIndex, totalRows);
			}, e => {
				if(e?.status === 404) {
					this.scrollExpired = true;
				}

				params.failCallback();
			})
			.finally(() => this.zone.run(noop));
	}

	private dispatchLoadCompleteEvent(batchStartIndex: number, batchLastIndex: number, totalRows: number): void {
		this.api.dispatchEvent({
			type: INFINITE_GRID_BATCH_LOAD_COMPLETE,
			batchStartIndex,
			batchLastIndex,
			totalRows
		} as InfiniteGridBatchLoadCompleteEvent);
	}

	public resetScrollId(): void {
		this.previousScrollId = this.scrollId;
		this.scrollId = null;
	}

	public setDataFetchContext(context: any): void {
		this.dataFetchContext = context;
	}

	protected buildParams(params: IGetRowsParams, pageSize: number, sortModel: any): IVbUiInfiniteScrollGridDataSourceParams {
		const { filterModel } = params;
		const filterDetails = getGridFilterDetails(filterModel);

		return {
			...params,
			dataFetchContext: this.dataFetchContext,
			filterFields: filterDetails?.filterFields,
			isSortAscending: !sortModel || sortModel.sort === 'asc',
			pageSize,
			query: filterDetails?.query,
			scrollId: this.scrollId,
			previousScrollId: this.previousScrollId,
			sortField: sortModel?.colId
		};
	}

	protected reset(): void {
		this.currentPage = 0;
		this.scrollId = undefined;
	}

	private setNoRecords(totalCount: number): void {
		this.noRecordSubject$.next(totalCount <= 0);
	}
}
