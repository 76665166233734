import { Injectable } from '@angular/core';

import { LicenseService } from './License.Service';
import { IAccountLicense } from './IAccountLicense';
import { UserContextService } from './UserContext.Service';
import { LicenseType } from './LicenseType';

@Injectable({
	providedIn: 'root'
})
export class AccountLicenseService implements IAccountLicense {
	private accountId: string;

	public licenseType: LicenseType;
	public isEnabled: boolean;
	public aiCreditsValid: boolean;
	public mediaViewingAllowed: boolean;
	public viewingHoursValid: boolean;

	public isLicenseTypeByHours: boolean;
	public isLicenseTypeByUsers: boolean;
	public isLicenseTypeNamedOrActive: boolean;

	private accountLicenseFetchPromise: Promise<any> | null = null;

	constructor(
		private LicenseService: LicenseService,
		UserContext: UserContextService
	) {
		this.accountId = UserContext.getAccount().id;
	}

	public reload(): Promise<IAccountLicense> {
		this.accountLicenseFetchPromise = this.LicenseService.getLicense(this.accountId)
			.then((license: IAccountLicense) => Object.assign(this, license));

		return this.accountLicenseFetchPromise;
	}

	public loadAccountLicense(): Promise<IAccountLicense> {
		if (this.accountLicenseFetchPromise != null) {
			return this.accountLicenseFetchPromise;
		}

		return this.reload();
	}

	public updateLicenseHoursValid(): Promise<void> {
		return this.LicenseService.fetchLicenseHoursValidity(this.accountId)
			.then(result => {
				this.viewingHoursValid = result.viewingHoursValid;
				this.aiCreditsValid = result.aiCreditsValid;
			});
	}
}
