const urlRegexp = /([a-z]+):\/\/([^:]*)(:(\d+))?(\/.*)/;

export function parseQueryString(str: string): any {
	const result = {};

	if(str) {
		const pairs = str.split(/&/);

		pairs.forEach(rawPair => {
			const pair = rawPair
				.split(/=/)
				.map(decodeURIComponent);

			result[pair[0]] = pair[1] === undefined ? true : pair[1];
		});
	}

	return result;
}

export function getQueryParamsFromUrl(url: string): any {
	const urlObj = new URL(url);
	return new URLSearchParams(urlObj.search);
}

export function removeQueryParamsFromUrl(url: string, params: string[]): string {
	let newUrl = url;
	params.forEach(param => {
		const regex = new RegExp(`[?&]${param}=[^&]*`, 'g');
		newUrl = newUrl.replace(regex, '');
	});
	return newUrl;
}

export function parseUrl(url: string): { scheme, host, port, path } {
	const parts = url.match(urlRegexp);

	if(!parts){
		return null;
	}

	const [, scheme, host, , port, path] = parts;

	return { scheme, host, port, path };
}

export function buildUrl(scheme: string, host: string, port: string, path: string): string {
	return `${scheme}://${host}:${port}${path}`;
}

export function extractQueryParamsFromHash(): any {
	const hash = window.location.hash;
	const queryIndex = hash?.indexOf('?');

	const query = queryIndex >= 0 ? hash?.substring(queryIndex + 1, hash?.length) : undefined;
	return parseQueryString(query);
}

export function getDomainWithProtocol(url: string): string {
	const urlObj = new URL(url);
	return `${urlObj.protocol}//${urlObj.hostname}`;
}
