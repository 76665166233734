import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { isString } from 'rev-shared/util';
import { Deferred } from 'rev-shared/util/Deferred';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

import { UserAuthenticationService } from './UserAuthentication.Service';

@Injectable({
	providedIn: 'root'
})
export class SecurityContextService {
	private authorizationKeys: { [key: string]: true } = {};
	private initializationDeferred = new Deferred<SecurityContextService>();
	public initializationPromise = this.initializationDeferred.promise; // Promise that resolves when the service has been initialized.
	public $promise: Promise<any> = this.initializationDeferred.promise; // Tracks state when data is reloaded.

	constructor(
		private $state: StateService,
		private UserAuthenticationService: UserAuthenticationService
	) { }

	public allowStateChange(state: IVbNg2StateDeclaration | string ): Promise<boolean> {
		const authKey: string = this.getAuthorizationKey(state);

		if (authKey) {
			return Promise.resolve(this.$promise)
				.then(() => this.checkAuthorization(authKey));
		}

		return Promise.resolve(true);
	}

	/**
	 * If state has an authorization key, checks if user is allowed to enter the state.
	 * Does not check parent states
	 */
	public allowStateChangeSync(state: IVbNg2StateDeclaration | string): boolean {
		const authKey: string = this.getAuthorizationKey(state);

		if (authKey) {
			return this.checkAuthorization(authKey);
		}

		return true;
	}

	public checkAuthorization(authorizationKey: string): boolean {
		return !!this.authorizationKeys[authorizationKey];
	}

	public checkAuthorizations(authorizationKeys: string[]): boolean {
		return authorizationKeys.some(key => this.checkAuthorization(key));
	}

	public hasAuthorizationKey(fn: (k: string) => boolean): boolean {
		return Object.keys(this.authorizationKeys).some(fn);
	}

	public getFirstAllowedStateChange(states: string[]): string {
		states = states || [];

		if (this.initializationDeferred) {
			console.error('Security Context not initiallized, cannot redirect');

			return null;
		}

		return states.find((state: string) => {
			const authKey: string = this.getAuthorizationKey(state);

			return !authKey || this.checkAuthorization(authKey);
		});
	}

	public clearAuthorization(): void {
		this.authorizationKeys = {};
	}

	public reloadAuthorization(): Promise<any> {
		this.authorizationKeys = {};

		this.$promise = this.UserAuthenticationService.getAuthorization()
			.then((keys: string[]) => {
				(keys || []).forEach((key: string) => this.authorizationKeys[key] = true);

				if (this.initializationDeferred) {
					this.initializationDeferred.resolve(this);
					this.initializationDeferred = null;
				}
			}).catch(err => {
				if (this.initializationDeferred) {
					this.initializationDeferred.reject(err);
					this.initializationDeferred = null;
				}
			});

		return this.$promise;
	}

	private getAuthorizationKey(stateDeclaration: IVbNg2StateDeclaration | string): string {
		const stateName: string = isString(stateDeclaration) ? stateDeclaration : stateDeclaration.name;
		const state: IVbNg2StateDeclaration = this.$state.get(stateName);

		if (!state) {
			throw new Error(`State not found: ${stateName}`);
		}

		return state.authorizationKey;
	}

}
