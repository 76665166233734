import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

import styles from './vb-ui-radio-btn-group.module.less';
import secondaryBtnStyles from './vb-btn-secondary.module.less';

// The idea behind this component is essentially our radio button component that allows multiple selections
// This component expects an ngModel in the form of
// {
// 	key1: boolean,
// 	key2: boolean,
// 	key3: boolean,
// 	...
// }

// labelMap exists to format the keys of ngModel (what shows up on the button)
// {
// 	key1: 'THIS IS THE LABEL FOR KEY 1',
// 	key2: 'THIS IS THE LABEL FOR KEY 2',
// 	key3: 'THIS IS THE LABEL FOR KEY 3',
// 	...
// }

@Component({
	selector: 'vb-ui-multi-select-btn',
	templateUrl: './VbUiMultiSelectBtn.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbUiMultiSelectBtnComponent),
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbUiMultiSelectBtnComponent),
		multi: true
	}],
})
export class VbUiMultiSelectBtnComponent extends BaseControlValueAccessor<{[key: string]: boolean}> implements OnInit, Validator {
	@Input() public labelMap: {[key: string]: any};
	@Input() public disabled: boolean;
	@Input() public requireOneValue: boolean;

	public readonly styles = { ...styles, ...secondaryBtnStyles };

	public modelKeys: string[];

	public ngOnInit(): void {
		this.modelKeys = Object.keys(this.labelMap);
		this.setDisabledState(this.disabled);
	}

	public ngOnChanges(changes): void {
		if (changes.disabled) {
			this.setDisabledState(this.disabled);
		}
	}

	public onClick(key: string): void {
		this.value[key] = !this.value[key];
		this.writeValue(this.value);
		this.onChangeFn(this.value);
		this.onTouchFn();
	}

	public validate(): ValidationErrors {
		return !this.value ||
			Object.values(this.value).some(v => v === true) ||
			!this.requireOneValue ? null : { requireOneValue: true };
	}

}
