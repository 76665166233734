import { Directive, Input, ElementRef, HostListener, forwardRef, Renderer2 } from '@angular/core';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { formatTimespanShort } from 'rev-shared/date/DateFormatters';
import { ControlValueAccessor, FormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import {
	validateIPRange,
	validateIP,
	validateIPRangeMulticast,
	validateIPMulticast,
	IPRange,
	validateCIDR,
	validateMulticastCIDR
} from 'rev-shared/util/IPAddressValidation';

export type IpInputModelValue = IPRange[];
export type IpInputGroupingModelValue = IpInputModelValue[];

const GROUP_IP_VALUE_SEPARATOR: string = ';';
const IP_RANGE_SEPARATOR: string = '-';
const NEW_LINE: string = '\n';
const CIDR_CHARACTER = '/';

@Directive({
	selector: '[vbBulkIpInput]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbBulkIpInputDirective),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbBulkIpInputDirective),
			multi: true,
		}
	],
})
export class VbBulkIpInputDirective implements ControlValueAccessor, Validator {
	@Input() public ipGroupings: boolean;
	@Input() public isMulticast: boolean;

	private nativeElement: HTMLInputElement;
	private onChange: (value: IpInputModelValue | IpInputGroupingModelValue) => void;
	public onTouched: () => void;

	constructor(
		Element: ElementRef,
		private renderer : Renderer2
	) {
		this.nativeElement = Element.nativeElement;
	}

	@HostListener('input', ['$event.target.value'])
	public input(value: string): void {
		this.onTouched();
		this.onChange(this.parse(value));
	}

	@HostListener('blur')
	public onBlur(): void {
		this.onTouched();
	}

	public writeValue(value : IpInputModelValue | IpInputGroupingModelValue) : void {
		this.renderer.setProperty(this.nativeElement, 'value', this.format(value));
	}

	public registerOnChange( fn : any ) : void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public validate({ value }: FormControl): ValidationErrors {
		return !value || this.validateModel(value) ? null : { invalidIPAddresses: true };
	}

	private format(modelValue: IpInputModelValue | IpInputGroupingModelValue): string {
		if (!this.validateModel(modelValue)) {
			return '';
		}

		return this.ipGroupings ?
			this.formatGroupings(modelValue as IpInputGroupingModelValue) :
			this.formatSingleInputs(modelValue as IpInputModelValue);
	}

	private formatGroupings(modelValue: IpInputGroupingModelValue): string {
		return (modelValue || [])
			.map(grouping => this.formatSingleInputs(grouping, GROUP_IP_VALUE_SEPARATOR))
			.join(NEW_LINE);
	}

	private formatSingleInputs(modelValue: IpInputModelValue, join: string = NEW_LINE): string {
		return (modelValue || [])
			.map(entry => entry.cidr ? entry.cidr
				: entry.end ? entry.start + IP_RANGE_SEPARATOR + entry.end
				: entry.start
			)
			.join(join);
	}

	private parse(textInput: string): IpInputModelValue | IpInputGroupingModelValue {
		const lines = (textInput || '')
			.split(NEW_LINE)
			.map(line => line.trim())
			.filter(line => !!line);

		const result = this.ipGroupings ?
			this.parseGroupings(lines) :
			this.parseSingleInputs(lines);

		if (!result.length) {
			//returing empty array to avoid parse error.
			//parse error making form invalid
			return [];
		}

		return result;
	}

	private parseGroupings(lines: string[]): IpInputGroupingModelValue {
		return lines
			.map(line => line.split(GROUP_IP_VALUE_SEPARATOR))
			.map(grouping => this.parseSingleInputs(grouping));
	}

	private parseSingleInputs(inputs: string[]): IpInputModelValue {
		return inputs
			.map(line => {
				if(line?.includes(CIDR_CHARACTER)) {
					return {
						start: null,
						end: null,
						cidr: line.trim()
					};
				}

				const [start, end] = line.split(IP_RANGE_SEPARATOR);
				return start ?
					{
						start: start.trim(),
						end: end?.trim()
					} : null;

			});
	}

	private validateModel(modelValue: IpInputModelValue | IpInputGroupingModelValue): boolean {
		if (!modelValue) {
			return false;
		}

		const groupings: IpInputGroupingModelValue = this.ipGroupings ?
			modelValue as IpInputGroupingModelValue :
			[modelValue as IpInputModelValue];

		return groupings
			.every(grouping => this.validateSingleInputs(grouping));
	}

	private validateSingleInputs(inputs: IpInputModelValue): boolean {
		return inputs
			.every(entry => {
				if (!entry || !entry.start && !entry.cidr) {
					return false;
				}

				return this.isMulticast ? this.validateMulticastIP(entry) : this.validateIP(entry);
			});
	}

	private validateIP(range: IPRange): boolean {
		return range.cidr ? validateCIDR(range.cidr)
			: this.validateNonCidrIp(range);
	}

	private validateNonCidrIp(range: IPRange): boolean {
		return range.end ? validateIPRange(range) : validateIP(range.start);
	}

	private validateMulticastIP(range: IPRange): boolean {
		return range.cidr ? validateMulticastCIDR(range.cidr) : this.validateNonCidrMulticastIp(range);

	}

	private validateNonCidrMulticastIp(range: IPRange): boolean {
		return range.end ? validateIPRangeMulticast(range) : validateIPMulticast(range.start);
	}
}
