import { Directive, Input, OnChanges, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbMin]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMinDirective),
			multi: true
		}
	]
})
export class VbMinDirective implements Validator, OnChanges {
	@Input() public vbMin: any;
	private control: AbstractControl;

	public ngOnChanges(): void {
		this.control?.updateValueAndValidity();
	}

	public validate(control: AbstractControl): ValidationErrors {
		if(!this.control && !(control as any).dummy) {
			this.control = control;
		}

		const value = control.value;
		if (this.vbMin == null || value == null || value === '') {
			return null;
		}
		return value < this.vbMin ? { vbMin: true } : null;
	}
}
