import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';

import { VbDropdownMenuComponent } from './VbDropdownMenu.Component';
import { VbDropdownDirective } from './VbDropdown.Directive';

const components = [
	VbDropdownMenuComponent,
	VbDropdownDirective
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule
	]
})
export class VbDropdownMenuModule {}
