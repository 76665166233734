import { InjectionToken, Injectable, Inject } from '@angular/core';
import { Dictionary } from 'underscore';

import { Deferred } from 'rev-shared/util/Deferred';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IHubProxy } from './IHubProxy';
import { IPushError } from './Push.Contract';
import { MessageHandler } from './MessageHandler';
import { PushBus } from './PushBus.Service';
import { PushHubToken } from './PushHubToken';
import { SignalRHubsConnection, SignalRHubsConnectionState } from './SignalRHubsConnection';
import { SignalRHubsConnectionToken } from './SignalRHubsConnectionToken';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

interface IPushServiceConfig {
	httpCommandDispatchEnabled: boolean;
}

export const PushServiceConfigToken = new InjectionToken<IPushServiceConfig>('PushServiceConfig');

export interface ICommandPromise<T> extends Promise<T> {
	$commandId: Promise<string>;
}

export type DisconnectCallback = () => Promise<boolean>;

@Injectable({
	providedIn: 'root'
})
export class PushService {
	public connectionState: number;
	public readonly userAgent: string;

	constructor(
		@Inject(PushServiceConfigToken) private config: IPushServiceConfig,
		private http: HttpClient,
		private PushBus: PushBus,
		@Inject(PushHubToken) private PushHub: IHubProxy,
		@Inject(SignalRHubsConnectionToken) private SignalRHubsConnection: SignalRHubsConnection
	) {
		this.userAgent = window.navigator.userAgent;

		this.init();
	}

	public cancelDisconnectCommand(connectionId: string): Promise<boolean> {
		connectionId = connectionId || this.SignalRHubsConnection.connectionId;
		console.log('Cancel disconnect command');
		return this.PushHub.server.tryCancelDisconnectCommand(connectionId)
			.then((success: boolean) => {
				console.log(success ? 'Cancelled' : 'Not Cancelled');
				return success;
			}, (err: any) => {
				console.log('Cancel error: ', err);
				return Promise.reject(err);
			});
	}

	public dispatchCommandOnDisconnect(commandType: string, content: any, key: string): Promise<any> {
		console.log('Schedule disconnect command: ', commandType);
		return this.PushHub.server.dispatchCommandOnDisconnect(key, commandType, 'application/json', JSON.stringify(content), this.userAgent)
			.then(response => {
				console.log('Schedule ok');
				return response;
			}, err => {
				console.log('Schedule error: ', err);
				return Promise.reject(err);
			});
	}

	public dispatchCommand(domainKey: string, content: any, finalEvents?: string | string[]): ICommandPromise<any> {
		try {
			if(domainKey === 'network:ConfirmUser') {
				console.log('Dispatching command: ', domainKey);
			}
			else if(domainKey !== 'network:ExtendSessionTimeout') {
				console.log('Dispatching command: ', domainKey, content, finalEvents);
			}

			const finalEventTypes = this.normalizeFinalEvents(finalEvents);

			if(this.config.httpCommandDispatchEnabled && !finalEventTypes) {
				return this.dispatchHttpCommand(domainKey, content) as ICommandPromise<any>;
			}
			return this.dispatchPushCommand(domainKey, content, finalEventTypes);

		} catch(e) {
			return Promise.reject(e) as ICommandPromise<never>;
		}
	}

	private dispatchPushCommand(domainKey: string, content: any, finalEvents: string[]): ICommandPromise<any>{
		const contentString = JSON.stringify(content || {});

		const dispatchPromise = this.PushHub.server.dispatchCommand(domainKey, 'application/json', contentString, this.userAgent);

		const commandPromise: ICommandPromise<any> = Object.assign(dispatchPromise.then((commandId: string) => {
			if(domainKey !== 'network:ExtendSessionTimeout') {
				console.log('Sent Command: ', commandId, domainKey);
			}

			return this.awaitCommandCompletion(commandId, finalEvents);

		},
		(error: any) => {
			console.log('Failed to send command: ', error);
			return Promise.reject(this.buildFailureResult({ error }));

		}), {
			$commandId: dispatchPromise
		});

		this.SignalRHubsConnection.addPendingOperation(Promise.resolve(commandPromise));

		return commandPromise;
	}

	private dispatchHttpCommand(domainKey: string, content: any): Promise<any>{
		return lastValueFrom(this.http.post(`/domain/dispatch/${domainKey}`, content || {}))
			.catch((response: HttpErrorResponse) => {
				return Promise.reject(this.buildFailureResult(response?.error));
			});
	}

	public publishEvent(eventType: string, content: any): Promise<any> {
		console.log('Publish Event: ', eventType);
		return this.PushHub.server.publishEvent(eventType, 'application/json', JSON.stringify(content), this.userAgent)
			.then((response: any) => {
				console.log('Publish Event success: ', response);
				return response;
			}, (err: any) => {
				console.log('Publish Event error: ', err);
				return Promise.reject(err);
			});
	}

	public registerDisconnectCommand(commandType: string, content: any, key?: string): Promise<DisconnectCallback> {
		const connectionId = this.SignalRHubsConnection.connectionId;
		key = key || connectionId;

		return this.dispatchCommandOnDisconnect(commandType, content, key)
			.then(response => {
				return () => this.cancelDisconnectCommand(key);
			});
	}

	/**
	 * Sets a token value that will be sent with all requests.
	 */
	public setToken(accessToken: string, csrfToken: string): void {
		this.PushHub.setState({
			token: accessToken
				? ['VBrick', accessToken, csrfToken].filter(Boolean).join(' ')
				: null,
			csrfToken: !accessToken ? csrfToken : null
		});
	}

	private init(): void {
		this.SignalRHubsConnection.on({
			stateChanged: (event: any) => this.onStateChanged(event),
			reconnected: () => this.onReconnect(),
			ConnectionReestablished: () => this.onReconnect()
		});
	}

	private onStateChanged(event: any): void {
		this.connectionState = event.newState;
		if(this.connectionState === SignalRHubsConnectionState.Connected) {
			this.PushBus.resubscribe();
		}
	}

	private onReconnect(): void {
		this.PushBus.resubscribe();
	}

	private awaitCommandCompletion(commandId: string, finalEvents: string[]): Promise<any> {
		return new Promise((resolve, reject) => {

			const handlers: Dictionary<MessageHandler> = {
				CommandStopped: (message: any): void => {
					this.unsubscribe(commandId, handlers)
						.finally(() => reject(this.buildFailureResult(message)));
				},
				CommandFailed: (message: any): void => {
					console.log('CommandFailed: ', message);
				},
				CommandDenied: (message: any): void => {
					console.log('PushService: CommandDenied', message);
					const issues = [{ id: 'CommandDenied' }];
					if (message.issue) {
						issues.push({ id: message.issue });
					}

					this.unsubscribe(commandId, handlers)
						.finally(() => reject(this.buildFailureResult({ issues })));
				}
			};

			if(!finalEvents) {
				handlers.CommandFinished = (message: any): void => {
					resolve({
						...message,
						unsubscribePromise: this.unsubscribe(commandId, handlers)
					});
				};
			} else {
				finalEvents.forEach((eventType: string) => {
					handlers[eventType] = (message: any): void => {
						resolve({
							eventType,
							message,
							unsubscribePromise: this.unsubscribe(commandId, handlers)
						});
					};
				});
			}

			// Server will autosubscribe to this route, but we still subscribe here so the pushBus knows about it
			this.PushBus.subscribe(commandId, null, handlers, true);
		});
	}

	private unsubscribe(commandId: string, handlers: Dictionary<MessageHandler>): Promise<void> {
		return this.PushBus.unsubscribe(commandId, handlers, true);
	}

	private buildFailureResult(result: any): IPushError {
		const pushError = {
			issues: [],

			hasIssue(issueId): boolean {
				return this.issues.some(issue => issue.id === issueId);
			},

			hasDomainIssue(): boolean {
				return this.issues.some(issue => issue.origin === 'Domain');
			},

			hasPlatformIssue(): boolean {
				return this.issues.some(issue => issue.origin === 'Platform');
			},

			getIssue(issueId): any {
				return this.issues.find(issue => issue.id === issueId);
			},

			getIssueDetail(issueId: string, detailId: string): any {
				const issue: any = this.issues.find((currentIssue: any) => currentIssue.id === issueId);
				const detail: any = issue && (issue.details || []).find((currentDetail: any) => currentDetail.id === detailId);

				return detail && detail.value;
			},

			getIssueIds(): string[] {
				return this.issues.map(issue => issue.id);
			},

			...(result || {})
		} as IPushError;

		console.error('Push Message Issue: ', pushError.issues[0]?.id);

		return pushError;
	}

	private normalizeFinalEvents(finalEvents?: string | string[]): string[] | undefined {
		if (Array.isArray(finalEvents)) {
			return finalEvents;
		} else if(finalEvents) {
			return [finalEvents];
		}
	}
}

export function isPushError(error: any): error is IPushError {
	return error.issues && error.hasIssue;
}
