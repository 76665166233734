import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm, ValidationErrors, Validator } from '@angular/forms';
import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { Subscription } from 'rxjs';

@Component({
	selector: 'base-control-value-and-validation-accessor',
	template: ''
})
export class BaseControlValueAndValidationAccessorComponent<T> extends BaseControlValueAccessor<T> implements Validator, OnDestroy, AfterViewInit {
	public validateFn: () => void;

	@ViewChild(NgForm, { static: true })
	public form: NgForm;

	public subscription: Subscription = new Subscription();

	public ngAfterViewInit(): void {
		this.subscription.add(this.form.statusChanges.subscribe(() => this.validateFn?.()));
	}

	public ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	public validate(): ValidationErrors {
		if(this.form?.invalid) {
			return { invalid: true };
		}
	}

	public registerOnValidatorChange(fn: () => void): void {
		this.validateFn = fn;
	}
}
