import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { noop } from 'rev-shared/util';

import { IViewerIdInfo } from './IViewerIdInfo';
import { ViewerIdPolicy } from './ViewerIdContract';

export const VIEWER_ID_SETTING_HELPER = {
	[ViewerIdPolicy.REQUIRE]: (..._params) => true,
	[ViewerIdPolicy.ALLOW]: (...params) => params[0],
	[ViewerIdPolicy.DISABLE]: (..._params) => false,
};

const VIEWER_ID_INFO_HELPER = {
	[ViewerIdPolicy.REQUIRE]: getRequiredViewerIdInfo,
	[ViewerIdPolicy.ALLOW]: getAllowedViewerIdInfo,
	[ViewerIdPolicy.DISABLE]: noop
};

export function getViewerId(userContext: UserContextService, userLocalIPService: UserLocalIPService) {
	const { username, email } = userContext.getUser();

	if (username && !userContext.isRegisteredGuest()) {
		return username;
	}

	return email || userLocalIPService?.currentUserIp;
}

export function getViewerFullName(userContext: UserContextService) {
	if (userContext) {
		return userContext.getUser().fullName || '';
	}
}

function getRequiredViewerIdInfo(...params: any) {
	const [accViewerIdSettings, viewerId, userContext, customText] = params;

	return {
		id: accViewerIdSettings?.showViewerId ? viewerId : undefined,
		fullName: accViewerIdSettings?.showFullName ? getViewerFullName(userContext) : undefined,
		customMsg: customText ? customText : accViewerIdSettings?.customInformation
	};
}

function getAllowedViewerIdInfo(...params: any) {
	const [accViewerIdSettings, viewerId, userContext, customText, enabledLocal] = params;
	return enabledLocal ? getRequiredViewerIdInfo(accViewerIdSettings, viewerId, userContext, customText): undefined;
}


export function getViewerIdInfo(accViewerIdSettings: any, viewerId: string, enabledLocal: boolean, userContext: UserContextService, customText: string): IViewerIdInfo {
	if (!accViewerIdSettings) {
		return;
	}

	return VIEWER_ID_INFO_HELPER[accViewerIdSettings.viewerIdPolicy](accViewerIdSettings, viewerId, userContext, customText, enabledLocal);
}
