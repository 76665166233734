import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';

import { VbUiBaseSidebarComponent } from './VbUiBaseSidebar.Component';
import { VbUiSidebarLayoutComponent } from './VbUiSidebarLayout.Component';
import { VbUiSidebarComponent } from './VbUiSidebar.Component';

const components = [
	VbUiBaseSidebarComponent,
	VbUiSidebarComponent,
	VbUiSidebarLayoutComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule
	]
})
export class SidebarLayoutModule {}
