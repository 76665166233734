import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TranslationsRequestService } from 'rev-shared/util/TranslationsRequest.Service';
import { ITranslationStrings } from 'rev-shared/util/ITranslationStrings';

import { LIVE_LANGUAGES } from './LiveTranscriptionLanguages';

export interface ILanguageCode {
	name: string;
	code: string;
}

@Injectable({
	providedIn: 'root'
})
export class TranscriptionLanguagesService {

	public srtlanguagesByCode: ITranslationStrings;
	public srtLanguages: ILanguageCode[] = [];
	public aiServiceLanguages: ILanguageCode[] = [];
	public aiServiceTranslationLanguages: ILanguageCode[] = [];
	public liveLanguages: ILanguageCode[] = [];

	constructor(
		private TranslationsRequest: TranslationsRequestService,
		private translateService: TranslateService
	) {}

	public loadSrtLanguages(): Promise<ILanguageCode[]> {
		if (this.srtLanguages.length > 0) {
			return Promise.resolve(this.srtLanguages);
		}
		return this.getTranslationsFile('/partials/shared/media/srt-languages.html')
			.then(strings => {
				this.srtlanguagesByCode = strings;
				this.srtLanguages = this.readTranslations(strings);
				return this.srtLanguages;
			});
	}

	public loadAiServiceLanguages(): Promise<ILanguageCode[]> {
		if (this.aiServiceLanguages.length > 0) {
			return Promise.resolve(this.aiServiceLanguages);
		}
		return this.getTranslationsFile('/partials/shared/media/ai-service-languages.html')
			.then(languages => this.aiServiceLanguages = this.readTranslations(languages));
	}

	public loadAiServiceTranslationLanguages(): Promise<ILanguageCode[]> {
		if (this.aiServiceTranslationLanguages.length > 0) {
			return Promise.resolve(this.aiServiceTranslationLanguages);
		}
		return this.getTranslationsFile('/partials/shared/media/ai-service-translation-languages.html')
			.then(languages => this.aiServiceTranslationLanguages = this.readTranslations(languages));
	}

	public loadLiveTranscriptionLanguages(): ILanguageCode[] {
		if (this.liveLanguages.length) {
			return this.liveLanguages;
		}
		this.liveLanguages = LIVE_LANGUAGES.map(language => ({ code: language.key, name: this.translateService.instant(language.name) }));
		return this.liveLanguages;
	}

	public loadLiveSubtitlesLanguages(): Promise<ILanguageCode[]> {
		this.loadLiveTranscriptionLanguages();
		return this.loadAiServiceTranslationLanguages();
	}

	private getTranslationsFile(file: string): Promise<ITranslationStrings> {
		return this.TranslationsRequest.requestTranslations(file, 'languageTranslations');
	}

	private readTranslations(translations: ITranslationStrings): ILanguageCode[] {
		return Object.entries(translations)
			.map(([code, name]) => ({ code, name }))
			.sort((a, b) => a.name.localeCompare(b.name));
	}
}
