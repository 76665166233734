export const LIVE_LANGUAGES = [
	{
		key: 'en',
		name: 'Language_en'
	},
	{
		key: 'en-au',
		name: 'Language_en-au'
	},
	{
		key: 'en-gb',
		name: 'Language_en-gb'
	},
	{
		key: 'es',
		name: 'Language_es'
	},
	{
		key: 'fr',
		name: 'Language_fr'
	},
	{
		key: 'fr-ca',
		name: 'Language_fr-ca'
	},
	{
		key: 'de',
		name: 'Language_de'
	},
	{
		key: 'it',
		name: 'Language_it'
	}
];

