import {
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnDestroy,
	inject
} from '@angular/core';

import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { MediaQueryService } from 'rev-shared/ui/size/MediaQuery.Service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'vb-ui-base-sidebar',
	template: ''
})
export class VbUiBaseSidebarComponent implements OnDestroy {
	@Input() public isMobileLayoutDisabled: boolean;
	@Input() public mobileLayoutBreakPointSize: number;

	public isMobileWindow: boolean;
	protected mediaQuery: string;
	protected hostClass: {[key: string]: boolean};
	private subscription: Subscription;

	private MediaQueryService: MediaQueryService = inject(MediaQueryService);
	private elementRef: ElementRef = inject(ElementRef);


	public initMediaQuerySubscription(styles: any): void {
		if (this.mobileLayoutBreakPointSize) {
			this.elementRef.nativeElement.style.setProperty('--sidebar-mobile-layout-break', this.mobileLayoutBreakPointSize + 'px');
			this.mediaQuery = `(max-width: ${this.mobileLayoutBreakPointSize - 1}px)`;
			this.elementRef.nativeElement.classList.add(styles.customMobileBreakPoint);
		}

		this.subscription = this.MediaQueryService.getObservable(this.mediaQuery || MaxWidthSmallMediaQuery)
			.subscribe(matches => this.onMobileMediaQueryChange(matches, styles));

	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	@HostBinding('attr.layout')
	public get layout(): string {
		return this.isMobileWindow ? 'column' : 'row';
	}

	protected onMobileMediaQueryChange(matches: boolean, styles: any): void {
		if (this.mobileLayoutBreakPointSize) {
			if (matches) {
				this.elementRef.nativeElement.classList.add(styles.customBreakPointReached);
			} else {
				this.elementRef.nativeElement.classList.remove(styles.customBreakPointReached);
			}
		}

		this.isMobileWindow = !this.isMobileLayoutDisabled && matches;
	}
}
