import MultiStreamsMixer from 'multistreamsmixer/MultiStreamsMixer';
import { Producer } from 'mediasoup-client/lib/types';

import { WebRtcListenerConnectionService } from './WebRtcListenerConnection.Service';

export class AudioProducer {
	private micStream: MediaStream;
	private micProducer: Producer;
	private captureStream: MediaStream;
	private captureProducer: Producer;
	private mixedProducer: Producer;
	private mixedTrack: MediaStreamTrack;

	constructor(
		public readonly mixdown: boolean, //If true, mix audio from camera and capture into one stream
		public readonly WebRtcListener: WebRtcListenerConnectionService
	) {}

	public update(micStream: MediaStream, captureStream: MediaStream): Promise<any> {
		if(this.mixdown) {
			return this.updateMixedAudio(micStream, captureStream);
		}

		const updates = [];
		if(micStream !== this.micStream) {
			this.micStream = micStream;
			updates.push(this.updateProducer(micStream?.getAudioTracks()[0], this.micProducer)
				.then(producer => {
					this.micProducer = producer;
				}));
		}

		if(captureStream !== this.captureStream) {
			this.captureStream = captureStream;
			updates.push(this.updateProducer(captureStream?.getAudioTracks()[0], this.captureProducer, { kind: 'content' })
				.then(producer => {
					this.captureProducer = producer;
				}));
		}

		return Promise.all(updates);
	}

	public close(): void {
		this.mixedTrack?.stop();
		this.mixedProducer?.close();
		this.captureProducer?.close();
		this.micProducer?.close();
	}

	private updateMixedAudio(micStream: MediaStream, captureStream: MediaStream): Promise<void> {
		const track = getMixedAudioTrack(micStream, captureStream);
		this.mixedTrack = track;
		return this.updateProducer(track, this.mixedProducer)
			.then(producer => {
				this.mixedProducer = producer;
			});
	}

	private updateProducer(track: MediaStreamTrack, producer: Producer, appData: any = undefined): Promise<Producer> {
		if(!track) {
			return this.WebRtcListener.stopProducer(producer)
				.then(() => undefined);
		}

		if(producer) {
			return this.WebRtcListener.replaceTrack(producer, track, 'audio')
				.then(() => producer);
		}

		return this.WebRtcListener.produceAudio(track, appData);

	}
}

function getMixedAudioTrack(...mediaStreams: MediaStream[]): MediaStreamTrack {
	const streams = mediaStreams.filter(Boolean);

	const audioTracks = streams.flatMap(s => s.getAudioTracks());

	if(!audioTracks.length) {
		// Audio is needed by MPH, Send blank audio
		const context = new AudioContext();
		const dest = context.createMediaStreamDestination();
		const osc = context.createOscillator();
		osc.frequency.value = 440; // Hz
		const vol = context.createGain();
		vol.gain.value = 0.001;
		osc.connect(vol);
		vol.connect(dest);
		return dest.stream.getAudioTracks()[0];
	}

	if(audioTracks.length === 1) {
		return audioTracks[0];
	}

	const mixer = new MultiStreamsMixer(streams.map(stream =>
		new MediaStream(stream.getAudioTracks())));

	return mixer.getMixedStream().getAudioTracks()[0];
}


