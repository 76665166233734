import {
	Directive, ElementRef, HostListener, OnInit, OnDestroy
} from '@angular/core';

import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import { modulo } from 'rev-shared/util';

/**
 * Override for ngx-bootstrap to add better keyboard controls.
 * Adds support for container=body.
 * Escape key closes menu if a menu item is focuses
 * Wrapping from bottom to top
 * handle focusin to keep focused item in sync with internal state
 */
@Directive({
	selector: '[vbDropdown]',
})
export class VbDropdownDirective implements OnInit, OnDestroy {
	private sub: Subscription;
	private isOpen: boolean;
	private isToggleFocused: boolean;
	private focusedIndex: number;

	private get toggleBtn(): HTMLElement {
		return this.elementRef.nativeElement.querySelector('[dropdownToggle]');
	}

	constructor(
		private readonly dropdown: BsDropdownDirective,
		private readonly elementRef: ElementRef
	) {
		//Override arrowdown/arrowup hostbinding method.
		dropdown.navigationClick = (event: KeyboardEvent)=> {
			if(!this.isOpen) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			this.moveFocus(event.code === 'ArrowUp');
		};
	}

	public ngOnInit(): void {
		if(this.dropdown.container === 'body') {
			this.sub = this.dropdown.isOpenChange.subscribe(isOpen => {
				this.isOpen = isOpen;
				if(!this.isOpen) {
					return;
				}

				const menuEl = this.getMenuEl();
				menuEl?.addEventListener('focusin', e => this.onFocus(e));
				menuEl?.addEventListener('keydown', e => {
					switch(e.code) {
						case 'Escape':
							this.dropdown.hide();
							break;
						case 'ArrowDown':
							this.moveFocus(false);
							break;
						case 'ArrowUp':
							this.moveFocus(true);
							break;
						default:
							return;
					}
					e.stopPropagation();
					e.preventDefault();
				});
			});
		}
	}

	public ngOnDestroy(): void {
		this.sub?.unsubscribe();
	}

	private moveFocus(up: boolean): void {
		const items = Array.from(this.getMenuEl().querySelectorAll('.dropdown-item')) as HTMLElement[];

		const currentPosition = this.isToggleFocused ? 0 : (this.focusedIndex + 1);
		const direction = up ? -1 : 1;
		const newPosition = modulo(currentPosition + direction, items.length + 1);

		if(newPosition === 0) {
			this.toggleBtn.focus();
			return;
		}

		items[newPosition - 1].focus();
	}

	@HostListener('focusin', ['$event'])
	public onFocus($event: FocusEvent) {
		this.resetFocus();
		const target = $event.target as HTMLElement;
		if(target === this.toggleBtn) {
			this.isToggleFocused = true;
			return;
		}
		const item = target.closest('.dropdown-item');
		const allItems = Array.from(this.getMenuEl()?.querySelectorAll('.dropdown-item') || []);
		const index = allItems.indexOf(item);
		this.focusedIndex = index;
	}

	private resetFocus(): void {
		this.focusedIndex = null;
		this.isToggleFocused = false;
	}

	private getMenuEl(): HTMLElement {
		return (this.dropdown as any)._dropdown?.instance?._element?.nativeElement;
	}
}
