import {
	Component,
	Input,
	forwardRef
} from '@angular/core';
import { ImageSelectorComponent } from './ImageSelector.AngularComponent';
import { ChangeDetectorRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

/*
* ImageSelectorV2Component has identical functionality to ImageSelectorComponent. The primary
* purpose of this component is to allow for a larger drop target for drag and drop functionality. Old uses of
* ImageSelectorComponent have not been replaced as of yet (05/30/2024)
*/

@Component({
	selector: 'image-selector-v2',
	templateUrl: './ImageSelectorV2.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => ImageSelectorV2Component),
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => ImageSelectorV2Component),
		multi: true
	}]
})
export class ImageSelectorV2Component extends ImageSelectorComponent {
	@Input() public isAdminTheme: boolean;

	constructor(
		public ChangeDetectorRef: ChangeDetectorRef
	) {
		super(ChangeDetectorRef);
	}
}
