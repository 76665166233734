<vb-title *ngIf="bulkEdit" [title]="'Title_BulkEditVideos' | translate"></vb-title>

<div class="media-layout overflow-hidden" layout="column" layout-gt-md="row" flex-wrap="false" flex="fill">

	<!-- right sidebar -->
	<div *ngIf="!mediaState.isFilteringDisabled" #rightSidebarContainerRef flex-gt-md="48px" flex-align-self="stretch" class="right-sidebar" [ngClass]="[
			styles.sidebar,
			bulkEdit ? 'bulk-edit-sidebar' : '',
			bulkEdit && hasLockedVideos ? 'force-closed' : ''
		]" [vbUiSrefActive]="'is-open'" [activeState]="'-sidebar'">

		<div role="tablist" class="sidebar-buttons theme-primary" [attr.aria-label]="'SideBarMenu' | translate">
			<div class="btn-list" (keydown.arrowup)="onSidebarButtonArrowUp($event.target)" (keydown.arrowdown)="onSidebarButtonArrowDown($event.target)">
				<ng-container *ngIf="(mediaQuery$ | async) as mediaQuery">
					<a *ngIf="mediaQuery.isSmallSize && isOpen" role="tab" class="legacySidebarBtn" [attr.tabindex]="'-1'" [attr.id]="VideoResultsSidebarButton.HOME" [attr.aria-label]="'Home' | translate" [tooltip]="'Home' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" uiSref=".">
						<span class="glyphicons home"></span>
					</a>
				</ng-container>
				<a role="tab" class="legacySidebarBtn" [attr.tabindex]="!isOpen || (isOpen && activeSidebarButtonId === VideoResultsSidebarButton.FILTERS) ? '0' : '-1'" [attr.id]="VideoResultsSidebarButton.FILTERS" [attr.aria-label]="'Media_FiltersTooltip' | translate" [tooltip]="'Media_FiltersTooltip' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" uiSref=".filters-sidebar" uiSrefActive="active theme-accent" [uiParams]="{ tableDataMode: tableDataMode }" [uiOptions]="{ location: false, relative: currentStateName }" vbUiSrefToggle (click)="onSidebarToggle(VideoResultsSidebarButton.FILTERS)">
					<span class="glyphicons filter"></span>
				</a>
				<a *ngIf="
						MediaStateService.showBulkEdit &&
						userHasEditableVideos &&
						!MediaStateService.mediaState.isBulkEditDisabled
					" role="tab" class="legacySidebarBtn" [attr.tabindex]="'-1'" [attr.id]="VideoResultsSidebarButton.BULK_EDIT" [attr.aria-label]="'Media_BulkEdit' | translate" [tooltip]="'Media_BulkEdit' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" uiSref="portal.media.edit" [uiParams]="{ categoryId: categoryId, q: query, sortField, desc: !sortAscending }" vbUiSrefToggle>
					<span class="vb-icon vb-icon-center vb-icon-pencil type-20"></span>
				</a>

				@if (bulkEdit) {
					<div class="bulk-edit-sidebar-btns">
						@if (!hasLockedVideos) {
							@if (isBulkGenerateMetadataEnabled || isBulkTranscribeEnabled) {
								<a role="tab" class="legacySidebarBtn" [attr.tabindex]="activeSidebarButtonId === VideoResultsSidebarButton.BULK_EDIT_TRANSCRIPTION_METADATA_SIDEBAR ? '0' : '-1'" [attr.id]="VideoResultsSidebarButton.BULK_EDIT_TRANSCRIPTION_METADATA_SIDEBAR" [attr.aria-label]="(isBulkGenerateMetadataEnabled && isBulkTranscribeEnabled ? 'Media_BulkEdit_TranscriptionMetadata' : isBulkTranscribeEnabled ? 'Media_BulkEdit_Transcription' : 'Media_BulkEdit_Metadata') | translate" [tooltip]="(isBulkGenerateMetadataEnabled && isBulkTranscribeEnabled ? 'Media_BulkEdit_TranscriptionMetadata' : isBulkTranscribeEnabled ? 'Media_BulkEdit_Transcription' : 'Media_BulkEdit_Metadata') | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" uiSref=".bulk-edit-transcription-metadata-sidebar" uiSrefActive="active theme-accent" [uiOptions]="{ location: false, relative: currentStateName }" vbUiSrefToggle (click)="onSidebarToggle(VideoResultsSidebarButton.BULK_EDIT_TRANSCRIPTION_METADATA_SIDEBAR)">
									<span class="vb-icon vb-icon-center vb-icon-sparkle"></span>
								</a>
							}
							<a role="tab" class="legacySidebarBtn" [attr.tabindex]="activeSidebarButtonId === VideoResultsSidebarButton.BULK_EDIT_SETTINGS_SIDEBAR ? '0' : '-1'" [attr.id]="VideoResultsSidebarButton.BULK_EDIT_SETTINGS_SIDEBAR" [attr.aria-label]="'Media_BulkEdit_EditTooltip' | translate" [tooltip]="'Media_BulkEdit_EditTooltip' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" uiSref=".bulk-edit-sidebar" uiSrefActive="active theme-accent" [uiOptions]="{ location: false, relative: currentStateName }" vbUiSrefToggle (click)="onSidebarToggle(VideoResultsSidebarButton.BULK_EDIT_SETTINGS_SIDEBAR)">
								<span class="glyphicons cogwheel"></span>
							</a>
							@if (!VideoSelectionModel.allSelectedVideosAreLiveRecordings) {
								<vb-delete-videos-button [loadSearchResults]="forceLoadAllSearchResults"></vb-delete-videos-button>
							}
						}
						@if (mediaFeatures.enableLegalHold && isAccountAdminUser) {
							<vb-legal-hold-sidebar></vb-legal-hold-sidebar>
						}
						<a role="tab" class="legacySidebarBtn" [attr.tabindex]="'-1'" [attr.id]="VideoResultsSidebarButton.BULK_EDIT_CANCEL" [attr.aria-label]="'Media_BulkEdit_Cancel' | translate" [tooltip]="'Media_BulkEdit_Cancel' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" [href]="mediaState.backLink">
							<span class="glyphicons circle_remove"></span>
						</a>
					</div>
				}

				<button *ngIf="hasMediaEditAuth && MediaStateService.showDownloadInventoryReports" role="tab" class="legacySidebarBtn" [attr.tabindex]="'-1'" [attr.id]="VideoResultsSidebarButton.DOWNLOAD_CSV" [attr.aria-label]="'InventoryReportsDownload' | translate" [tooltip]="'InventoryReportsDownload' | translate" [adaptivePosition]="false" [container]="'body'" [placement]="'left'" (click)="initiateInventoryReportDownload()">
					<span class="vb-icon vb-icon-csv vb-icon-center" [ngClass]="styles.sidebarVbIcon"></span>
				</button>
			</div>
		</div>

		<div ui-view="right-sidebar-content" class="right-sidebar-content theme-primary-font-border-fade-50"></div>

	</div>

	<!-- main content -->
	<div *ngIf="viewMode === 'tiles'; else tableView" class="fadein-effect container-fluid vb-scrollbar" flex="fill" [ngClass]="styles.tilesSearchContent" infiniteScroll (scrolled)="loadNextPage()" [infiniteScrollDisabled]="pauseInfiniteScroll" [scrollWindow]="false" [infiniteScrollDistance]="2">
		<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!showSpinner()"></vb-loading-spinner>

		<vb-search-filter-list class="row padding-left-10" *ngIf="!mediaState.isFilteringDisabled"></vb-search-filter-list>

		<video-search-results-tiles></video-search-results-tiles>

		<div class="centered-text-block-content" [hidden]="!isLoadComplete || videos?.length || categories?.length">
			<h2>{{ 'UI_NoResultsFound' | translate }}</h2>
		</div>
		<div *ngIf="scrollExpired" [ngClass]="styles.scrollExpiredMsg">
			<a (click)="resetDataModel()">
				{{ 'UI_ClickToRefreshTable' | translate }}
			</a>
		</div>
	</div>

	<ng-template #tableView>
		<div class="fadein-effect container-fluid" flex="fill" [ngClass]="styles.tableSearchContent">
			<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!showSpinner()"></vb-loading-spinner>
			<vb-search-filter-list class="row" *ngIf="!mediaState.isFilteringDisabled"></vb-search-filter-list>
			<ng-container *ngIf="(mediaQuery$ | async) as mediaQuery">
				<video-search-results-table *ngIf="!isInitializing" [hidden]="showSpinner()" [isSmallSize]="mediaQuery.isSmallSize" [tableDataMode]="tableDataMode" [columnSort]="{ colId: sortField | tableViewColumnMap,  sort: sortAscending ? 'asc' : 'desc' }" (tableViewReady)="onTableViewReady($event)">
				</video-search-results-table>
			</ng-container>
		</div>
	</ng-template>

</div>
