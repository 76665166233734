import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import styles from './CollapsableSection.module.less';

@Component({
	selector: 'collapsable-section-v2',
	templateUrl: './CollapsableSectionV2.Component.html'
})
export class CollapsableSectionV2Component implements OnInit {
	@Input() public startClosed: boolean;
	@Input() public headerTitle: string;
	@Input() public invalidSection: boolean;
	@Output() public toggleCollapsed = new EventEmitter<boolean>();

	public readonly styles = styles;

	public expanded: boolean;

	public ngOnInit(): void {
		this.expanded = !this.startClosed;
	}

	public toggleExpanded(): void {
		this.expanded = !this.expanded;
	}
}
