<div class="modal-header">
	<h4>
		<span class="glyphicons circle_exclamation_mark error-msg"></span>
		{{ title }}
	</h4>
	<button type="button" class="close btn btn-transparent" (click)="hide()" [attr.aria-label]="'Close' | translate">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
<div class="modal-body">
	{{ message }}
</div>
<div class="modal-footer">
	<button vbBtnPrimary (click)="hide()">
		{{ okButtonText || 'Ok' | translate }}
	</button>
</div>
