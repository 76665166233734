import { fromEvent, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';

import { noop } from 'rev-shared/util';

@Injectable({
	providedIn: 'root'
})
export class MediaQueryService {
	constructor(
		private ngZone: NgZone
	) {}

	public getObservable(query: string): Observable<boolean> {
		const mediaQuery = window.matchMedia(query);
		return fromEvent(mediaQuery, 'change').pipe(
			map(() => mediaQuery.matches),
			startWith(mediaQuery.matches),
			tap(() => this.ngZone.run(noop))
		);
	}
}
