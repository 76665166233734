import {
	Directive, ElementRef, Input, OnInit, inject
} from '@angular/core';

import styles from './vb-ui-text-area.module.less';

@Directive({
	selector: '[vbUiTextArea]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbTextAreaDirective implements OnInit {
	@Input() public height: number;
	public hostClass: string = `${styles.textArea} 'theme-accent-border-focus'`;

	private elementRef = inject(ElementRef);

	public ngOnInit(): void {
		if (this.height) {
			this.elementRef.nativeElement.style.setProperty('--vb-ui-text-area-height', this.height + 'px');
		}

	}
}
