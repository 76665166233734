import { NgModule } from '@angular/core';

import { Video360IndicatorComponent } from './Video360Indicator.Component';

@NgModule({
	declarations: [
		Video360IndicatorComponent
	],
	exports: [Video360IndicatorComponent]
})
export class Video360IndicatorModule {}
