import {
	AfterContentInit,
	Component,
	ContentChild,
	ElementRef,
	Input,
	OnDestroy
} from '@angular/core';
import { NgModel } from '@angular/forms';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { map, Observable, Subscription } from 'rxjs';

import styles from './select.module.less';
/**
 * This is an angular component. This will be used to wrap select element to provide themed look.
 * Add #selectElement on select element. Please check below example.
 * Below is the usage.
 * <vb-ui-select>
		<select id="selectEvent"
			name="selectEvent"
			[(ngModel)]="selectedEvent"
			required
			(ngModelChange)="onEventChange()"
			#selectElement
			#selectEvent="ngModel">
			<option [ngValue]="undefined">{{ 'Webex_SelectWebcast' | translate }}</option>
			<option *ngFor="let webcast of webcastList"  [ngValue]="webcast">{{webcast.title}}</option>
		</select>
 *	</vb-ui-select>
 * #selectElement - Provide selectElement template variable. it is mandatory.
 */

@Component({
	selector: 'vb-ui-select',
	template: `
	<ng-template [vbCssRules]="cssRules$ | async"></ng-template>
	<div
		[ngClass]="[
			styles.customSelect,
			isError ? styles.error : '',
			isFocused ? focusedClass : '',
			customClass ? customClass: ''
		]">
		<ng-content></ng-content>
	</div> `
})

export class VbUiSelectComponent implements AfterContentInit, OnDestroy {
	@Input() public customClass: string;
	@ContentChild('selectElement') private selectElementRef: ElementRef;
	@ContentChild(NgModel) private ngModel: NgModel;

	public readonly styles = styles;
	public selectElement: HTMLSelectElement;
	public isFocused: boolean;
	public onFocus = () => this.isFocused = true;
	public onBlur = () => this.isFocused = false;
	public readonly focusedClass = `theme-accent-border ${styles.focus}`;
	public cssRules$: Observable<IRules>;

	constructor(
		private ThemeService: ThemeService
	) {
	}

	public ngAfterContentInit(): void {
		if (!this.selectElementRef) {
			console.error('selectElement ref not provided');
			return;
		}

		this.selectElement = this.selectElementRef.nativeElement;
		this.selectElement.addEventListener('focus', this.onFocus);
		this.selectElement.addEventListener('blur', this.onBlur);

		this.cssRules$ = this.ThemeService.brandingSettings$.pipe(
			map(() => this.getCssRules())
		);
	}

	public ngOnDestroy(): void {
		this.selectElement.removeEventListener('focus', this.onFocus);
		this.selectElement.removeEventListener('blur', this.onBlur);
	}

	public get isError(): boolean {
		return this.ngModel?.invalid && this.ngModel?.touched;
	}

	public getCssRules(): IRules {
		return {
			[`.${styles.customSelect}:before`]: {
				background: this.ThemeService.accentColor
			},
			[`.${styles.customSelect}:after`]: {
				'border-top': `10px solid ${this.ThemeService.accentFontColor}`
			}
		};
	}
}
