import { Injectable } from '@angular/core';

import { AppBootstrapService } from '../bootstrap/AppBootstrap.Service';

@Injectable()
export class AppUtilService {
	constructor(
		private AppBootstrapService: AppBootstrapService
	) {}

	public isMsTeamsEmbedLogin(): boolean {
		return window.location.pathname.includes('ms-teams') && !this.isMsTeamsMobile();
	}

	public isMsTeamsMobile(): boolean {
		return this.AppBootstrapService.appConfig?.isMsTeamsMobileMode;
	}
}

export function isPortal(): boolean {
	return window.location.pathname === '/';
}

export function isEmbed(): boolean {
	return !!window.location.pathname.match(/^\/embed/);
}
