import { ParamTypes, StateService, Transition, UIRouter, UIRouterGlobals } from '@uirouter/angular';
import { Injector } from '@angular/core';

import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { ApprovalProcessService } from 'rev-shared/media/approvalProcess/ApprovalProcess.Service';
import { ApprovalStatus, UNCATEGORIZED } from 'rev-shared/media/MediaConstants';
import { ApproverTemplateInfo } from 'rev-shared/media/approvalProcess/ApproverTemplateInfo';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserSubscriptionsService } from 'rev-shared/userSubscription/UserSubscriptions.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { SearchConstants } from 'rev-portal/search/SearchConstants';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';
import { SearchHeaderService } from 'rev-portal/navigation/searchHeader/SearchHeader.Service';
import { SearchService } from 'rev-portal/search/Search.Service';
import { SparkService } from 'rev-portal/media/spark/Spark.Service';
import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { MediaContainerComponent } from './MediaContainer.Component';
import { MediaStateService, MediaViewMode, SortField } from './MediaState.Service';
import { MyVideoSearchResultsComponent, GlobalVideoSearchResultsComponent, MySubscriptionVideoSearchResultsComponent, ExpirationVideoSearchResultsComponent, PendingVideoSearchResultsComponent, CategoryDetailsSearchResultsComponent, CategoryBrowseSearchResultsComponent } from './search/SearchResults.Component';
import { VideoSearchResultsComponent } from './search/VideoSearchResults.Component';

export const MEDIA_STATE_ALL: string = 'portal.media.all';
export const MEDIA_STATE_BROWSE: string = 'portal.media.browse';

function redirectToAllTeamsState($state: StateService): void {
	$state.go('portal.team');
}

function setMediaState(MediaStateService: MediaStateService,
	desc: boolean,
	sortField: string,
	viewMode: MediaViewMode,
	defaultSortField: string,
	defaultViewMode: MediaViewMode) {
	MediaStateService.setSortField(sortField || defaultSortField);
	MediaStateService.setViewMode(viewMode || defaultViewMode);
	desc === null ? MediaStateService.setDefaultSortDirection(): MediaStateService.setIsSortDesc(desc);
}
let lastSearchQuery: string;

export function configureMediaRouter(uiRouter: UIRouter, injector: Injector): void {
	uiRouter.urlService.rules.when('/guest', () => {
		const $state = injector.get(StateService);
		const userContext = injector.get(UserContextService);

		//redirect guests while masking it with the /guest location
		$state.go(MEDIA_STATE_BROWSE, {}, { location: userContext.isUserAuthenticated() });
	});
}

export function getBaseState(UIRouterGlobals: UIRouterGlobals) {
	return UIRouterGlobals.current.name?.replace(/\.[^.]*-sidebar$/g, '');
}

export const states: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.media',
		url: '/media?team&sortField&viewMode&desc&:filters',
		secureRedirects: [MEDIA_STATE_ALL],
		allowGuestAccess: true,
		component: MediaContainerComponent,
		params: {
			team: { value: null, squash: true },
			config: { value: null, squash: true, type: ParamTypes.json, dynamic: true },
			filters: { value: null, squash: true, dynamic: true },
			isMediaFilterDisabled: { value: null, squash: true }
		},
		resolve: [
			{
				token: 'approverProcessTemplates',
				deps: [ApprovalProcessService, UserContextService],
				resolveFn: (ApprovalProcessService: ApprovalProcessService, UserContext: UserContextService) => {
					return UserContext.isUserAuthenticated() ?
						ApprovalProcessService.fetchUserApprovalProcessTemplates()
							.then(() => ApprovalProcessService.approverProcessTemplates) :
						[];
				}
			},
			{
				token: 'uploadedVideoCount',
				deps: [VideoService, UserContextService],
				resolveFn: (VideoService: VideoService, UserContext: UserContextService) => {
					return UserContext.isUserAuthenticated() ? VideoService.getUserUploadedVideosCount() : 0;
				}
			},
			{
				token: 'expirationsVideoCount',
				deps: [SearchService, 'accountId'],
				resolveFn: (SearchService: SearchService, accountId: string) => {
					return SearchService.expirationsVideoCount(accountId);
				}
			},
			{
				token: 'userHasEditableVideos',
				deps: [SearchService, UserContextService, 'accountId', 'teamId'],
				resolveFn: (SearchService: SearchService, UserContext: UserContextService, accountId: string, teamId: string) => {
					if (UserContext.isGuest()) {
						return false;
					}

					return SearchService.userHasEditableVideos(accountId, teamId);
				}
			},
			{
				token: 'mediaFeatures',
				deps: [MediaFeaturesService],
				resolveFn: (MediaFeatures: MediaFeaturesService) => {
					return MediaFeatures.getFeatures();
				}
			},
			{
				token: 'sparkSubscriptions',
				deps: [SparkService, 'mediaFeatures'],
				resolveFn: (SparkService: SparkService, mediaFeatures: any) => {
					if(mediaFeatures.enableSpark){
						return SparkService.loadCategorySubscriptions();
					}
				}
			},
			{
				token: 'loadAccountLicense',
				deps: [AccountLicenseService],
				resolveFn: (accountLicense: AccountLicenseService) => {
					accountLicense.reload();
				}
			},
			{
				token: 'teamId',
				deps: [Transition],
				resolveFn: ($transition$: Transition) => $transition$.params().team
			},
			{
				token: 'initMediaStateConfig',
				deps: [Transition, MediaStateService],
				resolveFn: ($transition$: Transition, MediaStateService: MediaStateService) => {
					MediaStateService.initMediaStateConfig($transition$.params().config);
				}
			},
			{
				token: 'isMediaFilterDisabled',
				deps: [Transition],
				resolveFn: (transition: Transition) => transition.params().isMediaFilterDisabled
			},
			{
				token: 'teamBranding',
				deps: [StateService, TeamListingsService, 'teamId', MediaStateService],
				resolveFn: ($state: StateService, TeamListingsService: TeamListingsService, teamId: string, MediaStateService: MediaStateService) => {
					if (!teamId) {
						return;
					}

					return TeamListingsService.loadTeam(teamId)
						//If Team Admin who is not account or media admin removes themself from team, they get 401.
						.catch(err => err.status === 401 ? {} : Promise.reject(err))
						.then(response => response.isTeamMember ? response :
							MediaStateService.redirectToAllTeams ?
								redirectToAllTeamsState($state) :
								Promise.reject({ status: 401 })
						);
				}
			},
			{
				token: 'forceMediaView',
				resolveFn: () => null
			},
			{
				token: 'searchParams',
				resolveFn: () => ({})
			},
			{
				token: 'mediaInit',
				deps: [Transition, MediaStateService, SearchFilterStateService, StateChangeStatus, 'teamId', 'isMediaFilterDisabled'],
				resolveFn: ($transition$: Transition, MediaStateService: MediaStateService, SearchFilterState: SearchFilterStateService, StateChangeStatus: StateChangeStatus, teamId: string, isMediaFilterDisabled: boolean) => {
					if (!StateChangeStatus.isStateReloading) {
						if (!$transition$.params().keepFilterOverrides) {
							SearchFilterState.initialize();
						}
						MediaStateService.setIsSortDesc(false);
						MediaStateService.setSortField();
						MediaStateService.setViewMode(MediaViewMode.TILES);

						if (isMediaFilterDisabled) {
							MediaStateService.setIsFilteringDisabled(true);
						}
					}
				}
			},
			{
				token: 'desc',
				deps: [Transition],
				resolveFn: ($transition$: Transition) => {
					const desc = $transition$.params().desc;
					return desc ? desc === 'true': null;
				}
			},
			{
				token: 'viewMode',
				deps: [Transition],
				resolveFn: ($transition$: Transition) => $transition$.params().viewMode
			},
			{
				token: 'sortField',
				deps: [Transition],
				resolveFn: ($transition$: Transition) => $transition$.params().sortField
			},
			{
				token: 'filters',
				deps: [Transition],
				resolveFn: ($transition$: Transition) => $transition$.params().filters
			},
			{
				token: 'loadContentSubscriptions',
				deps: ['mediaFeatures', UserSubscriptionsService],
				resolveFn: (mediaFeatures: any, UserSubscriptionsService: UserSubscriptionsService) => {
					if(mediaFeatures.enableContentNotifications) {
						return UserSubscriptionsService.loadUserSubscriptions();
					}
				}
			}
		],
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const searchHeaderService = injector.get<SearchHeaderService>(SearchHeaderService);

			searchHeaderService.setSearchQuery(transition.params().q);
			if (!transition.params().keepFilterOverrides) {
				searchFilterState.startService();
			}
		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			if (!transition.params().keepFilterOverrides) {
				searchFilterState.stopService();
			}
			if (!stateChangeStatus.isStateReloading) {
				searchFilterState.clear(false);
			}
		}
	},
	{
		name: 'portal.media.dummy',
		url: '/dummy',
		allowGuestAccess: true,

	},
	{
		name: MEDIA_STATE_ALL,
		url: '/all',
		views: {
			'content@portal.media': {
				component: VideoSearchResultsComponent
			}
		},
		scrollToTop: true,
		resolve: [
			{
				token: 'init',
				deps: [StateChangeStatus, 'teamId', Transition, 'mediaInit'],
				resolveFn: (
					StateChangeStatus: StateChangeStatus,
					teamId: string,
					$transition$: Transition,
					mediaInit
				) => {
					const isReloading = StateChangeStatus.isStateReloading && teamId === $transition$.params('from').team;
					if(isReloading) {
						return;
					}
				}
			}
		],
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const mediaFeatures = injector.get<IMediaFeatures>('mediaFeatures');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const teamId = injector.get<string>('teamId');
			const viewMode = injector.get<MediaViewMode>('viewMode');

			let defaultSort = mediaFeatures.defaultVideoSortOrder;

			if (teamId) {
				searchFilterState.filters.teamIds.overrideValue([{ teamId }], true);
				defaultSort = SortField.WhenUploaded;
			}

			setMediaState(mediaStateService, desc, sortField, viewMode, defaultSort, MediaViewMode.TILES);
		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const mediaFeatures = injector.get<IMediaFeatures>('mediaFeatures');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			mediaStateService.reset(mediaFeatures.defaultVideoSortOrder);

			if (!transition.params().keepFilterOverrides) {
				searchFilterState.filters.teamIds.clearValueOverride();
			}
		}
	},
	{
		name: 'portal.media.uploads',
		url: '/uploads',
		views: {
			'content@portal.media': {
				component: MyVideoSearchResultsComponent
			}
		},
		scrollToTop: true,
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const userContext = injector.get<UserContextService>(UserContextService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const viewMode = injector.get<MediaViewMode>('viewMode');

			searchFilterState.filters.ownerUserId.overrideValue([{ id: userContext.getUser().id }]);

			setMediaState(mediaStateService, desc, sortField, viewMode, SortField.WhenUploaded, MediaViewMode.TILES);

			if (!stateChangeStatus.isStateReloading) {
				mediaStateService.showPendingButton = false;
			}
		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			if (!transition.params().keepFilterOverrides) {
				searchFilterState.filters.ownerUserId.clearValueOverride();
			}
		}
	},
	{
		name: 'portal.media.search',
		url: '/search?{q:any}',
		views: {
			'content@portal.media': {
				component: GlobalVideoSearchResultsComponent
			}
		},
		scrollToTop: true,
		allowGuestAccess: true,
		resolve: [
			{
				token: 'init',
				deps: [
					MediaStateService,
					SearchFilterStateService,
					StateChangeStatus,
					'desc',
					'sortField',
					'viewMode',
					'mediaInit'
				],
				resolveFn: (
					MediaStateService: MediaStateService,
					SearchFilterState: SearchFilterStateService,
					StateChangeStatus: StateChangeStatus,
					desc: boolean,
					sortField: string,
					viewMode: MediaViewMode,
					mediaInit: any
				) => {
					const q: any = StateChangeStatus.transition.toParams.q;
					const isNewQuery: boolean = lastSearchQuery !== q;
					MediaStateService.showSortRecommended = false;

					if (!StateChangeStatus.isStateReloading || isNewQuery) {
						MediaStateService.reset();
						SearchFilterState.clear(false);

						lastSearchQuery = q;
					}

					setMediaState(MediaStateService, desc, sortField, viewMode, SearchConstants.defaultSortField, MediaViewMode.TABLE);
				}
			},
			{
				token: 'searchParams',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return { query: transition.params().q };
				}
			}
		],
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			const searchHeaderService = injector.get<SearchHeaderService>(SearchHeaderService);

			searchHeaderService.clearData();
			mediaStateService.showSortRecommended = true;
			if (!stateChangeStatus.isStateReloading) {
				lastSearchQuery = null;
			}
		}
	},
	{
		name: MEDIA_STATE_BROWSE,
		url: '/browse',
		views: {
			'content@portal.media': {
				component: CategoryBrowseSearchResultsComponent
			}
		},
		allowGuestAccess: true,
		scrollToTop: true,
		resolve: [
			{
				token: 'categoryRoot',
				deps: ['mediaFeatures'],
				resolveFn: (mediaFeatures: IMediaFeatures) => {
					return mediaFeatures.enableCategories;
				}
			},
			{
				token: 'searchParams',
				resolveFn: () => ({ hideNavbar: true })
			},
			{
				token: 'desc',
				deps: [Transition],
				resolveFn: (transition: Transition) => transition.params().desc
			},
			{
				token: 'sort',
				deps: [Transition],
				resolveFn: (transition: Transition) => transition.params().sort
			}
		],
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const mediaFeatures = injector.get<IMediaFeatures>('mediaFeatures');

			const desc = injector.get<boolean>('desc');
			const sortField = injector.get<string>('sort');
			const viewMode = injector.get<MediaViewMode>('viewMode');
			const defaultSort = SortField.Title;

			setMediaState(mediaStateService, desc, sortField, viewMode, defaultSort, MediaViewMode.TILES);

			if (mediaFeatures.enableCategories) {
				mediaStateService.setIsFilteringDisabled(true);
				mediaStateService.setIsSortingDisabled(true);
			}
		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			mediaStateService.setIsBulkEditDisabled(false);
			mediaStateService.setIsSortingDisabled(false);
			mediaStateService.setIsFilteringDisabled(false);

			if(!stateChangeStatus.isStateReloading){
				mediaStateService.setIsViewModeDisabled(false);
			}
		}
	},
	{
		name: 'portal.media.category-detail',
		url: '/videos/category/:categoryId',
		allowGuestAccess: true,
		views: {
			'content@portal.media': {
				component: CategoryDetailsSearchResultsComponent
			}
		},
		scrollToTop: true,
		resolve: [
			{
				token: 'categoryCheck',
				deps: ['mediaFeatures'],
				resolveFn: (mediaFeatures: IMediaFeatures) => {
					return !mediaFeatures.enableCategories && Promise.reject({ status: 401 });
				}
			},
			{
				token: 'categoryId',
				deps: [Transition],
				resolveFn: (transition: Transition) => transition.params().categoryId
			},
			{
				token: 'searchParams',
				deps: ['categoryId', CategoryService],
				resolveFn: (categoryId: string, CategoryService: CategoryService) => {
					if (categoryId === UNCATEGORIZED) {
						return { isUncategorized: true };
					}

					return CategoryService
						.getCategoryContent(categoryId)
						.then(categoryContent => ({
							categoryId,
							categoryContent,
							isUncategorized: false
						}));
				}
			}
		],
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const searchParams = injector.get<any>('searchParams');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const viewMode = injector.get<MediaViewMode>('viewMode');
			searchFilterState.filters.categoryIds.overrideValue([
				searchParams.isUncategorized ?
					{ categoryId: UNCATEGORIZED } :
					{ categoryId: searchParams.categoryId }
			]);

			setMediaState(mediaStateService, desc, sortField,
				viewMode, SortField.WhenUploaded, MediaViewMode.TILES);

		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);

			if (!transition.params().keepFilterOverrides) {
				searchFilterState.filters.categoryIds.clearValueOverride();
			}
			if(!stateChangeStatus.isStateReloading){
				mediaStateService.setIsViewModeDisabled(false);
			}
		}
	},
	{
		name: 'portal.media.pending-videos',
		url: '/pending',
		views: {
			'content@portal.media': {
				component: PendingVideoSearchResultsComponent
			}
		},
		scrollToTop: true,
		resolve: [
			{
				token: 'templatesCheck',
				deps: ['approverProcessTemplates'],
				resolveFn: (approverProcessTemplates: ApproverTemplateInfo[]) => {
					if (!approverProcessTemplates.length) {
						return Promise.reject({ status: 401 });
					}
				}
			}
		],
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const approverProcessTemplates = injector.get<ApproverTemplateInfo[]>('approverProcessTemplates');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const viewMode = injector.get<MediaViewMode>('viewMode');

			searchFilterState.filters.approvalStatus.overrideValue(ApprovalStatus.PENDING_APPROVAL);

			searchFilterState.filters.stepId.overrideValue(
				approverProcessTemplates.map(template => template.stepId)
			);

			mediaStateService.showSortRecommended = false;

			setMediaState(mediaStateService, desc, sortField, viewMode, SortField.WhenUploaded, MediaViewMode.TILES);

		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);

			mediaStateService.showSortRecommended = true;

			if (!transition.params().keepFilterOverrides) {
				searchFilterState.filters.approvalStatus.clearValueOverride();
				searchFilterState.filters.stepId.clearValueOverride();
			}
		}
	},
	{
		name: 'portal.media.expirations',
		url: '/expirations',
		views: {
			'content@portal.media': {
				component: ExpirationVideoSearchResultsComponent
			}
		},
		resolve: [
			{
				token: 'canViewExpiration',
				deps: [SecurityContextService],
				resolveFn: (SecurityContext: SecurityContextService) => {
					// reject if not media admin or contributor
					if (!(SecurityContext.checkAuthorization('admin.media.approvalProcesses') ||
						SecurityContext.checkAuthorization('media.add'))) {
						return Promise.reject({ status: 401 });
					}
				}
			},
			{
				token: 'expirationState',
				resolveFn: () => true
			},
		],
		scrollToTop: true,
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const viewMode = injector.get<MediaViewMode>('viewMode');

			mediaStateService.showSortRecommended = false;

			const [todayIsoString] = new Date().toISOString().split('T');

			mediaStateService.reset();
			mediaStateService.setColumns({
				'title.sort': 0,
				'ownerName.sort': 1,
				'deleteOnExpiration': 2,
				'expiryDate': 3
			});

			searchFilterState.filters.expiryDateExists.overrideValue({
				from: todayIsoString,
				to: SearchConstants.maxDate
			});

			setMediaState(mediaStateService, !!desc, sortField, viewMode, 'expiryDate', MediaViewMode.TABLE);

		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);

			mediaStateService.showSortRecommended = true;
			if (!stateChangeStatus.isStateReloading && stateChangeStatus.transition.toState.name !== 'portal.media.edit') {
				mediaStateService.resetColumns();
				mediaStateService.setIsViewModeDisabled(false);
				mediaStateService.setSortField('title.sort');

				searchFilterState.filters.expiryDateExists.clearValueOverride();
			}
		}

	},
	{
		name: 'portal.media.my-subscriptions',
		url: '/subscriptions',
		views: {
			'content@portal.media': {
				component: MySubscriptionVideoSearchResultsComponent
			}
		},
		scrollToTop: true,
		onEnter(transition: Transition) {
			const injector = transition.injector();
			const desc = injector.get<boolean>('desc');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const sortField = injector.get<string>('sortField');
			const viewMode = injector.get<MediaViewMode>('viewMode');

			setMediaState(mediaStateService, desc, sortField, viewMode, SortField.WhenUploaded, MediaViewMode.TILES);

			searchFilterState.filters.mySubscriptions.overrideValue(true);

		},
		onExit(transition: Transition) {
			const injector = transition.injector(null, 'from');
			const searchFilterState = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const mediaFeatures = injector.get<IMediaFeatures>('mediaFeatures');

			mediaStateService.reset(mediaFeatures.defaultVideoSortOrder);

			if (!transition.params().keepFilterOverrides) {
				searchFilterState.filters.mySubscriptions.clearValueOverride();
			}
		}

	}
];
