import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IVbrickPlayerConfig } from 'vbrick-player-src/IVbrickPlayerConfig';

import { CacheFactory } from 'rev-shared/util/CacheFactory';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';

import { IMediaFeatures } from './IMediaFeatures';
import { mergeWithDefaultPlayerConfig } from './VbrickPlayerDefaultConfig';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	'providedIn': 'root'
})
export class MediaFeaturesService {
	private mediaFeatureSettingsCache: CacheFactory<IMediaFeatures>;

	constructor(
		private UserAccount: UserAccountService,
		private http: HttpClient
	) {
		this.mediaFeatureSettingsCache = new CacheFactory<IMediaFeatures>(3);
	}

	public get accountFeatures(): IMediaFeatures {
		return this.getAccountFeatures(this.UserAccount.workingAccountId);
	}

	public get playerConfig(): IVbrickPlayerConfig {
		return mergeWithDefaultPlayerConfig(this.accountFeatures.playerSettings);
	}

	public getAccountFeatures(accountId: string): IMediaFeatures {
		const cachedFeatures = this.mediaFeatureSettingsCache.get(accountId);

		if (!cachedFeatures) {
			throw new Error('Account mediaFeatures not loaded');
		}

		return cachedFeatures;
	}

	public clearCache(accountId: string): void {
		this.mediaFeatureSettingsCache.remove(accountId);
	}

	//If account is loaded, update the cache
	public updateAccountFeatures(accountId: string, features: Partial<IMediaFeatures>): void {
		const cachedFeatures = this.mediaFeatureSettingsCache.get(accountId);
		if(cachedFeatures) {
			Object.assign(cachedFeatures, features);
		}
	}

	public setAccountFeatures(accountId: string, features: IMediaFeatures): void {
		this.mediaFeatureSettingsCache.put(accountId, features);
	}

	public getFeatures(accountId?: string): Promise<IMediaFeatures> {
		accountId = accountId || this.UserAccount.workingAccountId;
		const cachedFeatures = this.mediaFeatureSettingsCache.get(accountId);

		return cachedFeatures ?
			Promise.resolve(cachedFeatures) :
			this.fetchMediaFeatures(accountId);
	}

	private fetchMediaFeatures(accountId: string): Promise<IMediaFeatures> {
		return lastValueFrom(this.http.get<any>(`/media/accounts/${accountId}/features`))
			.then(result => {
				const features = {
					...result.features,
					canConfigureAutoIngest: result.canConfigureAutoIngest,
					mfaEnabled: result.enableMfaSetting,
					isLiveVideosCarouselEnabled: result.isLiveVideosCarouselEnabled,
					enablePublicWebcastAccess: result.enablePublicWebcastAccess,
					enableTrustedPublicWebcastAccess: result.enableTrustedPublicWebcastAccess,
					enableGuestAccess: result.enableGuestAccess,
					enablePublicVideoAccess: result.enablePublicVideoAccess,
					enableLegalHold: result.enableLegalHold,
					enableWebcastCustomConsent: result.enableWebcastCustomConsent,
					webcastEngagementSettings: result.webcastEngagementSettings,
					recordingPolicy: result.recordingPolicy,
					webcastSources: {
						vodDisabled: result.features?.webcastSources?.vodDisabled,
						webrtcSinglePresenterDisabled: result.features?.webcastSources?.webrtcSinglePresenterDisabled
					},
					enableRevTestModeFeatures: result.enableRevTestModeFeatures,
					modules: result.modules,
					enableExternalApplicationsTrustedAccess: result.enableExternalApplicationsTrustedAccess,
					trustedAccessExternalViewersSettings: result.trustedAccessExternalViewersSettings
				};

				this.setAccountFeatures(accountId, features);

				return features;
			});
	}
}
