import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TagData } from 'ngx-tagify';

import { ISparkRoom } from 'rev-portal/media/spark/Spark.Service';

import { autoCompleteSettings, TagValue, TEMPLATE, VbTagsInputComponent } from './VbTagsInput.Component';

import styles from './VbTagsInput.Component.module.less';

@Component({
	selector: 'vb-spark-rooms-tags-input',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbSparkRoomsTagsInputComponent),
			multi: true
		}
	],
	template: TEMPLATE
})
export class VbSparkRoomsTagsInputComponent extends VbTagsInputComponent {

	public ngOnInit(): void {
		super.ngOnInit();
		this.settings = {
			...this.settings,
			...autoCompleteSettings,
			templates: {
				tag(tagData: TagData) {
					return `
						<tag title="${(tagData.title || tagData.value)}"
							contenteditable='false'
							spellcheck='false'
							tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
							class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ''}"
							${this.getAttributes(tagData)}>
								<x title='' class="${this.settings.classNames.tagX}" role='button' aria-label='remove tag'></x>
								<div class="${styles.tag}">
									<span title="${tagData.value}">${tagData.value}</span>
								</div>
						</tag>
					`;
				},
				dropdownItem(item: TagData) {
					return `
						<div ${this.getAttributes(item)} class="${styles.dropdownItem} ${this.settings.classNames.dropdownItem}"
							tabindex="0"  role="option">
							<span title="${item.value}">${item.value}</span>
						</div>
					`;
				}
			}
		};
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public mappedWhitelist(): TagValue[] {
		return this.whitelist.map(room => ({ ...room, value: room.title }));
	}

	public onTagsChange(selectedRooms: ISparkRoom[]): void {
		const tags = selectedRooms?.map((room: ISparkRoom) => ({ title: room.title, id: room.id }));
		this.notifyChange(tags);
	}
}
