import { Directive, Input, OnInit } from '@angular/core';

import styles from './VbBtn.Directive.module.less';

@Directive({
	selector: '[vbBtnPrimary]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbBtnPrimaryDirective implements OnInit {
	@Input() public ignoreVbBtnPrimary: boolean;

	public hostClass;

	public ngOnInit() {
		if (this.ignoreVbBtnPrimary) {
			return;
		}

		this.hostClass = `${styles.vbBtn} ${styles.btnPrimary}`;
	}

}
