import { Injectable } from '@angular/core';

import { DASHBOARD_STATE } from './AppStateNames';

@Injectable({
	providedIn: 'root'
})

export class AppBootstrapService {
	private landingState = DASHBOARD_STATE;
	private landingStateParams;

	public appConfig: any;

	public setAppLandingState(state: string, params?: any) {
		this.landingState = state;
		this.landingStateParams = params;
	}

	public getAppLandingStateName(): string {
		return this.landingState;
	}

	public getAppLandingState(): { state: string, params: any} {
		return {
			state: this.landingState,
			params: this.landingStateParams ?? undefined
		};
	}

	public setAppConfig(config: any) {
		this.appConfig = { ...(this.appConfig || {}), ...config };
	}
}
