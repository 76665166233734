import { Directive, Input, HostBinding, DoCheck } from '@angular/core';

import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

/**
 * This is a directive to programatically make a menu drop-up instead of drop-down
 * - primarily used to prevent overflow visibility issues when there are too many menu options to display
 * - ex: 3rd to last row in a table has an actions menu where the last options are not visible because
 *       it is rendering as a drop-down instead of drop-up
 * @param {HTMLElement} vbAutoDropup - (required) the scroll/table container
 * @param {HTMLElement} rowParent - (required) the row container (usually the *ngFor)
 * @param {number} menuItemsCount - (required) the maximum possible menu items displayed
 */
@Directive({
	selector: '[vbAutoDropup]'
})
export class VbAutoDropupDirective implements DoCheck {
	@Input({ alias: 'vbAutoDropup', required: true }) public scrollParent: HTMLElement;
	@Input({ alias: 'rowParent', required: true }) public offsetChildSelector: HTMLElement;
	@Input({ required: true }) public menuItemsCount: number;
	@HostBinding('class.dropup') public shouldDropUp: boolean;

	constructor(
		private dropdown: BsDropdownDirective
	) { }

	public ngDoCheck(): void {
		//measurements
		const scrollContainerOffsetTop = this.scrollParent.offsetTop;
		const scrollContainerHeight = this.scrollParent.scrollHeight;
		const rowElementOffsetTop = this.offsetChildSelector.offsetTop;
		const scrollContainerMenuHeight = this.menuItemsCount * 50 + 20; // 50px per menu item and 20px for extra offset

		//toggle the dropup class appropriately
		const shouldDropUp = rowElementOffsetTop > (scrollContainerOffsetTop + scrollContainerHeight - scrollContainerMenuHeight);
		if (this.shouldDropUp !== shouldDropUp) {
			this.shouldDropUp = shouldDropUp;
			this.dropdown.dropup = this.shouldDropUp;
		}
	}

}
