import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './VbUiSingleUserSelect.Component.module.less';
import { VbQuerySelectorBaseComponent } from '../VbQuerySelectorBase.Component';
import { IRules } from '../../css/CssRules.Contract';

@Component({
	selector: 'vb-ui-single-user-select',
	templateUrl: './VbUiSingleUserSelect.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbUiSingleUserSelectComponent),
		multi: true
	}]
})
export class VbUiSingleUserSelectComponent extends VbQuerySelectorBaseComponent {
	@Input() public ariaLabel: string;

	public readonly styles = styles;
	public themeStyleOverrides: IRules;

	constructor(
		private ThemeService: ThemeService
	) {
		super();
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();
		setTimeout(() => {
			const themeSettings = this.ThemeService.brandingSettings.themeSettings;
			this.themeStyleOverrides = {
				[`.${styles.itemListItem.split(' ')[0]}.active`]: {
					'color': themeSettings.accentFontColor,
					'background-color': themeSettings.accentColor,
					'border-color': themeSettings.accentColor
				},
				[`.${styles.inputContainer}:focus-within`]: {
					'box-shadow': `inset 0 0 0 2px ${themeSettings.accentColor}`,
				}
			};
		});
	}
}
