import { Injectable, Inject, Optional } from '@angular/core';

import { compact as _compact, pick as _pick } from 'underscore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { pairwise, filter, distinctUntilChanged, map } from 'rxjs/operators';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { getCookie, unsetCookie } from 'rev-shared/util/CookieUtil';
import { isDefined, isUndefined } from 'rev-shared/util';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { UserAuthenticationService } from './UserAuthentication.Service';
import { CsrfTokenCookie } from './Tokens';
import { getSessionStorage, setSessionStorage } from './SessionStorageHelper';
import { UserContextStoreService } from './UserContextStore.Service';

export interface IUserContextAccount {
	id: string;
	isRootAccount: boolean;
	language: string;
	name: string;
	readonlyUserProfile: boolean;
	isUniversalEcdn: boolean;
}

export interface IUserContextUser {
	email?: string;
	firstName?: string;
	fullName?: string;
	id: string;
	isSsoUser?: boolean;
	language?: string;
	resolvedLanguage?: string;
	lastName?: string;
	username?: string;
	webcastId?: string;
	profileImageUri?: string;
	token?: any; //Only used for API and Embed cases
	uniqueId?: string;
	roles?: string[];
	joinUrl?: string;
	isRegisteredGuest?: boolean;
	isPreRegistered?: boolean;
}

export interface IUserContextCfg {
	disableUserContextStore: boolean;
	enableSessionStorage: boolean;

}

const sessionStorageKey = 'revUserSession';

/**
 * User Context
 * Holds information about the currently logged in user.
 */
@Injectable({
	providedIn: 'root'
})
export class UserContextService {
	private readonly userAuthenticatedSubject$: Subject<IUserContextUser>;
	private readonly userSubject$: Subject<IUserContextUser>;
	private readonly userSessionStartTimeCookie = 'sessionStart';

	private account: IUserContextAccount; // The account the user is logged into
	private loadedLanguage: string;
	private rootHostName: string;
	private sessionStable: boolean;
	private user: IUserContextUser;
	private avoidOutsideSecurityLoad: boolean;

	public logoutOnInvalidAuthKey: boolean;

	public readonly userAuthenticated$: Observable<IUserContextUser>;

	//The currently logged in user.
	public readonly user$: Observable<IUserContextUser>;

	//Emits a value when the user id changes, such as logging in or out.
	public readonly userIdChanged$: Observable<void>;

	public readonly userIdChangedForSecurityReload$: Observable<void>;

	constructor(
		@Optional()
		@Inject('UserContextCfg')
		private readonly UserContextCfg: IUserContextCfg,
		private readonly UserAuthenticationService: UserAuthenticationService,
		private readonly UserContextStore: UserContextStoreService,
		private readonly SecurityContext: SecurityContextService
	) {

		this.UserContextCfg = this.UserContextCfg || {} as any;
		const bootstrapUser = BootstrapContext.user || {};
		const user: IUserContextUser = {
			roles: BootstrapContext.roles,
			isPreRegistered: bootstrapUser.isPreRegistered ?? false,
			...bootstrapUser,
			isRegisteredGuest: (bootstrapUser.isRegisteredGuest || bootstrapUser.isExternalPresenter || bootstrapUser.isExternalViewer) ?? false,
			...this.getSessionStorageUser()
		};

		this.account = { ...BootstrapContext.account };
		this.rootHostName = BootstrapContext.rootHostName;
		this.sessionStable = !!user.id;
		this.user = user;
		this.loadedLanguage = BootstrapContext.language;

		this.userAuthenticatedSubject$ = new Subject<IUserContextUser>();
		this.userSubject$ = new BehaviorSubject<IUserContextUser>(this.user);
		this.userAuthenticated$ = this.userAuthenticatedSubject$.asObservable();
		this.user$ = this.userSubject$.asObservable();
		this.userIdChanged$ = this.user$.pipe(
			map(x => x.id),
			pairwise(),
			filter<any>(([a, b]) => a !== b)
		);

		this.userIdChangedForSecurityReload$ = this.userIdChanged$
			.pipe(
				filter(() => !this.avoidOutsideSecurityLoad)
			);

		if(!this.UserContextCfg.disableUserContextStore) {
			this.UserContextStore.user$.pipe(
				distinctUntilChanged((a, b) => {
					if(!a || !b) {
						throw new Error('missing data');
					}
					return a.id === b.id;
				})
			)
				.subscribe(user => this.setUser(user));

			this.updateUserStore();
		}
	}

	public acceptUserAgreement(userId: string, ssoLogin: boolean): Promise<any> {
		return this.UserAuthenticationService.acceptUserAgreement(userId, ssoLogin)
			.then((user: any) => {
				if(user) {
					user.isSsoUser = ssoLogin;

					return this.initializeUserAuthentication(user);
				}
			});
	}

	/**
	 * Attempt to authenticate a user
	 * Returns a promise object:
	 * If the login attempt fails, the promise will be rejected with the following result object:
	 * 	{
	 * 		isAuthenticateFailure - true if the user was not authenticated
	 * 		attemptsRemaining - If authentication failed, number of attempts that will be allowed until the account is locked.
	 * 		whenUnlocked - If the account was locked, date the account will be unlocked
	 * 	}
	 */
	public authenticateUser(username: string, password: string, useChips: boolean = true): Promise<any> {
		return this.UserAuthenticationService.authenticateUser(username, password, useChips)
			.then((user: any) => {
				if(!user) {
					throw new Error('User is null');
				}

				user.username = username;
				return this.initializeUserAuthentication(user);
			});
	}

	private setUser(user: IUserContextUser): void {
		this.user = user;
		this.sessionStable = !!user.id;
		this.userAuthenticatedSubject$.next(user);
		this.userSubject$.next(user);
	}

	public updateUserAndSecurityContext(user: IUserContextUser): Promise<any> {
		this.avoidOutsideSecurityLoad = true;

		this.setUser(user);
		return this.SecurityContext.reloadAuthorization();
	}

	public getAccount(): IUserContextAccount {
		return this.account;
	}

	public getCurrentLanguage(): string {
		return this.loadedLanguage;
	}

	public getCsrfToken(): string {
		return this.user.token?.csrfToken || getCookie(CsrfTokenCookie);
	}

	public getRootHostName(): string {
		return this.rootHostName;
	}

	public getSessionStartTime(): number {
		return +getCookie(this.userSessionStartTimeCookie);
	}

	public ssoEnabled(): boolean {
		return BootstrapContext.ssoEnabled;
	}

	/**
	 * Returns the user who is currently logged in:
	 */
	public getUser(): IUserContextUser {
		return this.user;
	}

	public isGuest(): boolean {
		return isUndefined(this.user.id);
	}

	/**
	 * Returns true if user is logged in as a webcast guest.
	 */
	public isRegisteredGuest(): boolean {
		return this.isUserLoggedIn() && this.user.isRegisteredGuest;
	}

	public isSessionStable(): boolean {
		return this.sessionStable;
	}

	/**
	 * Returns true if user is logged in normally
	 */
	public isUserAuthenticated(): boolean {
		return this.isUserLoggedIn() && !this.user.isRegisteredGuest;
	}

	/**
	 * Returns true if user is logged in normally, or as a registered guest
	 */
	public isUserLoggedIn(): boolean {
		return isDefined(this.user.id);
	}

	/**
	 * Ends the users authenticated session. Logs the user out and invalidate the current access token.
	 * returns promise
	 *
	 * localLogOut: do not call the logout server api.
	 */
	public logOutUser(localLogOut: boolean = false): Promise<any> {
		if(this.isRegisteredGuest()) {
			localLogOut = true;
		}

		const doLocalLogout = () => {
			unsetCookie(CsrfTokenCookie);
			unsetCookie(this.userSessionStartTimeCookie);

			this.sessionStable = false;
			this.user = {
				firstName: undefined,
				id: undefined,
				isSsoUser: this.user.isSsoUser
			};

			this.updateUserStore();
		};

		if(localLogOut){
			doLocalLogout();
			//Push message always triggers a local logout after the command is sent. Only reload securityContext once.
			return this.SecurityContext.reloadAuthorization();
		}

		this.SecurityContext.clearAuthorization();
		return this.UserAuthenticationService.doLogout(this.user.id)
			.catch(e => console.error('Error logging out', e))
			.finally(doLocalLogout);
	}

	public registerGuestUser(user: { userId: string; name: string; webcastId: string; email: string; token: any; }): void {
		this.initializeUserAuthentication({
			id: user.userId,
			firstName: user.name,
			webcastId: user.webcastId,
			email: user.email,
			token: user.token,
			isRegisteredGuest: true
		});
	}

	public updateUserInfo(user: IUserContextUser): void {
		this.user = {
			...this.user,
			fullName: _compact([user.firstName, user.lastName]).join(' '),
			...user,
		};
		this.userSubject$.next(this.user);
	}

	public updateProfileImageUri(profileImageUri: string): void {
		this.user.profileImageUri = profileImageUri;
		this.userSubject$.next(this.user);
	}

	/**
	 * Create the initial state of the user authentication
	 * @param accessToken
	 * @param user
	 */
	private initializeUserAuthentication(user: IUserContextUser): Promise<any> {
		this.sessionStable = false;
		this.avoidOutsideSecurityLoad = true;

		this.user = {
			email: user.email,
			firstName: user.firstName,
			fullName: _compact([user.firstName, user.lastName]).join(' '),
			id: user.id,
			isPreRegistered: user.isPreRegistered,
			isRegisteredGuest: user.isRegisteredGuest,
			isSsoUser: user.isSsoUser,
			language: user.language,
			resolvedLanguage: user.resolvedLanguage,
			lastName: user.lastName,
			username: user.username,
			webcastId: user.webcastId,
			profileImageUri: user.profileImageUri,
			uniqueId: user.uniqueId,
			roles: user.roles,
			token: user.token
		};

		this.userAuthenticatedSubject$.next(user);

		return this.waitForSessionToStabilize()
			.then(() => {
				this.sessionStable = true;
				return this.SecurityContext.reloadAuthorization();
			})
			.then(() => {
				this.updateUserStore();
			})
			.catch(e => {
				console.log('Session not stable', e);
				return Promise.reject({ sessionNotStable: true });
			});
	}

	private waitForSessionToStabilize(): Promise<any> {
		return retryUntilSuccess(
			() => this.UserAuthenticationService.checkSessionHealth(),
			undefined,
			undefined,
			err => err.status !== 400
		);
	}

	private updateUserStore(): void {
		if(!this.UserContextCfg.disableUserContextStore) {
			this.UserContextStore.setUser(this.user);
		}
		else {
			this.setUser(this.user);
		}

		if(this.UserContextCfg.enableSessionStorage) {
			setSessionStorage(sessionStorageKey, this.user);
		}
	}

	private getSessionStorageUser(): any {
		if(!this.UserContextCfg.enableSessionStorage) {
			return;
		}

		const user = getSessionStorage(sessionStorageKey);
		const userWebcastId = user?.webcastId || null;
		if(!userWebcastId || userWebcastId === BootstrapContext.webcastId) {
			return user;
		}
	}
}
