<vb-title [title]="'Title_UserConfirmation' | translate"></vb-title>

<vb-confirmation-dialog #userConfirmedDialog [title]="'Title_UserConfirmation' | translate" [message]="'Message_UserConfirmation' | translate" [actionText]="'Ok' | translate">
</vb-confirmation-dialog>


<vb-auth-layout>

	<div [hidden]="status !== 'processing'">
		<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'"></vb-loading-spinner>
	</div>

	<div [hidden]="status !== 'saving'">
		<vb-loading-spinner [msg]="'Processing' | translate" [size]="'large'"></vb-loading-spinner>
	</div>

	<div [hidden]="status !== 'error'">
		<div [ngClass]="authLayoutStyles.authMsg">{{ 'UnhandledError' | translate }}</div>
	</div>

	<div [hidden]="status !== 'invalidToken'" [ngClass]="authLayoutStyles.authMsg">
		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>
		<h3 [ngClass]="authLayoutStyles.centered">{{ 'UserConfirm_LinkNoLongerValid' | translate }}</h3>
		<button class="btn btn-primary theme-accent margin-top-20" uiSref="login">{{ 'UserConfirm_ReturnToLogin' | translate }}</button>
	</div>

	<div [hidden]="status !== 'invalidLicense'" [ngClass]="authLayoutStyles.authMsg">
		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>
		<h3 [ngClass]="authLayoutStyles.centered">{{ 'License_Error_UserShort' | translate }}</h3>
		<button class="btn btn-primary theme-accent margin-top-20" uiSref="login">{{ 'UserConfirm_ReturnToLogin' | translate }}</button>
	</div>

	<div [hidden]="status !== 'active'">

		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<form #userConfirmationForm="ngForm" [ngClass]="authLayoutStyles.authForm" name="userConfirmationForm" (submit)="submit()">

			<div [ngClass]="authLayoutStyles.heading">
				<h2>{{ 'UserConfirm_NewUserConfirmation' | translate }}</h2>
			</div>

			<div class="margin-top-10">
				<label for="password">{{ 'Password' | translate }}</label>
				<input type="password" id="password" name="password" class="form-control theme-header-bg theme-header-txt margin-bottom-5" [placeholder]="'Password_APlaceholder' | translate" [(ngModel)]="userConfirmation.password" #passwordControl="ngModel" trim="blur" required>
				<div [ngClass]="authLayoutStyles.pwValidation">
					<div [hidden]="!passwordRulesCheckerResult?.errorMinimumLength">{{ 'UserConfirm_MinimumLengthIs' | translate }} {{ passwordRules?.minimumLength }}.</div>
					<div [hidden]="!passwordRulesCheckerResult?.errorMinimumUppercaseLetterCount">{{ 'UserConfirm_MustContainOneUppercaseLetter' | translate }}</div>
					<div [hidden]="!passwordRulesCheckerResult?.errorMinimumLowercaseLetterCount">{{ 'UserConfirm_MustContainOneLowercaseLetter' | translate }}</div>
					<div [hidden]="!passwordRulesCheckerResult?.errorMinimumNumberCount">{{ 'UserConfirm_MustContainOneDigit' | translate }}</div>
					<div [hidden]="!passwordRulesCheckerResult?.errorMinimumSpecialCharacterCount">{{ 'UserConfirm_MustContainOneSpecialCharacter' | translate }}</div>
				</div>
			</div>

			<div class="margin-top-10">
				<label for="confirmPassword" #uiLabel>{{ 'ConfirmPassword' | translate }}</label>
				<input type="password" id="confirmPassword" name="confirmPassword" class="form-control theme-header-bg theme-header-txt margin-bottom-5" [placeholder]="'Password_Reenter' | translate" [(ngModel)]="userConfirmation.confirmPassword" #confirmPasswordControl="ngModel" trim="blur" required>
				<div [ngClass]="authLayoutStyles.pwValidation">
					<div [hidden]="confirmPasswordControl.pristine || confirmPasswordControl.valid">{{ 'PasswordNoMatch' | translate }}</div>
				</div>
			</div>

			<div class="margin-top-10">
				<label for="securityQuestion">{{ 'SecurityQuestion' | translate }}</label>
				<select id="securityQuestion" name="securityQuestion" class="form-select theme-header-bg theme-header-txt" [(ngModel)]="userConfirmation.securityQuestion" #securityQuestionControl="ngModel" required>
					<option [ngValue]="undefined">{{ 'UserConfirm_SecurityQuestion_SelectQuestion' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_WhereNewYear2000' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_CityNearestSibling' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_FavoriteBook' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_RoadGrewUp' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_FavoriteChildhoodFriend' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_FirstJobAttendingSchool' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_WhatWantToBe' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_WhereMeetSpouse' | translate }}</option>
					<option>{{ 'UserConfirm_SecurityQuestion_FirstMathTeacher' | translate }}</option>
				</select>
			</div>

			<div class="margin-top-10">
				<label for="securityAnswer">{{ 'UserConfirm_TypeYourAnswer' | translate }}</label>
				<input type="text" id="securityAnswer" name="securityAnswer" class="form-control theme-header-bg theme-header-txt margin-bottom-5" [placeholder]="'Answer_Placeholder' | translate" [(ngModel)]="userConfirmation.securityAnswer" #securityAnswerControl="ngModel" minlength="4" trim="blur" required>
				<div *ngIf="securityAnswerControl.touched && securityAnswerControl.invalid && securityAnswerControl.errors.minlength" [ngClass]="authLayoutStyles.pwValidation">
					{{ 'MinLength' | translate: { '0': 4 } }}
				</div>
			</div>

			<button type="submit" class="btn btn-primary theme-accent theme-accent-txt margin-top-20" [ngClass]="authLayoutStyles.authBtn" [disabled]="userConfirmationForm.invalid">
				{{ 'UserConfirm_Submit' | translate }}
			</button>

		</form>

	</div>
</vb-auth-layout>
