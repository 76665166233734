
import {
	Component,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SelectionModel } from '@angular/cdk/collections';

import { orderBy } from 'rev-shared/util/SortUtil';

import { IWebexSiteSettings } from 'rev-shared/media/IWebexSiteSettings';

import { WebExService } from './WebEx.Service';

import styles from './ImportWebex.module.less';
import './import-webex-dialog.less';

@Component({
	selector: 'import-webex-dialog',
	templateUrl: './ImportWebexDialog.Component.html'
})
export class ImportWebexDialogComponent implements OnInit {
	@Input() public webexSitesSettings: IWebexSiteSettings[];
	@Input() public teamId: string;

	public errorWebExGeneric: boolean;
	public errorWebExLogin: boolean;
	public errorWebExSso: boolean;
	public isLoading: boolean;
	public isLoggedIn: boolean;
	public noWebexRecordingsFound: boolean;
	public selectionModel: SelectionModel<any>;
	public webExRecordings: any[];

	public readonly styles = styles;

	public isSelectAll: boolean;
	public showWebexSitesList: boolean;
	public selectedSite: IWebexSiteSettings;
	public webexSites: IWebexSiteSettings[];

	constructor(
		private WebExService: WebExService,
		private modalInstance: BsModalRef
	) {
	}

	public ngOnInit(): void {
		this.errorWebExGeneric = false;
		this.errorWebExLogin = false;
		this.errorWebExSso = false;
		this.isLoading = false;
		this.isLoggedIn = false;
		this.noWebexRecordingsFound = false;
		this.webexSites = this.webexSitesSettings || [];
		this.showWebexSitesList = this.webexSites.length > 1;
		this.selectedSite = this.webexSites.length === 1 ? this.webexSites[0] : undefined;
		this.selectionModel = new SelectionModel(
			true //enable multi select
		);

		if (this.hideLoginView) {
			this.login();
		}
	}

	public get hideLoginView(): boolean {
		return this.selectedSite?.webexCiLinked;
	}

	public onRowSelectionChange(recording: any): void {
		this.selectionModel.toggle(recording);
		this.isSelectAll = this.selectedWebExImports.length === this.webExRecordings.length;
	}

	public onSiteSelect(): void {
		this.showWebexSitesList = false;
		this.clearLoginInfo();
		this.clearError();

		if (this.hideLoginView) {
			this.login();
		}
	}

	public onBackToWebexSitesList(): void {
		this.showWebexSitesList = true;
	}

	public getImportedColumnIconClass(item: { isImported: boolean }): string {
		return item.isImported ? 'glyphicons circle_ok' : '';
	}

	public import(): void {
		//loading state to prevent dupes
		this.isLoading = true;

		//import the selected recordings
		this.selectedWebExImports.forEach(selectedImport =>
			this.WebExService.importRecording(this.selectedSite.id, selectedImport.id, selectedImport.topic, selectedImport.downloadUrl, this.teamId, selectedImport.transcriptionFileUrl)
		);

		//close the modal
		this.close();
	}

	public login(): void {
		this.isLoading = true;
		this.errorWebExLogin = false;
		this.errorWebExGeneric = false;

		this.WebExService.getRecordings(this.selectedSite.id, this.teamId)
			.then(result => {
				this.isLoggedIn = true;
				this.webExRecordings = result ? orderBy(result, item => item.createTime, false) : [];
			}).catch(error => { //handle login failure and potentially other error
				if (error.status === 401) {
					this.errorWebExLogin = true;
				} else if(error.data.code === 'NoWebexRecordingsFound') {
					this.noWebexRecordingsFound = true;
					this.errorWebExGeneric = true;
				} else if(error.data.code === 'InvalidWebexUser') {
					// SSO Login fails
					this.errorWebExSso = true;
				}
				else {
					this.errorWebExGeneric = true;
				}
			}).finally(() => {
				this.isLoading = false;
			});
	}

	private clearError(): void {
		this.errorWebExGeneric = false;
		this.errorWebExLogin = false;
		this.errorWebExSso = false;
		this.noWebexRecordingsFound = false;
	}

	private clearLoginInfo(): void {
		this.isLoggedIn = false;

	}

	public syncSelectionHeaderModel(): void {
		window.setTimeout(() => {
			if(this.webExRecordings && this.webExRecordings.length > 0) {
				this.toggleSelectAll();
			}
		});
	}

	public toggleSelectAll(): void {
		(this.webExRecordings || []).forEach(item => {
			if (!item.isImported) {
				item.selected = this.isSelectAll;
				item.selected ? this.selectionModel.select(item) : this.selectionModel.deselect(item);
			}
		});
	}

	public close(): void {
		this.modalInstance.hide();
	}

	public get selectedWebExImports(): any[] {
		return this.selectionModel?.selected ?? [];
	}

	public trackByIndex(index: number): number {
		return index;
	}
}
