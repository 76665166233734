export interface IRgb {
	r: number;
	g: number;
	b: number;
}

export function setOpacity(hex: string, opacity: number): string {
	const { r, g, b } = parseHexColor(hex);
	return getRgba(r, g, b, opacity);
}

export function getRgba(r: number, g: number, b: number, a: number): string {
	return `rgba(${r}, ${g}, ${b}, ${a})`;
}

/**
 * based on color's brightness, returns either black or white transparent overlay color
 */
export function findOverlayColor(hex: string): string {
	const brightness = getBrightness(hex);

	if(brightness > 0.5) {
		return getRgba(0, 0, 0, 0.1);
	}

	return getRgba(255, 255, 255, 0.1);
}

function getBrightness(hex: string): number {
	const { r, g, b } = parseHexColor(hex);
	return Math.max(r, g, b) / 255;
}

function parseHexColor(hex: string): IRgb {
	const result = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1)
		.match(/.{2}/g)
		.map(x => parseInt(x, 16));

	return result && {
		r: result[0],
		g: result[1],
		b: result[2]
	};
}

export function lightenHexColor(hex: string, percent: number): string {
	const { r, g, b } = parseHexColor(hex);
	const newR = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
	const newG = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
	const newB = Math.min(255, Math.floor(b + (255 - b) * percent / 100));
	return rgbToHex(newR, newG, newB);
}

export function darkenHexColor(hex: string, percent: number): string {
	const { r, g, b } = parseHexColor(hex);
	const newR = Math.max(0, Math.floor(r * (1 - percent / 100)));
	const newG = Math.max(0, Math.floor(g * (1 - percent / 100)));
	const newB = Math.max(0, Math.floor(b * (1 - percent / 100)));
	return rgbToHex(newR, newG, newB);
}

function rgbToHex(r: number, g: number, b: number): string {
	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function componentToHex(c: number): string {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
}
