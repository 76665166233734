import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SearchHeaderService {
	public searchQuery: string;

	public setSearchQuery(query: string): void {
		this.searchQuery = query;
	}

	public clearData(): void {
		this.searchQuery = '';
	}
}
