import { Injectable } from '@angular/core';

import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class UploadFileService {
	private videos: any[] = [];
	private unsubscribePushHandlers: IUnsubscribe;

	private readonly videosSubject$ = new BehaviorSubject<any[]>(this.videos);
	public readonly videos$: Observable<any[]> = this.videosSubject$.asObservable();

	constructor(
		private PushBus: PushBus
	) { }

	public registerPushHandlers(accountId: string): void {
		this.unsubscribePushHandlers = this.PushBus.subscribe(accountId, 'Media.Videos', {
			VideoDeleted: data => {
				this.removeVideoById(data.videoId);
			}
		});
	}

	public unregisterPushHandlers(): void {
		this.unsubscribePushHandlers?.();
	}

	public isAVideoUploading(): boolean {
		return this.videos.some(upload => upload.status.uploading);
	}

	public resetVideos(): void {
		this.videos = [];
		this.videosSubject$.next(this.videos);
	}

	public abortVideoUploads(): void {
		this.videos.forEach(upload => upload.file.abort());
	}

	public addVideo(video: any): void {
		this.videos.push(video);
		this.videosSubject$.next(this.videos);
	}

	public removeVideo(video: any): void {
		const i = this.videos.indexOf(video);
		if (i >= 0) {
			this.videos.splice(i, 1);
		}
		this.videosSubject$.next(this.videos);
	}

	public removeVideoById(videoId: string): void {
		const i = this.videos?.findIndex(upload => upload.id === videoId);
		if (i >= 0) {
			this.videos.splice(i, 1);
		}
		this.videosSubject$.next(this.videos);
	}

	public removeCompletedVideoUploads(): void {
		this.videos
			.filter(video => !video.status.uploading)
			.forEach(video => {
				this.removeVideo(video);
			});
		this.videosSubject$.next(this.videos);
	}

}
