import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';

import { ISidebarConfig } from './ISidebarConfig';

import { ISidebarToggleEvent, VbUiSidebarComponent } from './VbUiSidebar.Component';

import styles from './VbUiSidebarLayout.Component.module.less';
import { VbUiBaseSidebarComponent } from './VbUiBaseSidebar.Component';

export enum VbUiSidebarLayoutSlot {
	MAIN_CONTENT = 'mainContent',
	SIDEBAR_HEADER_CONTROLS = 'sidebarHeaderControls',
	SIDEBAR_PANEL = 'sidebarPanel'
}

@Component({
	selector: 'vb-ui-sidebar-layout',
	templateUrl: './VbUiSidebarLayout.Component.html',
	host: {
		'[class]': 'hostClass',
		'[attr.layout]': 'layout',
		'layout-gt-md': 'row',
		'layout-wrap': 'false'
	}
})
export class VbUiSidebarLayoutComponent extends VbUiBaseSidebarComponent implements OnInit {
	@Input() public hideSidebar: boolean;
	@Input() public sidebarConfig: ISidebarConfig;
	@Input() public themed: boolean;
	@Input() public noBorder: boolean;
	@Output() public onSidebarToggle = new EventEmitter<ISidebarToggleEvent>();
	@ViewChild('sidebar') public sidebarComponent: VbUiSidebarComponent;

	public readonly styles = styles;
	public isSidebarOpen: boolean = false;
	public mainContentClass: string;

	public ngOnInit(): void {
		this.setMainContentClass();

		this.hostClass = {
			[this.styles.root]: true,
			[this.styles.mobileLayoutDisabled]: this.isMobileLayoutDisabled
		};
		super.initMediaQuerySubscription(styles);
	}

	public setMainContentClass(): void {
		const sidebarStateClass: string = this.isSidebarOpen && !this.hideSidebar ?
			styles.mainContentSidebarOpen :
			'';

		this.mainContentClass = styles.mainContentSlot + ' ' + sidebarStateClass;
	}

	public onSidebarToggleInternal($event: ISidebarToggleEvent): void {
		this.isSidebarOpen = $event.isOpen;
		this.setMainContentClass();

		this.onSidebarToggle.emit($event);
	}

	public closeSidebar(): void {
		this.sidebarComponent?.closeSidebar();
	}

	public triggerButton(id: string): void {
		this.sidebarComponent.triggerById(id);
	}
}
