export enum SpinnerSize {
	XSMALL = 'xsmall',
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large'
}

export enum SpinnerState {
	ACTIVE = 'active',
	COMPLETE = 'complete',
	INACTIVE = 'inactive'
}
