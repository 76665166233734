import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { firstValueFrom } from 'rev-shared/rxjs/firstValueFrom';

@Component({
	selector: 'alert-dialog',
	templateUrl: './AlertDialogAngular.Component.html'
})

export class AlertDialogAngularComponent {
	@Input() public title: string;
	@Input() public message: string;
	@Input() public okButtonText: string;

	public readonly result: Promise<void>;

	constructor(
		private bsModalRef: BsModalRef,
		private bsModalService: BsModalService
	) {
		this.result = firstValueFrom(this.bsModalService.onHide);
	}

	public hide(): void {
		this.bsModalRef.hide();
	}
}
