import { PushService } from 'rev-shared/push/PushService';
import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';

import { SecurityContextService } from './SecurityContext.Service';
import { UserContextService } from './UserContext.Service';

export function securityBootstrap(
	pushService: PushService,
	SecurityContext: SecurityContextService,
	signalRHubsConnection: SignalRHubsConnection,
	UserContext: UserContextService
) {
	let csrfToken = UserContext.getCsrfToken();
	let isLoggedIn = UserContext.isUserLoggedIn();

	UserContext.userAuthenticated$.subscribe(updateSecurityToken);
	onUserContextChange();
	UserContext.userIdChangedForSecurityReload$.subscribe(() => {
		//session is stable. no need for any $timeout...
		if (UserContext.isSessionStable()) {
			onUserContextChange();
			return;
		}
		//allow time for userId unsubscribe to complete
		window.setTimeout(() => onUserContextChange(), 500);
	});

	function onUserContextChange(): void {
		updateSecurityToken();
		SecurityContext.reloadAuthorization();
	}

	function updateSecurityToken(): void {
		const newIsLoggedIn = UserContext.isUserLoggedIn();
		const accessToken = UserContext.getUser().token?.accessToken;
		const newCsrfToken = UserContext.getCsrfToken();

		pushService.setToken(accessToken, newCsrfToken);

		if (csrfToken !== newCsrfToken || isLoggedIn !== newIsLoggedIn) {
			csrfToken = newCsrfToken;
			isLoggedIn = newIsLoggedIn;
			signalRHubsConnection.bounce()
				.catch(e => console.error('updateSecurityToken: ', e));
		}
	}
}

