import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';

import { AlertDialogAngularComponent } from './AlertDialogAngular.Component';
import { ConfirmationDialogAngularComponent } from './ConfirmationDialogAngular.Component';
import { StorysetDialogComponent } from './StorysetDialog.Component';
import { VbConfirmationDialogComponent } from './VbConfirmationDialogAngular.Component';
import { VbDialogBaseComponent } from './VbDialogBase.Component';

const components = [
	AlertDialogAngularComponent,
	ConfirmationDialogAngularComponent,
	StorysetDialogComponent,
	VbConfirmationDialogComponent,
	VbDialogBaseComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		FormDirectivesAngularModule,
		FormsModule,
		ModalModule.forChild(),
		TranslateModule
	],
	providers: [
		DialogRegistrationService
	]
})
export class DialogAngularModule {}

@NgModule({
	imports: [
		ModalModule.forRoot(),
		DialogAngularModule
	]
})
export class RootDialogAngularModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('ConfirmationDialog', {
			backdrop: 'static',
			keyboard: false,
			component: ConfirmationDialogAngularComponent
		});

		dialog.register('AlertDialog', {
			backdrop: 'static',
			keyboard: false,
			component: AlertDialogAngularComponent
		});

		dialog.register('StorysetDialog', {
			backdrop: 'static',
			keyboard: false,
			component: StorysetDialogComponent
		});
	}
}
