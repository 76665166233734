import { Injectable, NgZone } from '@angular/core';

import { StateService, UrlService } from '@uirouter/angular';

import { AppUtilService, isEmbed } from 'rev-shared/util/AppUtil.Service';
import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';
import { addQueryParams } from 'rev-shared/util/Util.Service';
import { isString } from 'rev-shared/util';

import { UserContextService } from './UserContext.Service';

export const SSOLoginPath = '/sso/login';

@Injectable({
	providedIn: 'root'
})
export class LoginRedirectService {
	private readonly loginLogoutRx = (/login|logout/);
	private readonly landingPageBlacklistRx = (/login|user-agreement|reset-password/);
	private readonly LOGIN_STATES = ['login', 'logout', 'sso-logout'];

	constructor(
		private $state: StateService,
		private $url: UrlService,
		private AppBootstrapService: AppBootstrapService,
		private AppUtil: AppUtilService,
		private UserContext: UserContextService,
		private ngZone: NgZone
	) {
		'ngInject';
	}

	public getLandingPageUrl(fwdUrl: string): string {
		if(!isString(fwdUrl) ||
			fwdUrl.match(this.landingPageBlacklistRx)) {

			fwdUrl = '/';
		}

		return fwdUrl;
	}

	public getLoginLink(fwdUrl?: string): string {
		fwdUrl = this.sanitizeFwdUrl(fwdUrl ?? this.$url.url());

		return this.isExternalLogin() ?
			this.encodeSSOLoginUrl(fwdUrl) :
			this.$state.href('login', { fwdUrl });
	}

	public isExternalLogin(): boolean {
		return !this.isEmbedLoginType &&
			this.UserContext.ssoEnabled() &&
			(this.UserContext.getUser().isSsoUser || !this.UserContext.isUserAuthenticated());
	}

	public encodeSSOLoginUrl(fwdUrl?: string, baseUrl?: string): string {
		const app: string = this.getAppPath(location);
		const fullFwdUrl: string =
			(baseUrl || (location.pathname + location.search)) +
			(fwdUrl ? '#' + fwdUrl : '');

		return addQueryParams(SSOLoginPath, {
			fwdUrl: fullFwdUrl,
			app
		});
	}

	public getLogoutRedirect(params?: any): { state: string; params: any } {
		const state: string = (this.UserContext.getUser().isSsoUser && !isEmbed())?
			'sso-logout' :
			'login';

		params = params || {};

		return {
			state,
			params: Object.assign({}, params, {
				fwdUrl: this.sanitizeFwdUrl(params.fwdUrl)
			})
		};
	}

	public isLoginHref(href: string): boolean {
		return this.LOGIN_STATES.some((loginStateName: string) => href.includes(this.$state.href(loginStateName, {})));
	}

	public isLoginState(stateName: string): boolean {
		return this.LOGIN_STATES.some((loginStateName: string) => loginStateName === stateName);
	}

	public redirectToLandingPage(fwdUrl: string, reloadPage: boolean): void | Promise<void> {
		fwdUrl = this.getLandingPageUrl(fwdUrl);

		if (reloadPage || this.AppBootstrapService.appConfig?.reloadAfterLogin) {
			return new Promise(resolve => {
				window.setTimeout(() => {
					resolve();

					window.location.href = '#' + fwdUrl;
					window.location.reload();
				}, 100);
			});
		}

		if (fwdUrl.length) {
			this.$url.url(fwdUrl);
		} else { // handle empty string case - elminate the hash so that we can match to an '' url in the stateConfig
			window.setTimeout(() => {
				window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
				this.ngZone.run(() => this.$url.sync());
			}, 100);
		}
	}

	public redirectToLogin(fwdUrl?: string): void {
		window.location.href = this.getLoginLink(fwdUrl);
		//Workaround, IE 11 not handling hash change.
		if(!this.isExternalLogin()) {
			setTimeout(() => this.$url.sync(), 100);
		}
	}

	public redirectToLogout(fwdUrl?: string): void {
		if(!this.$state.current.name.match(this.loginLogoutRx)){
			fwdUrl = this.sanitizeFwdUrl(fwdUrl || this.$url.url());

			this.$state.go('logout', { sessionEnded:true, fwdUrl });
		}
	}

	private get isEmbedLoginType(): boolean {
		return (isEmbed() && window.parent !== window)
			|| this.AppUtil.isMsTeamsEmbedLogin();
	}

	private sanitizeFwdUrl(fwdUrl: string): string {
		fwdUrl = fwdUrl || '';

		if(fwdUrl === '/' || fwdUrl.match(this.loginLogoutRx)) {
			return '';
		}

		return fwdUrl;
	}

	private getAppPath(location: Location): string {
		if(!location) {
			return;
		}

		switch(location.pathname) {
			case '/embed':
				return 'embed';
			case '/webex':
				return 'webex';
			case '/embed/login':
				return 'embedLogin';
			default:
				return;
		}
	}
}
